import React, { useState, useEffect, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import { Tabs, Tab, Box } from '@mui/material';
import { useAuth, useAuthUser } from "../../contexts/auth-context";
import { useTheme } from '@mui/material/styles';
import {  Link } from "react-router-dom";

export default function Product_Arch_Category_Edit({ products,arch }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const [categoryname, setcategoryname] = useState(products.length > 0 ? products[0].product_Group?.group_Name : '');
  const [activeTab, setActiveTab] = useState(0);
  const AuthUser = useAuthUser();
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const modalRef = useRef(null);
  const theme = useTheme();
  useEffect(() => {
    if (isEditing) {
      console.log("Category call");
      // Make API call to fetch related items here
      customaxios
      .post("/Category/getCategories", {
          categoryname: categoryname,
        })
        .then((response) => {
          setRelatedItems(response.data);
          console.log("setcategory");
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isEditing, categoryname]);

  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/ProductArch/update_arch_Category", {
        Group_Name: categoryname,
        Id: arch.productArchId,
      })
      .then((response) => {
        console.log("setcategory");
        console.log(response.data);
        console.log("set false");
        toast.success('Category updated');
        setIsEditing(false);

       // product.product_Group.group_Name = categoryname;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setcategoryname(products[0].product_Group.group_Name);
  };

  const handleBrandNameChange = (event) => {
    setcategoryname(event.target.value);
  };

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };

  return (
    <>

{isModalOpen && (
<>



<div className="tabone">
<div className="modal_brand" onClick={handleClose} ref={modalRef}>
          <div className="modal_brand-content">
            <p>{arch.arcCode} Config</p>
            <Link to={`/Arch/${arch.productArchId}`} target="_blank" rel="noopener noreferrer">
              {arch.productName}
              </Link>
          <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs"
    >
      <Tab label="Category " />
      <Tab label="Component" />
      <Tab label="Model" />
      {/* Add more tabs as needed */}
    </Tabs>
<Box sx={{ p: 2 }}>
      {activeTab === 0 && (
        <div className="tabone">
        <div className="category_config">
        {isEditing ? (
              <div className="brand_Name">
                <h2>Update Category</h2>
                <input
                  type="text"
                  value={categoryname}
                  onChange={handleBrandNameChange}
                />
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={handleCancelEditing}>Cancel</button>
              </div>
            ) : (
              <>
                <h4>Update Category</h4>
                <p 
                
                onClick={
                  (AuthUser.role === "Director" || AuthUser.role === "Developer") 
                    ? () => setIsEditing(true) 
                    : undefined
                }
                
                > click here to edit {categoryname}</p>
             
              </>
            )}
            {isEditing && relatedItems.length > 0 && (
              <ul className="productbrandselectlist">
                {relatedItems.map((item) => (
                  <li
                    className="productbrandselect"
                    key={item.product_groupId}
                    onClick={() => setcategoryname(item.group_Name)}
                  >
                    {item.group_Name}
                  </li>
                ))}
              </ul>
            )}
        </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="tabtwo">
          <p>component_config</p>
        </div>
      )}
       {activeTab === 2 && (
        <div className="tabthree">
          <p>Model_config</p>
        </div>
      )}
      {/* Add more tab content sections as needed */}
    </Box>
          </div>
        </div>
</div>



</>
      


      )}
      <p onClick={() => setIsModalOpen(true)}>{arch?.arcCode? arch.arcCode  : "N/A"}</p>

  


    </>
  );
}
