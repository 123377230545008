import ListSalary_Advance from "./ListSalary_Advance";
import CreateSalary_Advance from "./CreateSalary_Advance";
export default function  Salary_Advance (){
    
    return(
    <>
   
      <CreateSalary_Advance/>
        <br />
        <ListSalary_Advance/>
    </>)
}