import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageEditor from '@toast-ui/react-image-editor';
import Loading_Default from '../../components/Loading_Defualt';
import './Style/Style.css';
import logo from '../../assets/images/logo.jpeg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tui-image-editor/dist/tui-image-editor.css';
export interface FileUploadModalProps {
  isOpen: boolean;
  remove_background?: boolean;
  onClose: () => void;
  onFileUpload: (files: File[]) => void;
  toggleRemoveBackground?: () => void;
}

const dataURLtoFile = (dataUrl: string | undefined, filename: string) => {
  const arr = dataUrl?.split(',');
  const mime = arr && arr[0].match(/:(.*?);/)?.[1];
  if (!mime) return null;

  const binaryStr = atob(arr && arr[1]);
  let length = binaryStr.length;
  const u8arr = new Uint8Array(length);
  while (length--) {
    u8arr[length] = binaryStr.charCodeAt(length);
  }
  return new File([u8arr], filename, { type: mime });
};

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  onFileUpload,
  toggleRemoveBackground,
}): JSX.Element | null => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const imageEditorRef = useRef<any>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    const handleDocumentPaste = (event: ClipboardEvent) => {
      const { items } = event.clipboardData;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' && item.type.startsWith('image/')) {
          const file = item.getAsFile();
          if (file) {
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
          }
        }
      }
    };

    document.addEventListener('paste', handleDocumentPaste);
    return () => {
      document.removeEventListener('paste', handleDocumentPaste);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showEditModal && selectedImageIndex !== null && modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setShowEditModal(false);
        setSelectedImageIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEditModal, selectedImageIndex]);

  const handleFileUpload = () => {
    setIsUploading(true);
    onFileUpload(selectedFiles);
    setSelectedFiles([]); 
    setIsUploading(false);
  };

  const handleEditImage = (index: number) => {
    setSelectedImageIndex(index);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleSaveImage = () => {
    const imageEditorInstance = imageEditorRef.current.getInstance();
    const canvasData = imageEditorInstance.toDataURL();
    const updatedFile = dataURLtoFile(canvasData, selectedFiles[selectedImageIndex].name);
    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles[selectedImageIndex] = updatedFile;
      return updatedFiles;
    });

    setShowEditModal(false);
    setSelectedImageIndex(null);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-wrapper" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header">
          <h4>Upload Images</h4>
          <div className="toggle-button">
          <p style={{ color: 'black' }}>remove background</p>

            <label className="toggle-label">
              <input
                type="checkbox"
                onChange={toggleRemoveBackground}
              />
              <span className="toggle-slider" />
            </label>
            <span className=''>Remove Background</span>
          </div>
        </div>
        <div className="modal-body">
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop it like it's hot ...</p> : <p>Drop it like it's hot</p>}
          </div>
          <div className="selected-files">
            {selectedFiles.map((file, index) => (
              <div key={file.name} className="file">
                {file.type.startsWith('image/') && (
                  <div className="image-preview">
                    <img
                      className="preview-image"
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      onClick={() => handleEditImage(index)}
                    />
                  </div>
                )}
                <button onClick={() => setSelectedFiles(selectedFiles.filter((f) => f !== file))}>&times;</button>
              </div>
            ))}
          </div>
        </div>
        {isUploading ? (
          <div className="modal-footer">
            <Loading_Default />
          </div>
        ) : (
          <div className="modal-footer">
            {selectedFiles.length > 0 && <button onClick={handleFileUpload}>Upload</button>}
            <button onClick={onClose}>Cancel</button>
          </div>
        )}
      </div>

      <ToastContainer />

      {showEditModal && selectedImageIndex !== null && (
        <div className="modal-overlay">
          <div className="edit-modal">
            <div className="edit-modal-content">
              <h4>Edit Image</h4>
              <ImageEditor
                ref={imageEditorRef}
                includeUI={{
                  loadImage: {
                    path: URL.createObjectURL(selectedFiles[selectedImageIndex]),
                    name: selectedFiles[selectedImageIndex].name,
                  },
                  menu: ['shape', 'filter', 'crop', 'rotate', 'draw', 'text', 'icon', 'mask'], 
                  initMenu: 'filter',
                  uiSize: {
                    width: '90vw',
                    height: '90vh',
                  },
                  menuBarPosition: 'bottom',
                  menuLogo: {
                    normal: logo,
                    active: logo,
                  },
                }}
                cssMaxHeight={500}
                cssMaxWidth={700}
                usageStatistics={false}
                className="responsive-image-editor"
              />
              <button className='button_one' onClick={handleSaveImage}>Save</button>
              <button className='button_one danger' onClick={handleCloseEditModal}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadModal;
