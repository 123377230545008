import React, { useState } from "react";
import { Checkbox, Tab, Tabs } from "@mui/material";

export default function InvoiceTable(props) {
  const { invoices, selectedInvoices, setSelectedInvoices } = props;
  const [activeTab, setActiveTab] = useState(0);

  //Group invoices by billing group
  const groupedInvoices = invoices.reduce((acc, invoice) => {
    const billingGroup = invoice.billing_Group_Contained
      ? invoice.billing_Group_Contained.name
      : "Unassigned Billing Group";
    if (!acc[billingGroup]) {
      acc[billingGroup] = [];
    }
    acc[billingGroup].push(invoice);
    return acc;
  }, {});

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {Object.keys(groupedInvoices).map((billingGroup, index) => (
          <Tab key={index} label={billingGroup} />
        ))}
      </Tabs>
      {Object.keys(groupedInvoices).map((billingGroup, index) => (
        <div key={index} style={{ display: activeTab === index ? "block" : "none" }}>
          <table className="ledger_View_Table">
            <thead>
              <tr className="Ledger_View_Table_Row Title">
                <th>
                  <Checkbox
                    checked={selectedInvoices.length === groupedInvoices[billingGroup].length}
                    onChange={(event) =>
                      event.target.checked
                        ? setSelectedInvoices(groupedInvoices[billingGroup].map((invoice) => invoice.invoiceId))
                        : setSelectedInvoices([])
                    }
                  />
                </th>
                <th>ID</th>
                <th>Due Date</th>
                <th>Value</th>
                <th>Type</th>
                <th>Due Amount</th>
              </tr>
            </thead>
            <tbody>
              {groupedInvoices[billingGroup].map((invoice) => (
                <tr className="Ledger_View_Table_Row" key={invoice.invoiceId}>
                  <td>
                    <Checkbox
                      checked={selectedInvoices.includes(invoice.invoiceId)}
                      onChange={(event) =>
                        event.target.checked
                          ? setSelectedInvoices([...selectedInvoices, invoice.invoiceId])
                          : setSelectedInvoices(selectedInvoices.filter((id) => id !== invoice.invoiceId))
                      }
                    />
                  </td>
                  <td>{invoice.invoice_Id_Simplified}</td>
                  <td>{new Date(invoice.dueDate).toLocaleDateString()}</td>
                  <td>
                    {invoice.invoiceValue.toLocaleString("en-US", {
                      style: "currency",
                      currency: "LKR",
                    })}
                  </td>
                  <td>{invoice.paymentType}</td>
                  <td>
                    {invoice.paymentDue.toLocaleString("en-US", {
                      style: "currency",
                      currency: "LKR",
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}
