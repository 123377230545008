import React, { useState, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import { useAuthUser } from "../../contexts/auth-context";

export default function Product_Itemdetails_change({ product }) {
  const [isEditing, setIsEditing] = useState(false);
  const AuthUser = useAuthUser();
  const [itemdetails, setItemDetails] = useState(product.itemdetails);
  const inputRef = useRef(null);

  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/product/update_Name", {
        Product_name: itemdetails,
        Product: product.productId,
      })
      .then((response) => {
        console.log("set Item Details");
        console.log(response.data);
        console.log("set false");

        setIsEditing(false);
        toast.success('Item Details updated');

        // Consider updating the state or re-fetching data instead of modifying product directly
        product.itemdetails = itemdetails;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setItemDetails(product.itemdetails);
  };

  const handleDoubleClick = () => {
    if (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Praveen" || AuthUser?.role === "Developer") {
      setIsEditing(true);
    }
  };

  const handleInputChange = (event) => {
    setItemDetails(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveChanges();
    } else if (event.key === 'Escape') {
      handleCancelEditing();
    }
  };

  return (
    <>
      {isEditing ? (
        <input
          type="text"
          value={itemdetails}
          onChange={handleInputChange}
          onBlur={handleSaveChanges}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          autoFocus
        />
      ) : (
        <p onDoubleClick={handleDoubleClick}>{product.itemdetails}</p>
      )}
    </>
  );
}
