import React from "react";
import { useLocation } from "react-router-dom";
import customaxios from "../helpers/axios-helper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {Product} from "../models/Products/product";
import { CartProvider, Item, useCart } from "../components/Cart/index";

import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function Podcutlistbygroup(props) {


  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [productView, setProductView] = useState<Product>();


    const location = useLocation();



 
    //create product list 
    const [Product_List, setItems] = useState([]);
    
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('stock/movementrate',
        {            categoryname: location.state.group_Name,
        })

            .then((response) => {
             console.log(response);
                setItems(response.data);
                console.log(Product_List);
            
                
            })
    }, []);
   
  /*  <TableCell align="left">avg discount %</TableCell>
    <TableCell align="left">{(row.reduce((total, obj) => total + obj.movement, 0) / row.length).toFixed(2)}</TableCell>
*/
   /* const notify = () => 
    toast.success(Product_List.length +" Products Loaded")*/
   return(

        <div className="groupProductview">
             <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{
        productView?(
       <div>
           <TableContainer component={Paper}>
          <Table  sx={{ minWidth: 650 }}  aria-label="simple table">
          <TableRow >
                <TableCell align="left">{productView.suppliercode}</TableCell>
                <TableCell align="left">{productView.productName}</TableCell>
                <TableCell align="left">{productView.oaM_ACode}</TableCell>
                <TableCell align="left">{productView.brand.brand_Name}</TableCell>
                <TableCell align="left">subtotal</TableCell>
                 
                <TableCell align="left"></TableCell>
              
                </TableRow>
            <TableBody>

            <TableCell align="left">
            <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="web">Credit</ToggleButton>
          <ToggleButton value="android">Cash</ToggleButton>
        </ToggleButtonGroup>
            </TableCell>
            <TableCell>
            Unit Price <br/>{productView.productPrice.price} LKR
            </TableCell>
            <TableCell align="left">QTY <input type="number" value={productView.quantity} onChange={(e) => setProductView({...productView, quantity: parseInt(e.target.value)})} /></TableCell>
  
            <TableCell align="left">Discount <input type="number" value={productView.quantity} onChange={(e) => setProductView({...productView, quantity: parseInt(e.target.value)})} /></TableCell>
            <TableCell align="left"> {productView.productPrice.price * parseInt(productView.stringQuantity)}</TableCell>
            <TableCell align="left"></TableCell>
                
            </TableBody>
            <TableRow >
            <TableCell align="left">
            <TextField id="standard-basic" label="notes" variant="standard" />

            </TableCell>
            <Button variant="contained">add to cart</Button>



                </TableRow>
          </Table>
        </TableContainer>
        <div>
         
       
    
        </div>
       </div>
        
         
        ):(
          <div>Loading...</div>
        )
      }
        
        
        </Box>
      </Modal>
              

                <>
                
                </>
      

                <TableContainer component={Paper}>
      <Table  sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Supplier Code</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>A Code</TableCell>
            <TableCell align="left">Brand</TableCell>
            <TableCell align="left">qty instock</TableCell>
            <TableCell align="left">String Type Sale Price</TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left">avg units in invoice</TableCell>
            <TableCell align="left">avg invoice price</TableCell>
            <TableCell align="left">min discount %</TableCell>
            <TableCell align="left">max discount%</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {Product_List.sort((a, b) => b.length-a.length).map((row) => (

             <TableRow 
             
              key={row.stocklevelsId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
          
              <TableCell align="left">{row[0].product.suppliercode}</TableCell>
              <TableCell align="left">{<Link state={row[0].product} to="/ProductPage">{row[0].product.productName}</Link>}</TableCell>
              <TableCell align="left">{row[0].product.oaM_ACode}</TableCell>
              <TableCell align="left">{row[0].product.brand.brand_Name}</TableCell>
              <TableCell align="left">{row[0].product.stringQuantity}</TableCell>
              <TableCell align="left">{row[0].product.string_Type_Sale_Price}</TableCell>
              <TableCell align="left">{row.length}</TableCell>
              <TableCell align="left">{
              (
                row.reduce((total, obj) => {
                  if (obj.orderItem && obj.orderItem.total_Amount) {
                    return total + obj.orderItem.total_Amount;
                  }
                  return total;
                }, 0) / row.length).toFixed(2).toLocaleString()
              
              
              }</TableCell>
 
 <TableCell align="left">{
              (
                row.reduce((total, obj) => {
                  if (obj.orderItem && obj.orderItem.discount_Percentage) {
                    return total + obj.orderItem.discount_Percentage;
                  }
                  return total;
                }, 0) / row.length).toFixed(2).toLocaleString()
              
              
              }</TableCell>

            <TableCell align="left">{}</TableCell>

            </TableRow>
            
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>

       
    );

}


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: '#F3FFF5',
  border: '2px solid #25D442',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};