import React, { useState,useEffect } from 'react';
import Modal from 'react-modal';
import RefundModal from './RefundModal';
import PrintInvoice from './PrintInvoice';
import customaxios from '../../helpers/axios-helper';
interface InvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  invoiceid: any;
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen, onClose, invoiceid }) => {

    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [refundModalOpen, setRefundModalOpen] = useState(false);
    const [invoice, setInvoice] = useState<any>();

    useEffect(() => {
    
      customaxios
        .get("invoice/GetInvoiceById/" + invoiceid)
        .then((response) => {
          setInvoice(response.data);
        //  console.log(invoices)
        })
        .catch((error) => {
        //  console.error(error);
          console.log("An error occurred while loading the invoices.");
        })
        .finally(() => {
       //   setLoading(false);
        });
  
       
    }, [invoiceid]);
    const handleCheckboxChange = (item: any) => {
        setSelectedItems((prevSelectedItems) => {
          if (prevSelectedItems.find((selectedItem) => selectedItem.id === item.id)) {
            // If item is already selected, remove it from the list
            return prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id);
          } else {
            // If item is not selected, add it to the list
            return [...prevSelectedItems, item];
          }
        });
      
        console.log('Selected Items:', selectedItems);
      };
      
  
    const handleRefundButtonClick = () => {
      if (selectedItems.length > 0) {
        setRefundModalOpen(true);
      }
    };
  
    const handleRefundModalClose = () => {
      setRefundModalOpen(false);
    };
  
    const handleRefundSubmit = (quantities: { [itemId: number]: number }) => {
      // Perform refund logic here
      console.log('Refund quantities:', quantities);
  
      // Close the refund modal
      handleRefundModalClose();
    };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Invoice Details"
      ariaHideApp={false}
    >
        
      <div className="p-4">
      
        <RefundModal
        isOpen={refundModalOpen}
        onClose={handleRefundModalClose}
       // items={invoice.orderItems.filter((item) => selectedItems.includes(item.id))}
       items={selectedItems} 
       onSubmit={handleRefundSubmit}
       invoice={invoice}
      />
      {invoice ? (
  <PrintInvoice voucher={invoice}/>

      ):(<></>)}
      <button className="bg-red-500 text-white px-4 py-2 mt-4 float-end" onClick={onClose}>
          Close
        </button>
        {
        selectedItems.length > 0 ? ( <button className="bg-yellow-500 text-white px-4 py-2 mt-4 float-end" onClick={handleRefundButtonClick}>
        Refund
      </button>):(null)
      }
        <h2 className="text-lg font-bold mb-4">Invoice Details</h2>
       
     


        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
        <thead  className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
        
            <th>Product</th>
            <th>Price</th>
            <th>Discount  </th>
            <th>Qty</th>
            <th >Total</th>

          </tr>
        </thead>
        <tbody>
          {invoice?.orderItems?.map((item) => (
            <tr key={item.id
            } className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" '>
      
             <td>
             <input
  type="checkbox"
  checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
  onChange={() => handleCheckboxChange(item)}
/>
                <p className='font-medium'>{item.product.productName}</p> <p className='text-xs'>{item.product.oaM_ACode} - {item.product.suppliercode}</p></td>
              <td>{item.unitPrice.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</td>
              <td>{item.discount_Percentage.toLocaleString()} %</td>
              <td>{item.quantity.toLocaleString()}  {item.quantity_Unit}</td>
              <td className='subtotal'> {item.total_Amount?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })} </td>
            </tr>
          ))}
           <tr >
              <td></td>
              <td></td>
              <td></td>
              <td><strong>Grand Total (due)</strong></td>
              <td className='subtotal'><strong>{invoice?.paymentDue?.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</strong></td>
             
            </tr>
          
        </tbody>
    
      </table>
      </div>


    </Modal>
  );
};

export default InvoiceModal;

