import { useParams } from 'react-router-dom';
import {useEffect,useState} from "react";
import OrderComponent from '../components/Order/Order_Comp/OrderComponent';
import customaxios from '../helpers/axios-helper';
import { useAuth,useAuthUser } from "../contexts/auth-context";
import { Tabs, Tab,  } from "@mui/material";
import { Paper} from '@material-ui/core';
import './Style_Sales_Order/Style.css'; 
import Loading_Defualt from "../components/Loading_Defualt";
import Badge from '@mui/material/Badge';
import SalesOrderDate from "./SalesOrderDate";
import IconButton from '@mui/material/IconButton';
interface RouteParams {
    id: string;
    [key: string]: string;
}

export default function Sales_order_information({ id: routeId }: { id: string | null }) {
  const { id: paramId } = useParams<RouteParams>();
  const id = paramId ? paramId : routeId;
  
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const isAuth = useAuth();
    const AuthUser = useAuthUser();
    //const { id } = useParams<RouteParams>();
    const [Salesorder, setSalesorder] = useState<any>([]);
    const [tabIndex, setTabIndex] = useState(0);
  
    useEffect(() => {
      document.title = "#" + id +" - Sales Order";
      // Clean up function to reset the title if needed
      return () => {
        document.title = "#" + id; // If you want to reset the title to something else, change this line.
      };
    }, [id]);
  
    useEffect(() => {
        setIsLoading(true);
        setTabIndex(0);
        customaxios.get("SalesOrder/GetSalesOrder/"+id).then((res) => {
          
          setSalesorder(res.data);
          console.log(res.data)
          setIsLoading(false);
        });
    }, [id]);


    


    if (isLoading) {
        return (    <Loading_Defualt />);
      }

    return (
        <div key={id} className="SalesOrderPage">
 <div className="_Info">
  <div className="_Info__Order">
    <div className="_OrderNumber">
      Sales Order Id #{Salesorder?.salesOrderId}
    </div>
    <div className="_CustomerName">
      
{
  /*  <a href={"/CustomerInformation/"+Salesorder.customer.customerId}> */
}
  Customer: {Salesorder?.customerName || ""}
 {
  /*
   </a>
  */
 }
     </div>
    <div className="_SalesRep_Name">SalesRep: {`${Salesorder?.salesRepFullName}`}</div>
    <div className="_Created_Date_Time">Created Date : <SalesOrderDate createdDateTime={Salesorder?.created_Date_Time}/></div>
  </div>
</div>

<SalesOrderTabs salesOrders={Salesorder.orders}/>

{/*Salesorder.orders.map((order, index) => (
<OrderComponent v2={false} SalesOrder={order.orderId} orderid={order.orderId} />

))*/}


        </div>
    );
}



const SalesOrderTabs = ({ salesOrders }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <Paper square>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className="Order-tabs"
        >
          {salesOrders.map((order, index) => (
            <Tab
              key={index}
              label={
                <div className={`Order-tab-${
                  order.billing_Group_Contained?.name === "Maruti OE - RAH Series" ? "Maruti" : 
                  order.billing_Group_Contained?.name === "Harvestor H Series" ? "Harvestor" :
                  order.billing_Group_Contained?.name === "Simpsons OE" ? "Simpsons_OE" :
                  order.billing_Group_Contained?.name === "Tafe OE" ? "Tafe_OE" :

                  order.billing_Group_Contained?.name}`}>
               <IconButton aria-label="pending items" size="small">
                <Badge badgeContent={order.pending_items} color="primary">
                </Badge>
            </IconButton>    Order #{order.orderId}
                  <br />
                  {order.billing_Group_Contained?.name}
                 
                </div>
              }
            />
          ))}
        </Tabs>
      </Paper>
      <div role="tabpanel">
        {salesOrders.map((order, index) => (
          <div key={index} style={{ display: tabIndex === index ? 'block' : 'none' }}>
            {tabIndex === index && (
                <OrderComponent v2={false} SalesOrder={order.orderId} orderid={order.orderId} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};


{/*Order.ordeOrderItems.filter(item => item.orderItemStatus === "Pending").length ? (
            <IconButton aria-label="pending items" size="small">
                <Badge badgeContent={Order.ordeOrderItems.filter(item => item.orderItemStatus === "Pending").length} color="error">
                </Badge>
            </IconButton>
) : null




function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

   <Box   className="Sales_order_View" sx={{ width: '100%' }}>

                <Tabs className='Order-tabs'  value={tabIndex} onChange={handleTabChange} aria-label="Sales order tabs">
                    {Salesorder && Salesorder.orders && Salesorder.orders.map((Order: any, index: number) => (
                      <Tab
                      className={`Order-tab-${
                        
                      Order.vchseries === "Maruti OE - RAH Series" ? "Maruti" : 
                      Order.vchseries === "Harvestor H Series" ? "Harvestor" :
                      Order.vchseries === "Simpsons OE" ? "Simpsons_OE" :
                      Order.vchseries === "Tafe O" ? "Tafe_OE" :
                      Order.vchseries
                    }
                      `}

                      label={
                        <Box>
                    <div className={`Order-tab-${
                      Order.vchseries === "Maruti OE - RAH Series" ? "Maruti" : 
                      Order.vchseries === "Harvestor H Series" ? "Harvestor" :
                      Order.vchseries === "Simpsons OE" ? "Simpsons_OE" :
                      Order.vchseries === "Tafe OE" ? "Tafe_OE" :

                       Order.vchseries}`}>  {`Order # ${Order.orderId}`}
</div>
<div>   {Order.vchseries}</div>

                        </Box>
                      }
                      value={index}
                      key={Order.id}
                    />

                    
                    ))}
                </Tabs>
                {Salesorder && Salesorder.orders && Salesorder.orders.map((SalesOrder: any, index: number) => (
                    <TabPanel value={tabIndex} index={index} key={SalesOrder.id}>
                        <OrderComponent v2={false} SalesOrder={SalesOrder} />
                    </TabPanel>
                ))}
            </Box>


*/}