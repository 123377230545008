import customaxios from '../../helpers/axios-helper';
import { useState } from "react";
import $ from "jquery";
import { Link,useNavigate  } from "react-router-dom";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth, useAuthUpdate } from '../../contexts/auth-context';
import { UserLogin } from "../../models/users/user-login";

import { disableLoad, enableLoad } from "../../components/loader";
import { addCookie } from "../../helpers/cookie-helper";
import { addToLSOnLogin } from "../../helpers/storage-helper";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import 'react-toastify/dist/ReactToastify.css';

import { GoogleLogin } from '@react-oauth/google';
export default function Login() {
    const [form, setForm] = useState({
        email: "",
        password: "",
        otp: "",
        Cristal_Code:""
        
    });

    const isAuth = useAuth();
  
    const toggleAuth = useAuthUpdate();
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState(0);
    if(isAuth){
        navigate('/Home')
    }
    function updateForm(value) {
        return setForm((prev) => {
            console.log(value);
            return { ...prev, ...value };
        });
    }

    const processLogin = (e?) => {
        e.preventDefault();

        const user: UserLogin = {
            email: form.email,
            password: form.password,
            location: 'web',
            otp:'',
        };

        console.log(user);

        enableLoad("Logging you in...");

        customaxios.post('auth/login', user)
        .then((response) => {
            console.log(response);

            const data = response.data;

            disableLoad();

            if (data) {
                // Check if error
                if (typeof data !== 'string') {
                    $('#login-form').trigger("reset");

                    console.log(data);
                    if (data.mode === "OTP") {
                        setSelectedTab(1); // Switch to OTP tab
                        return;
                    } else {
                        const user = data.user;
                        const role: string = data.role;
                        const JWT: string = data.jwt;
                        const expiry: Date = new Date(data.expiry);
                        const issue_datetime: Date = new Date(data.issue_datetime);

                        addCookie('JWT', JWT, expiry);

                        let name: string = '';
                        role === 'Developer' ? name = role : name = user.firstName + ' ' + user.lastName;

                        addToLSOnLogin(user.id, name, user.email, role, expiry,issue_datetime); // Add to local storage

                        toggleAuth();

                        // alert('You have been logged in successfully.');
                        // notify();
                        setTimeout(() => {
                            role === 'Director' || 'Developer' ? navigate('/Home') : navigate('/Home');
                        }, 1000)
                    }

                } else {
                    alert(data);
                }
            } else {
                alert('Something went wrong');
            }
        });
    }
    const processLogin_WithGoogle = (credentialResponse?) => {
       
console.log(credentialResponse)
       // enableLoad("Logging you in...");
    // Construct the data to be sent
        const postData = {
            credential: credentialResponse.credential
        };
    
        // Make the POST request
        customaxios.post('auth/Login_with_Google/?credential='+ credentialResponse.credential)
        .then((response) => {
            console.log(response);

            const data = response.data;

            disableLoad();

            if (data) {
                // Check if error
                if (typeof data !== 'string') {
                    $('#login-form').trigger("reset");

                    console.log(data);
                    if (data.mode === "OTP") {
                        setSelectedTab(1); // Switch to OTP tab
                        return;
                    } else {
                        const user = data.user;
                        const role: string = data.role;
                        const JWT: string = data.jwt;
                        const expiry: Date = new Date(data.expiry);
                        const issue_datetime: Date = new Date(data.issue_datetime);

                        addCookie('JWT', JWT, expiry);

                        let name: string = '';
                        role === 'Developer' ? name = role : name = user.firstName + ' ' + user.lastName;

                        addToLSOnLogin(user.id, name, user.email, role, expiry,issue_datetime); // Add to local storage

                        toggleAuth();

                        // alert('You have been logged in successfully.');
                        // notify();
                        setTimeout(() => {
                            if (role === 'Director' || role === 'Developer') {
                                navigate('/Home');
                            } else {
                                navigate('/Products');
                            }
                        }, 10)
                    }

                } else {
                    alert(data);
                }
            } else {
                alert('Something went wrong');
            }
        });
    }

    const processOTP = (e?) => {
        e.preventDefault();
        console.log(form.otp)
        const user: UserLogin = {
            email: 't',
            password: 't',
            location: 't',
            Cristal_Code: form.Cristal_Code,
            
        };

        // Add your OTP submission logic here
        customaxios.post('auth/Login_With_Cristal_Code', user)
        .then((response) => {
            console.log(response);

            const data = response.data;

            disableLoad();

            if (data) {
                // Check if error
                if (typeof data !== 'string') {
                    $('#login-form').trigger("reset");

                    console.log(data);
                        const user = data.user;
                        const role: string = data.role;
                        const JWT: string = data.jwt;
                        const expiry: Date = new Date(data.expiry);
                        const issue_datetime: Date = new Date(data.issue_datetime);

                        addCookie('JWT', JWT, expiry);

                        let name: string = '';
                        role === 'Developer' ? name = role : name = user.firstName + ' ' + user.lastName;

                        addToLSOnLogin(user.id, name, user.email, role, expiry,issue_datetime); // Add to local storage

                        toggleAuth();

                        // alert('You have been logged in successfully.');
                        // notify();
                        setTimeout(() => {
                            role === 'Director' || 'Developer' ? navigate('/Home') : navigate('/Home');
                        }, 1000)

                } else {
                    alert(data);
                }
            } else {
                alert('Something went wrong');
            }
        });
  
      
    };

    return (
        <>
        


            <section className="login">
            <h1>Login</h1>
          
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Email" />
                    <Tab label="OTP" />
                </Tabs>
            {selectedTab === 0 ? (
                <div className='login-form-wrapper'>
                
<div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
    <form className="space-y-6" action="#" onSubmit={processLogin}>
        <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign in</h5>
        <div>
            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
            <input type="email" name="email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" id="email" 
             onChange={(e) => updateForm({ email: e.target.value.trim() })} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="name@company.com" required/>
        </div>
        <div>
            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
            <input onChange={(e) => updateForm({ password: e.target.value.trim() })} type={showPassword ? 'text' : 'password'} name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required/>
        </div>
        <div className="flex items-start">
        <FontAwesomeIcon  onClick={togglePasswordVisibility} icon={showPassword ? faEyeSlash : faEye} />
     
            <a href="/forgot-password" className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a>
        </div>
        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
            Not registered? <a href="/register" className="text-blue-700 hover:underline dark:text-blue-500">Create account</a>
        </div>
    </form>
    <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
    processLogin_WithGoogle(credentialResponse)
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>
</div>
                

                </div>
              
            ) : (
                <div className="login-form-wrapper">
                <form className="login-form" id="otp-form" onSubmit={processOTP}>
                <fieldset id="login-form">
                      <div className="floating-input">
                          <input
                              type="text"
                              id="otp-code"
                              placeholder="OTP Code"
                              required
                              onChange={(e) => updateForm({ Cristal_Code: e.target.value.trim() })}
                          />
                          <label htmlFor="otp-code">Cristal Code</label>
                      </div>
                      <div className="inline-btns">
                      <input type="submit" id="otp-submit" value="Submit OTP" />
                      </div>
                      </fieldset>
                  </form>
                  
                </div>
            )}
        </section>
        </>
    
    );
}

{
   /* <section className="login">
      
    <div className="login-form-wrapper">
        <form className="login-form" id="login-form" onSubmit={processLogin}>
            <fieldset id="login-form">
                <div className="floating-input">
                    <input type="email" pattern="[A-Za-z0-9._+-]+@[A-Za-z0-9 -]+\.[a-z]{2,}" title="Ex: john@zmail.com" id="login-email" placeholder=" " required
                    onChange={(e) => updateForm({ email: e.target.value.trim() })} />

                    <label htmlFor="login-email">Email</label>
                </div>

                <div className="floating-input">
<input
type={showPassword ? 'text' : 'password'}
id="login-password"
placeholder=" "
required
onChange={(e) => updateForm({ password: e.target.value.trim() })}
/>

<label htmlFor="login-password">Password</label>

<div className="password-toggle">
<FontAwesomeIcon  onClick={togglePasswordVisibility} icon={showPassword ? faEyeSlash : faEye} />
</div>
</div>

                <Link className='login-form-forgot' to={"/forgot-password"}>Forgot password?</Link>

                <div className="inline-btns">
                    <input type="submit" id="login-submit" 
                    value='Login' />
                </div>
            </fieldset>
        </form>
    </div>
   
   
</section>
*/
}