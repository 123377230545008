import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import { Salesrep } from '../../models/Sale_Rep/Salesrep';
import { useCart } from '../Cart/CartProvider';

function SelectedSales_rep() {
    const { salesrep, AddSalesrep } = useCart();
    const [isEditingSalesrep, setIsEditingSalesrep] = useState(false);
    const [relatedSalesrep, setRelatedSalesrep] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');

    useEffect(() => {
        if (isEditingSalesrep) {
            customaxios.get("/User/SearchUser?keyword=" + searchKeyword)
                .then((response) => {
                    setRelatedSalesrep(response.data);
                })
                .catch((error) => {
                    console.log("Error:", error);
                });
        }
    }, [isEditingSalesrep, searchKeyword]);

    const handleSalesrepChange = (event) => {
        setSearchKeyword(event.target.value);
        const updatedSalesrep = { ...salesrep, name: event.target.value };
        AddSalesrep(updatedSalesrep);
    };

    const handleSaveChanges = (selectedSalesrep) => {
        setIsEditingSalesrep(false);
        AddSalesrep(selectedSalesrep);
    };

    const handleCancelEditing = () => {
        setIsEditingSalesrep(false);
        setSearchKeyword('');
    };

    return (
        <div className="">
            {isEditingSalesrep ? (
                <div className="Customer_Name flex items-center">
                    <input
                        type="text"
                        value={searchKeyword}
                        onChange={handleSalesrepChange}
                        className="border border-gray-300 rounded px-2 py-1 mr-2"
                    />
                    <button
                        onClick={handleSaveChanges}
                        className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                    >
                        Select
                    </button>
                    <button
                        onClick={handleCancelEditing}
                        className="bg-gray-500 text-white px-2 py-1 rounded"
                    >
                        Cancel
                    </button>
                </div>
            ) : (

              <button
              className="bg-green-300  px-2 py-1 rounded mr-2"
              onClick={() => setIsEditingSalesrep(true)}
            >
            Sales rep: {salesrep.firstName} {salesrep.lastName}<br />
            </button>
              
            )}

            {isEditingSalesrep && relatedSalesrep.length > 0 && (
                <ul className="sales_RepSelectlist border border-gray-300 rounded mt-2 max-h-40 overflow-y-auto">
                    {relatedSalesrep.map(item => (
                        <li
                            className="sales_RepSelect cursor-pointer px-2 py-1 hover:bg-gray-100"
                            key={item.id}
                            onClick={() => handleSaveChanges(item)}
                        >
                            {item.firstName} {item.lastName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default SelectedSales_rep;
