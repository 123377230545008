import React from "react";
import { Link } from 'react-router-dom';

export default function View_Vouchers_row({voucher}) {
  
console.log(voucher);
    return (
        <div className={`voucher_View_Row ${voucher.verified && voucher.approved ? 'approved' : voucher.verified ? 'verified' : voucher.declined ? 'declined' : 'none'}`}>

        <div className="voucher_Id">
        <Link to={`/voucher/${voucher.voucherId}`}>
      <p>{voucher.voucherId}</p>
      </Link>
        </div>
        <div className="Voucer_Date">
        <p>{voucher.created_DateTime ? voucher.created_DateTime.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'Unknown'}</p>

        </div>
        <div className="VoucherTotal">
          <p>{voucher.voucher_Total.toLocaleString()} LKR</p>
        </div>
        <div className="Paymentmode">
          <p>{voucher.paymentMode}</p>
        </div>
        <div className="payee">
        <p>{voucher.payee?.name}</p>

        </div>
        <div className="invoiceNumber">
        <p>{voucher.invoiceNumber ? voucher.invoiceNumber : '-'}</p>
        <p>{voucher.invoice_date ? voucher.invoice_date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : '-'}</p>

        </div>
      </div>
    );
}