import React, { useState, useEffect } from 'react';
import axios from 'axios';
import customaxios from '../../helpers/axios-helper';
interface User {
  id: string;
  currentRole: string;
}

interface Props {
  userData: any;
}

const UserModal: React.FC<Props> = ({ userData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [allRoles, setAllRoles] = useState<string[]>([]);
  const disabledRoles = ['Director', 'Developer', 'CEO'];
  console.log(userData)
  useEffect(() => {
    // Fetch all roles from API on component mount
    const fetchRoles = async () => {
      try {
        const response = await customaxios.get('/roles/getallroles');
        setAllRoles(response.data);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };
    fetchRoles();
  }, []);

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewRole(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      await customaxios.post(`/roles/updateRole?id=${userData.id}&newRole=${newRole}`);
      // Handle success - maybe close the modal or show a success message
      setIsOpen(false); // close the modal after submission
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <>
    <p onClick={() => setIsOpen(true)}>
  {userData.role == null ? "not assigned" : userData.role}
</p>

      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          {/* Modal Content */}
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    {/* Icon here */}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Change Role</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Current Role: {userData.role}</p>
                      <select
                        onChange={handleRoleChange}
                        value={newRole}
                        disabled={userData.role && disabledRoles.includes(userData.role)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Select New Role</option>
                        {allRoles.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Update Role
                </button>
                <button
                  onClick={() => setIsOpen(false)} // close the modal on cancel
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserModal;
