import { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useAuthUser,useAuth } from '../contexts/auth-context'; 
import Pos from '../components/POS/Pos';
import { usePurchase_Prov } from '../components/Product_Purchase/Product_Purchase_Provider';
import { usePos } from '../components/POS/PosProvider';
import { useNavigate  } from "react-router-dom";


import { useCart } from '../components/Cart/CartProvider';
import Cart from '../components/Cart/Cart';

import List_Sales_reps from '../components/Sales_rep/List_Sales_reps';
import "./Style_Home/Style_Home.css";
import Sales_order_v2 from '../Sales_order/Sales_order_v2';
export default function SalesPage() {
 
  const AuthUser = useAuthUser();
  const { PurchaseItems } = usePurchase_Prov();
  const isAuth = useAuth();
  const { PosItems } = usePos();
  const { getTotalItemsInCart } = useCart();
    const navigate = useNavigate();
    const cartItemCount = getTotalItemsInCart();
  const tabs = [
   /* { label: "Sale Order", component: <Sales_order_v2 id={null} /> },*/
    { label: "Sales", component: <Sales_order_v2 id={null} /> },
    { label: "Sales Rep", component: <List_Sales_reps /> },

    { label:  `Cart ${cartItemCount > 0 ? "(" + cartItemCount + ")" : ""}`, component: <Cart /> },
    { label: `POS ${PosItems.length > 0 ? "(" + PosItems.length + ")" : ""}`, component: <Pos /> },
   /* { label: `Purchase ${PurchaseItems.length > 0 ? "(" + PurchaseItems.length + ")" : ""}`, component: <Product_Purchase /> },
*/
  ];

  // Filter tabs based on conditions
  if(!isAuth){  navigate('/')}
  const filteredTabs = tabs.filter(tab => {
    if (tab.label === "Sale Order") {
      return AuthUser.role === "Director" || AuthUser.role === "Developer";
    } else if ( tab.label === "Admin"  || tab.label === "POS" || tab.label === "Accounts" || tab.label === `Purchase ${PurchaseItems.length > 0 ? "(" + PurchaseItems.length + ")" : ""}` ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" ;
    } 
    else if (tab.label === "Customers" || tab.label === "Routes" ||  tab.label === "Sales" || tab.label === "POS" ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" || AuthUser.role === "Billing_Agent";
    } 
    else {
      return true; // Show other tabs by default
    }
  });

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className='Home_Container'>
 
      <div className="home-tabs-container">
      <Tabs 
             scrollButtons="auto" // Automatically show scroll buttons if tabs overflow
             variant="scrollable" // Use scrollable tabs for mobile screens
            value={activeTab} onChange={handleTabChange} className='home-tabs'>
              {filteredTabs.map((tab, index) => (
                <Tab key={index} label={tab.label} className='home-tab-label' />
              ))}
            </Tabs>

      </div>
     
      <div className='home-tab-container'>
      {filteredTabs[activeTab].component}
      </div>
    </div>
  );
}
