import * as React from "react";

import useLocalStorage from "./useLocalStorage";
export interface Item {
  productId: string;
  product: Product;
  price: number;
  quantity?: number;
  itemTotal?: number;
  [key: string]: any;
}
export interface Supplier {
  
  supplierId: string,
  supplier_Name: string,
  supplier_Short: string

}export interface brand {
  brandId: string,
  brand_Name: string
}
export interface productQuantity {
  productQuantityId: string,
  quantity_Units: string,
  quantity: number
}
export interface productPrice {
  productPriceId: string,
  priceUnit: string,
  price: number
}
export interface Product {
  

  supplier: Supplier

     productId:string,
     productName: string,
     oaM_ACode: string,
    
      brand: brand,

    //  product_Group: null,
      productQuantity: productQuantity,

      productPrice:productPrice
      duplicatename: string,
     // productArch: null,
      item_grouping:string,
      suppliercode: string,
      itemdetails: string,
      model: string,
      item_track:string,
      stringQuantity: string,
      quantity_Unit: string,
      string_Type_Sale_Price: string,
      billing_group: string,
      attribute_Lists: string
    //cart info

    price: number;
    quantity: number;
    itemTotal: number;
}
interface InitialState {
  productId: string;
  items: Item[];
  isEmpty: boolean;
  totalItems: number;
  totalUniqueItems: number;
  cartTotal: number;
  metadata?: Metadata;
}

export interface Metadata {
  [key: string]: any;
}

interface CartProviderState extends InitialState {
  addItem: (item: Item, quantity?: number) => void;
  removeItem: (productId: Item["productId"]) => void;
  updateItem: (productId: Item["productId"], payload: object) => void;
  setItems: (items: Item[]) => void;
  updateItemQuantity: (productId: Item["productId"], quantity: number) => void;
  emptyCart: () => void;
  getItem: (productId: Item["productId"]) => any | undefined;
  inCart: (productId: Item["productId"]) => boolean;
  clearCartMetadata: () => void;
  setCartMetadata: (metadata: Metadata) => void;
  updateCartMetadata: (metadata: Metadata) => void;
}

export type Actions =
  | { type: "SET_ITEMS"; payload: Item[] }
  | { type: "ADD_ITEM"; payload: Item }
  | { type: "REMOVE_ITEM"; productId: Item["productId"] }
  | {
      type: "UPDATE_ITEM";
      productId: Item["productId"];
      payload: object;
    }
  | { type: "EMPTY_CART" }
  | { type: "CLEAR_CART_META" }
  | { type: "SET_CART_META"; payload: Metadata }
  | { type: "UPDATE_CART_META"; payload: Metadata };

export const initialState: any = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  cartTotal: 0,
  metadata: {},
};

const CartContext = React.createContext<CartProviderState | undefined>(
  initialState
);

export const createCartIdentifier = (len = 12) =>
  [...Array(len)].map(() => (~~(Math.random() * 36)).toString(36)).join("");

export const useCart = () => {
  const context = React.useContext(CartContext);

  if (!context) throw new Error("Expected to be wrapped in a CartProvider");

  return context;
};

function reducer(state: CartProviderState, action: Actions) {
  switch (action.type) {
    case "SET_ITEMS":
      return generateCartState(state, action.payload);

    case "ADD_ITEM": {
      const items = [...state.items, action.payload];

      return generateCartState(state, items);
    }

    case "UPDATE_ITEM": {
      const items = state.items.map((item: Item) => {
        if (item.productId !== action.productId) return item;

        return {
          ...item,
          ...action.payload,
        };
      });

      return generateCartState(state, items);
    }

    case "REMOVE_ITEM": {
      const items = state.items.filter((i: Item) => i.productId !== action.productId);

      return generateCartState(state, items);
    }

    case "EMPTY_CART":
      return initialState;

    case "CLEAR_CART_META":
      return {
        ...state,
        metadata: {},
      };

    case "SET_CART_META":
      return {
        ...state,
        metadata: {
          ...action.payload,
        },
      };

    case "UPDATE_CART_META":
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      };

    default:
      throw new Error("No action specified");
  }
}

const generateCartState = (state = initialState, items: Item[]) => {
  const totalUniqueItems = calculateUniqueItems(items);
  const isEmpty = totalUniqueItems === 0;

  return {
    ...initialState,
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    cartTotal: calculateTotal(items),
    isEmpty,
  };
};

const calculateItemTotals = (items: Item[]) =>
  items.map(item => ({
    ...item,
    itemTotal: item.price * item.quantity!,
  }));

const calculateTotal = (items: Item[]) =>
  items.reduce((total, item) => total + item.quantity! * item.price, 0);

const calculateTotalItems = (items: Item[]) =>
  items.reduce((sum, item) => sum + item.quantity!, 0);

const calculateUniqueItems = (items: Item[]) => items.length;

export const CartProvider: React.FC<{
  children?: React.ReactNode;
  productId?: string;
  defaultItems?: Item[];
  onSetItems?: (items: Item[]) => void;
  onItemAdd?: (payload: Item) => void;
  onItemUpdate?: (payload: object) => void;
  onItemRemove?: (productId: Item["productId"]) => void;
  storage?: (
    key: string,
    initialValue: string
  ) => [string, (value: Function | string) => void];
  metadata?: Metadata;
}> = ({
  children,
  productId: cartId,
  defaultItems = [],
  onSetItems,
  onItemAdd,
  onItemUpdate,
  onItemRemove,
  storage = useLocalStorage,
  metadata,
}) => {
  const productId = cartId ? cartId : createCartIdentifier();

  const [savedCart, saveCart] = storage(
    cartId ? `react-use-cart-${productId}` : `react-use-cart`,
    JSON.stringify({
      productId,
      ...initialState,
      items: defaultItems,
      metadata,
    })
  );

  const [state, dispatch] = React.useReducer(reducer, JSON.parse(savedCart));
  React.useEffect(() => {
    saveCart(JSON.stringify(state));
  }, [state, saveCart]);

  const setItems = (items: Item[]) => {
    dispatch({
      type: "SET_ITEMS",
      payload: items.map(item => ({
        ...item,
        quantity: item.quantity || 1,
      })),
    });

    onSetItems && onSetItems(items);
  };

  const addItem = (item: Item, quantity = 1) => {
    if (!item.productId) throw new Error("You must provide an `productId` for items");
    if (quantity <= 0) return;

    const currentItem = state.items.find((i: Item) => i.productId === item.productId);

    if (!currentItem && !item.hasOwnProperty("price"))
      throw new Error("You must pass a `price` for new items");

    if (!currentItem) {
      const payload = { ...item, quantity };

      dispatch({ type: "ADD_ITEM", payload });

      onItemAdd && onItemAdd(payload);

      return;
    }

    const payload = { ...item, quantity: currentItem.quantity + quantity };

    dispatch({
      type: "UPDATE_ITEM",
      productId: item.productId,
      payload,
    });

    onItemUpdate && onItemUpdate(payload);
  };

  const updateItem = (productId: Item["productId"], payload: object) => {
    if (!productId || !payload) {
      return;
    }

    dispatch({ type: "UPDATE_ITEM", productId, payload });

    onItemUpdate && onItemUpdate(payload);
  };

  const updateItemQuantity = (productId: Item["productId"], quantity: number) => {
    if (quantity <= 0) {
      onItemRemove && onItemRemove(productId);

      dispatch({ type: "REMOVE_ITEM", productId });

      return;
    }

    const currentItem = state.items.find((item: Item) => item.productId === productId);

    if (!currentItem) throw new Error("No such item to update");

    const payload = { ...currentItem, quantity };

    dispatch({
      type: "UPDATE_ITEM",
      productId,
      payload,
    });

    onItemUpdate && onItemUpdate(payload);
  };

  const removeItem = (productId: Item["productId"]) => {
    if (!productId) return;

    dispatch({ type: "REMOVE_ITEM", productId });

    onItemRemove && onItemRemove(productId);
  };

  const emptyCart = () =>
    dispatch({
      type: "EMPTY_CART",
    });

  const getItem = (productId: Item["productId"]) =>
    state.items.find((i: Item) => i.productId === productId);

  const inCart = (productId: Item["productId"]) => state.items.some((i: Item) => i.productId === productId);

  const clearCartMetadata = () => {
    dispatch({
      type: "CLEAR_CART_META",
    });
  };

  const setCartMetadata = (metadata: Metadata) => {
    if (!metadata) return;

    dispatch({
      type: "SET_CART_META",
      payload: metadata,
    });
  };

  const updateCartMetadata = (metadata: Metadata) => {
    if (!metadata) return;

    dispatch({
      type: "UPDATE_CART_META",
      payload: metadata,
    });
  };

  return (
    <CartContext.Provider
      value={{
        ...state,
        getItem,
        inCart,
        setItems,
        addItem,
        updateItem,
        updateItemQuantity,
        removeItem,
        emptyCart,
        clearCartMetadata,
        setCartMetadata,
        updateCartMetadata,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
