import React, { useState } from 'react';

const PaymentModeSelect = ({ onPaymentModeSelected, onChequeNumberEntered }) => {
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');

  const handlePaymentModeChange = (event) => {
    const paymentMode = event.target.value;
    setSelectedPaymentMode(paymentMode);
    onPaymentModeSelected(paymentMode);
  };

  const handleChequeNumberChange = (event) => {
    const chequeNumber = event.target.value;
    onChequeNumberEntered(chequeNumber);
  };

  const renderChequeInput = () => {
    if (selectedPaymentMode === 'cheque') {
      return (
        <div className='ChequeInput'>
          <label htmlFor='chequeNo'>Cheque No:</label>
          <input type='text' id='chequeNo' name='chequeNo' onChange={handleChequeNumberChange} />
        </div>
      );
    }
  };

  return (
    <div className='PaymentMode'>
      <select value={selectedPaymentMode} onChange={handlePaymentModeChange}>
        <option value="">Select payment mode...</option>
        <option value="cash">Cash</option>
        <option value="bank-transfer">Bank Transfer</option>
        <option value="card-payment">Card Payment</option>
        <option value="cheque">Cheque</option>
      </select>
      {renderChequeInput()}
    </div>
  );
};

export default PaymentModeSelect;
