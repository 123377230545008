
import { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useNavigate  } from "react-router-dom";
import { useAuthUser,useAuth } from '../../contexts/auth-context'; 

export default function History(){
    const navigate = useNavigate();
    const AuthUser = useAuthUser();
    const isAuth = useAuth();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

 // Replace 'role1', 'role2', etc., with the actual roles you want to check
 const allowedRoles = ['Director','Developer','Praveen','Billing_Agent'];
  
 // Check if the user has at least one of the allowed roles
 const isVerified = allowedRoles.some(role => AuthUser.role === role);

 if (!isVerified) {
   // Redirect to the login page if not verified
   navigate('/');
 }


    const tabs = [
         { label: "Invoices", component: <>NO Auth</> },
         { label: "Sales Orders", component: <>NO Auth</> },
         { label: "Orders", component: <>NO Auth</> },
       ];

    return(<>
 
    <Tabs 
             scrollButtons="auto" // Automatically show scroll buttons if tabs overflow
             variant="scrollable" // Use scrollable tabs for mobile screens
            value={activeTab} onChange={handleTabChange} className='home-tabs'>
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} className='home-tab-label' />
              ))}
            </Tabs>
            {tabs[activeTab].component}


    </>)
}