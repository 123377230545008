import React, { useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import CategoryList from "./CategoryList";
export default function Create_Voucher_category() {
  const [category, setCategory] = useState({ Description: "" });

  const handleChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await customaxios.post("VoucherCategory/CreateCategory", category);
      alert("Category created successfully!");
      setCategory({ Description: "" });
    } catch (error) {
      console.error(error);
      alert("Failed to create category");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label htmlFor="description">Category Description:</label>
        <input
          type="text"
          id="description"
          name="Description"
          value={category.Description}
          onChange={handleChange}
        />
        <button type="submit">Create Category</button>
      </form>

    </>
  );
}
