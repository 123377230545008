import React, { useEffect, useState } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Default from "../../../Loading_Defualt";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the Favorite icon from MUI
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth,useAuthUser } from '../../../../contexts/auth-context';

interface Model {
  product_ModelId: any;
  model_Name: string;
  itemcount: number;
  archCount: number;
  image_url: string;  favourite:boolean;
}
  interface Catgory_select_Props {
    isOpen: boolean;
    closeModal: () => void;
  
    selectedCollection_Id: string | null;
    setselectedCollection_Name: (category: string | null) => void;
    setselectedCollection_Type: (category: string | null) => void;
    setselectedCollection_Id: (category: string | null) => void;
  }
export default function ModelsSelector(props:Catgory_select_Props) {
  const [ModelsList, setCategoriesList] = useState<Model[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(props.selectedCollection_Id);
  const filteredCategories_models = ModelsList.filter((Model) => Model.model_Name.toLowerCase().includes(searchTerm.toLowerCase()));
  const auth = useAuthUser();
  useEffect(() => {
    /*const storedData = localStorage.getItem("ModelsList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setCategoriesList(data);
        if (ModelsList.length > 0) {
          setSelectedCategory(ModelsList[0].product_ModelId);
        }
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("ModelsList");
      }
    }*/
    customaxios.get("ProductModel/GetProducts_Models").then((response) => {
      setCategoriesList(response.data);
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("ModelsList", JSON.stringify(data));
    });
  }, []);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("ModelsList");
    setCategoriesList([]);
    setLoading(true);
    fetchData_model();
  };

  const fetchData_model = () => {
    customaxios.get("ProductModel/GetProducts_Models").then((response) => {
      setCategoriesList(response.data);
      
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("ModelsList", JSON.stringify(data));
    });
  };

  
  const handle_select_model = (Model:Model)=>{
    setSelectedCategory(Model.product_ModelId)
    props.setselectedCollection_Name(Model.model_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_ByModel_paged")
    props.setselectedCollection_Id(Model.product_ModelId)
    console.log('selected ', Model)
  }
  const set_favourite_model = (brand: Model) => {
    if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
 // Handle the double click action here
 console.log('Double click on ', brand.product_ModelId);
  
 customaxios
   .post("ProductModel/Model_Favourite?id="+ brand.product_ModelId 
   )
   .then((response) => {
     console.log(response);
     fetchData_model();
   })
   .catch((error) => {
     console.error("Error during double click action:", error);
   });
    }
    
   
  };

    return(
    <>
           <div className="CategoriesList ">
       <div className="controls">
     <input className="Search_bar" type="text" placeholder="Search Models..." value={searchTerm} onChange={handleSearchChange} />

       <button className="refresh" onClick={clearLocalStorage}><FontAwesomeIcon icon={faSyncAlt} /> </button>
       <a target="_blank" href={`${window.location.origin}/M`}>
      
      <div className="Categorylist_item">
        <div className="CategoryName"><p>&#9734; Open In New Tab</p></div>
      
        
      </div>
         </a>  
       </div>
       {loading ? (
  <Loading_Default />
) : (
  <>
  
  <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
   
   <tr>
            <th className="px-6 py-3">Logo</th>
            <th className="px-6 py-3">Name</th>
            <th className="px-6 py-3">No of Items</th>
            <th className="px-6 py-3">No of Archs</th>
            <th className="px-6 py-3">Fav</th>
   </tr>
          </thead>
        
        
        <tbody>
          
 
          {filteredCategories_models.map((Model) => (
            <tr              key={Model.product_ModelId} className="bg-white border-b dark:bg-gray-800 dark:border-green-500 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td>  {Model.image_url && (
                 
                    <img src={Model.image_url} style={{ maxWidth: 50 }}  alt="" />
                )} </td>

                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select_model(Model); props.closeModal();}}>
                {Model.model_Name}
                </td>
                <td>
                {Model.itemcount}
                </td>
                <td>
                {Model.archCount}
                </td>
                <td>
                {Model.favourite === true ? (<IconButton
            onClick={() => set_favourite_model(Model)}
            color="secondary" // Change color as needed
          >
            <FavoriteIcon />
          </IconButton>):(<IconButton onClick={() => set_favourite_model(Model)}>
            <FavoriteBorderIcon />
          </IconButton>)}
                </td>
            </tr>
               
          
            ))}
        </tbody>
        </table>
        
  </>

)}

      </div>
        </>
        )
}