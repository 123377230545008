import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../../../helpers/axios-helper";
import { Tabs, Tab, Box } from "@mui/material";
import ImageRow  from "../../../Images/ImageRow";
import ProductHistoryTabs from "./ProductHistoryTabs ";
import Product from "../../../Product/Product";
import "./Style/Style.css"
import { useAuth, useAuthUser } from "../../../../contexts/auth-context";
import MovementComparison from "./MovementComparison";
import Movement from "./Movement";
interface RouteParams {
  id: string;
  [key: string]: string;
}




export default function Arch_Page({ id: routeId }: { id: string | null }) {
  const { id: paramId } = useParams<RouteParams>();
  const id = paramId ? paramId : routeId;
  const [archinfo, setarchinfo] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  
  const [product_Stats, setproduct_Stats] = useState<any>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const AuthUser = useAuthUser();

  useEffect(() => {
    if (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") {
      customaxios.get(`ProductArch/Get_Arch/${id}`)
        .then((response) => {
          setarchinfo(response.data.arch_data);
          setProducts(response.data.products);
        });
  
      customaxios.get(`stock/GetPurchaseTrend_Arch/${id}`)
        .then((response) => {
          // console.log(response);
          // setarchinfo(response.data.arch_data);
          setproduct_Stats(response.data);
        });

       
    }
  }, [AuthUser?.role, id]);
  

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setSelectedTabIndex(newIndex);
  };

  return (
<>
{AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer" ? (
  <>
    <br />
    <br />
    <div className="arch">
      <h1 className="Arch_Title">{archinfo?.productName}</h1>
    </div>

    <div className="kpi">
      <Movement id={id}/>
      <div className="kpi_Product">
        <MovementComparison product_Stats={product_Stats} />
      </div>
    </div>
    <div style={{ width: '90vw', display: 'flex', flexWrap: 'wrap' }}>
      <Tabs
        value={selectedTabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {products.map((product) => (
          <Tab key={product.productId} label={product.productName} />
        ))}
      </Tabs>
    </div>
    {products.map((product, index) => (
      <TabPanel key={product.productId} index={index} selectedTabIndex={selectedTabIndex}>
        <Product id={product.productId} />
      </TabPanel>
    ))}
  </>
) : (
  <>No Auth</>
)}
</>
  
    
  );
}

// Helper component for rendering tab content
function TabPanel(props: { children: React.ReactNode; index: number; selectedTabIndex: number }) {
  const { children, index, selectedTabIndex } = props;

  return (
    <div
      role="tabpanel"
      hidden={index !== selectedTabIndex}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {index === selectedTabIndex && <Box p={3}>{children}</Box>}
    </div>
  );
}
