import customaxios from "../../../../helpers/axios-helper" 
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material";
import "../../Style/Style.css"
import Account from "../../../Account/Account";

export default function ListBankAccounts() {
    const [Accounts, setExpenses] = useState<any[]>([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
  

    const [selectedTab, setSelectedTab] = useState<string | null>('all');
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
  
       // Group Accounts by company_Name
       const groupedAccounts: { [key: string]: any[] } = {};
       Accounts.forEach((expense) => {
         const company = expense.company_Name || "N/A"; // Use "N/A" for null values
         if (!groupedAccounts[company]) {
           groupedAccounts[company] = [];
         }
         groupedAccounts[company].push(expense);
       });


    useEffect(() => {
      fetchExpenses();
    }, []);
  
    const fetchExpenses = async () => {
      try {
        const response = await customaxios.get("Cash_At_Bank/GetAllBank_Acocunts");
        setExpenses(response.data);
        console.log(response)
      } catch (error) {
        console.error("Error fetching Accounts:", error);
        // Optionally, you can handle errors here, e.g., showing an error message to the user.
      }
    };
    const handleNameClick = (asset) => {
        setSelectedAsset(asset);
        console.log(asset)
      };
    return (
      <>
            <Account open={selectedAsset !== null} onClose={() => setSelectedAsset(null)} id={selectedAsset?.account_.account_Id
}/>
            <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        centered
      >
          <Tab key="all" label="All" value="all" />
        {Object.keys(groupedAccounts).map((companyName) => (
          <Tab key={companyName} label={companyName} value={companyName} />
        ))}
      </Tabs>

 {/* Render Accounts for the selected tab */}
 <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr >
            <th>Name</th>
            <th>A/C No</th>
            <th>company Name</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {selectedTab === "all"
            ? Accounts.map((expense) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>
                  <td onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.account_Number}</td>
                  <td>{expense.company_Name || "N/A"}</td>
                  <td>{expense.account_.balance || "N/A"}</td>
                </tr>
              ))
            : groupedAccounts[selectedTab]?.map((expense) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>
                  <td onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.account_Number}</td>
                  <td>{expense.company_Name || "N/A"}</td>
                  <td>{expense.account_.balance || "N/A"}</td>
                </tr>
              ))}
        </tbody>
      </table>
      </>
    );
  }
  