import React, { useState, useEffect, useContext } from "react";
import customaxios from "../../../helpers/axios-helper";
import ProductArch from '../Arch/ProductArch';
import ProductRow from "../Arch/productrow";
import Loading_Default from "../../Loading_Defualt";
import { GridOn  } from '@mui/icons-material';
import Lock from '@mui/icons-material/Lock';
import Clear from '@mui/icons-material/Clear';
import "./Search_Style/Style.css"
import { ThemeContext, Theme } from '../../Controls/Theme/ThemeContext';
import { useAuthUser } from "../../../contexts/auth-context";
import SearchIcon from '@mui/icons-material/Search';
interface LiveSearchProps {
  optionalParam?: {
    IsPos?: boolean;
    IsPurchase_list?: boolean;
    IsReturn?: boolean;
    returnitem?:any;
  };
}

const LiveSearch: React.FC<LiveSearchProps> = ({ optionalParam }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Search_format_lowercase_only, setSearch_format_lowercase_only] = useState(true);
  const { theme } = useContext(ThemeContext);
  const [Display_With_arch, setDisplay_With_arch] = useState(false);
  const AuthUser = useAuthUser();
  const [url, setUrl] = useState("");
  const [loadingDuration, setLoadingDuration] = useState<number | null>(null);
  const [isSearch_result_zero, setisSearch_result_zero] = useState(false);
  const [isHovering, setIsHovering] = useState(false); // State to track hovering

 
  const handleSearch_format_lowercase_only = () => {
    setResults([]);
    setSearch_format_lowercase_only((prevState) => !prevState);
  };

  const handleGroupFilterClick = () => {
    setResults([]);
    setDisplay_With_arch((prevState) => !prevState);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      startSearch();
    }
  };

  const startSearch = () => {
    setResults([]);
    setIsLoading(true);
    const startTime = Date.now();
    customaxios
      .post(url)
      .then((response) => {
        setResults(response.data);
        results.length == 0 ? setisSearch_result_zero(true) : setisSearch_result_zero(false)
        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // Convert to seconds
        setLoadingDuration(duration);
      })
      .catch((error) => {
        console.log(error);
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  useEffect(() => {
   // setResults([]);
    setUrl(`/product/${Display_With_arch ? "Search_v3_byArch" : "Search_v3"}?keyword=${encodeURIComponent(query)}`);

       }, [query, Display_With_arch, url]);

  const handleClearClick = () => {
    setQuery("");
    setResults([]);setisSearch_result_zero(false)
  };

  return (

    <>
    
    <div 
 className={`groupProductview`}>
      {AuthUser != null && (
        <>
          <br />
          <div
             onMouseEnter={() => setIsHovering(true)}
             onMouseLeave={() => setIsHovering(false)}
          style={{ borderColor: optionalParam?.IsPos === true ? 'green' : (optionalParam?.IsPurchase_list === true ? 'red' : '') }} className={`search_Primary `}>
          <div className="relative">
         
            <input
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(Search_format_lowercase_only ? e.target.value.toLocaleLowerCase() : e.target.value)}
              onKeyDown={handleKeyPress}
              className="w-full ..."
           
            />
             {isHovering && (
  <div className="dark:bg-gray-800 absolute z-10 w-full border divide-y shadow max-h-72 overflow-y-auto bg-white ...">
  {isLoading && <Loading_Default />}

  {results.length > 0 && (
    <div>
      {loadingDuration !== null && (
        <p>
          {results.length} Results in{" "}
          <span style={{ fontSize: "small", color: "gray" }}>
            ({loadingDuration.toFixed(2)} seconds)
          </span>
        </p>
      )}
      {results.map((result, index) => (
        Display_With_arch ? (
          <ProductArch key={result.productArch.productArchId} products={result.products} arch={result.productArch} />
        ) : (
          <ProductRow handleReturnItem={optionalParam?.returnitem} IsReturn={optionalParam?.IsReturn || false} IsPurchase_list={optionalParam?.IsPurchase_list || false}  IsPos={optionalParam?.IsPos || false} addtocart={true} product={result} index={index} />
        )
      ))}
    </div>
  )}

{isSearch_result_zero && (
    <>
  No Results Found
    </>
  )}
  </div>
  )}
</div>
{query.length >= 2 && (
              <div className="clear_Search" onClick={handleClearClick}>
                <Clear />
              </div>
            )}
               {query.length >= 2 && (
              <div className={`search_icon `} onClick={startSearch}>
                <SearchIcon />
              </div>
        )}
           
            <div className={`caselock ${Search_format_lowercase_only ? "highlighted" : ""} ${theme === Theme.Dark ? 'dark' : ''}`} onClick={handleSearch_format_lowercase_only}>
              <Lock/>
            </div>
            <div className={`Group_filter ${Display_With_arch ? "highlighted" : ""}  ${theme === Theme.Dark ? 'dark' : ''}`} onClick={handleGroupFilterClick}>
              <GridOn />
            </div>
          </div>
    
    { (  <div  className="Search_results"
  

  >


</div>)}


        <br />
        </>
      )}
    </div>


  
    </>
  );
};

export default LiveSearch;
