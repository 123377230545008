import React, { useState } from "react";
import "./Style/Style.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Master_Asset from './Asset/Master_Asset/Master_Asset';
import Loan from './Loan/Loan';
import Staff_Loan from './Staff_Loan/Staff_Loan';
import Sundry_Debtors from './Sundry Debtors/Sundry_Debtors';
import Accounts from './Accounts/Accounts';
import Cheque_list from "./Cheque/ChequeList";
import VoucherDashboard from "../components/Payment/Voucher/VoucherDashboard";
import Equity_And_Liabilities from "./Equity_And_Liabilities/Equity_And_Liabilities"
import Bank from "./Asset/Current_Asset/Bank/Bank";
import Transaction from "./Transaction/Transaction";
import { useAuthUser,useAuth } from '../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';
import Sales_List from "./Sales/Sales_List";
import Expense from "./Expenses/Expense";
import Profit_Loss from "./Profit_Loss/Profit_Loss";
const tabsData = [
  { label: "Transaction", component: <Transaction/> },
  { label: "Expense", component: <Expense/> },
  { label: "Asset", component: <Master_Asset /> },
  { label: "Equity & Liabilities", component: <Equity_And_Liabilities /> },
  /*{ label: "Loans", component: <Loan />},*/ 

  { label: "Sales", component: <Sales_List/> },
  { label: "P/L", component: <Profit_Loss/> },
  { label: "Capital", component: (<>Capital Report</>) },
   /*{ label: "Bank", component: <Bank /> },*/ 
 /* { label: "Accounts", component: <Accounts /> },*/ 
 /* { label: "Cheques", component: <Cheque_list /> },
 { label: "Vouchers", component: <VoucherDashboard /> },
 */ 
  
  /* { label: "Staff_Loan", component: <Staff_Loan /> },*/ 
];

export default function Admin() {
  const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

  const handleTabChange = (event, newValue) => {
    setSelectedItem(newValue);
  };
  const AuthUser = useAuthUser();
  const isAuth = useAuth();

  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles =['Director','Developer','Praveen'] ;

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  return (
    <>
      <div className="Admin_Dashboard">
        <div className="List">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedItem}
            onChange={handleTabChange}
            className="tabs_side"
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                value={tab.label}
                className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
              />
            ))}
          </Tabs>
        </div>

        <div className="data_view">
          {tabsData.map((tab, index) => (
            <React.Fragment key={index}>
              {selectedItem === tab.label && tab.component}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
}
