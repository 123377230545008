import CreateSelling_And_DistributionExpense from "./CreateSelling_And_DistributionExpense";
import ListExpenses from "./ListExpenses"
import LedgerView from "../../Ledger/LedgerView";
export default function Selling_And_DistributionExpense (){


    return(
        <>
      
        <CreateSelling_And_DistributionExpense/>
        <br />
        <ListExpenses/>
        </>
    )
}