import Search from "../Product/Search/Search";
import {usePurchase_Prov } from "./Product_Purchase_Provider";
import {  useAuthUser,useAuth } from '../../contexts/auth-context';
import { CartItem,  } from './Product_Purchase_Provider';
import Product_Purchase_Row from "./Product_Purchase_Row";
import { useNavigate } from 'react-router-dom';
interface GroupedCartItems {
    [key: string]: {
      items: CartItem[];
      subtotal: number;
      ParentgroupKey: string; // Add this line
      paymentModeGroups: {
        [key: string]: {
          items: CartItem[];
        };
      };
    };
  }
export default function Product_Purchase(){
 const { 
  PurchaseItems ,
    clearCart, 
    getTotalItemsInCart,
    SubmitOrder, } = usePurchase_Prov();

    const AuthUser = useAuthUser();
    const isAuth = useAuth();
  
    const navigate = useNavigate();
    if (!isAuth) {
      // Redirect to the login page if not verified
      navigate('/');
    }
  
    // Replace 'role1', 'role2', etc., with the actual roles you want to check
    const allowedRoles =['Director','Developer','Praveen'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }
    console.log(PurchaseItems)

    const groupedCartItems: Record<string, Record<string, { paymentModeGroups: Record<string, { items: CartItem[]; subtotal: number }>; subtotal: number }>> = PurchaseItems.reduce((groups, item) => {
      const ParentgroupKey = item.Product.billing_Group_Contained.name;
      const groupKey = item.Product.supplier.supplier_Name;
      const paymentMode = item.PaymentMode;
      const subtotal = item.Total_Amount;
    
      if (!groups[ParentgroupKey]) {
        groups[ParentgroupKey] = {};
      }
    
      if (!groups[ParentgroupKey][groupKey]) {
        groups[ParentgroupKey][groupKey] = { paymentModeGroups: {}, subtotal: 0 };
      }
    
      if (!groups[ParentgroupKey][groupKey].paymentModeGroups[paymentMode]) {
        groups[ParentgroupKey][groupKey].paymentModeGroups[paymentMode] = { items: [], subtotal: 0 };
      }
    
      groups[ParentgroupKey][groupKey].paymentModeGroups[paymentMode].items.push(item);
      groups[ParentgroupKey][groupKey].paymentModeGroups[paymentMode].subtotal += subtotal;
      groups[ParentgroupKey][groupKey].subtotal += subtotal;
    
      return groups;
    }, {});
    

    
   
      const handleSubmitOrder = () => {
        if (window.confirm('Are you sure you want to place your order?')) {
          SubmitOrder();
        }
      };

      const handleClearCart = () => {
        if (window.confirm('Are you sure you want to clear your cart?')) {
          clearCart();
        }
      };
  return (
    <div>
<Search optionalParam={{ IsPurchase_list: true }} />
    
    <div className='CartPage'>

          {PurchaseItems.length === 0 && (
      <>
       <div className="Cartemptymessage dark:text-white">
       <p>Your cart is empty.</p>
    
       </div>
      </>
    )}
    
        {
          /*
             <p className='totalItemsincart dark:text-white'>Items to Purchase: {getTotalItemsInCart()}</p>
    
          */
        }
    
     
         
          {PurchaseItems.length > 0 && (
    
            
         <>
         
         <div className='CartItems'> 
       
    
              
    {
      Object.entries(groupedCartItems).map(([parentGroup, suppliers]) => (
        <div key={parentGroup} className=' my-5 dark:border-gray-200 border-2 border-solid rounded '>
        
          <h2 className="dark:text-white">{parentGroup}</h2>
          {Object.entries(suppliers).map(([supplierGroup, { paymentModeGroups, subtotal }]) => (
            <div key={supplierGroup} className="supplier dark:text-white">
              <br />
              <h3>{supplierGroup} </h3>
              {Object.entries(paymentModeGroups).map(([paymentMode, paymentModeGroup]) => (
                <div key={paymentMode}>
                                     <PurchaseTable creditPeriodGroup={paymentModeGroup}/>

                </div>
              ))}
            </div>
          ))}
        </div>
      ))
    }
    
    
    
    
         </div>
         
         </>
         
         )}
         {PurchaseItems.length > 0 && (
    
    <div className="cartbottom dark:text-white">
    <div className="carttotal">
    {/*
    
    <p>Grand Total ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cartItems.reduce((acc, item) => acc + item.UnitPrice * item.Quantity * (1 - (item.Discount_Percentage / 100)), 0))}</p>
      <p>Grand Total: ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(PurchaseItems.reduce((total, item) => total + item.Total_Amount, 0))}</p>
    
    */}
    </div>
    <div className="placeorder">
    <button className='button_one' onClick={handleSubmitOrder}>Place Order</button>
    </div>
    <div className="clearcart">
    <button className='button_one danger' onClick={handleClearCart}>Clear Cart</button>
    </div>
    </div>
         )}
       
        </div>
    </div>
      );

}


const PurchaseTable = ({ creditPeriodGroup }) => {
  return (
    <div className="flex-initial w-full">
      <br />
    <table className='w-full text-sm text-left rtl:text-right  dark:text-gray-400'>
      <thead className="text-xs  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-blue-900 text-white">
          <th>Product</th>
          <th>Brand</th>
          <th>QTY</th>
         {
          /*
          
           <th>List Price</th>
          <th>Discount</th>
          <th>Nett</th>
          <th>Sub Total</th>
          */
         }
          <th></th>
        </tr>
      </thead>
      <tbody>
        {creditPeriodGroup.items.map((item) => (
          <Product_Purchase_Row key={item.Product.productId} cartItem={item} />
        ))}
      </tbody>
    </table>
    </div>
  );
};