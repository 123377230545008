import React from "react";

const LoadingAnimation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(50,50)">
        <circle
          cx="0"
          cy="0"
          r="0"
          fill="none"
          stroke="#ffffff"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            values="0; 18"
            dur="1.8s"
            repeatCount="indefinite"
            begin="0s"
            calcMode="spline"
            keySplines="0.75 0 0.25 1;0.75 0 0.25 1"
            keyTimes="0;1"
            />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 0 0"
            to="360 0 0"
            dur="0.6s"
            repeatCount="indefinite"
            />
        </circle>
        <circle
          cx="0"
          cy="0"
          r="0"
          fill="none"
          stroke="#ffffff"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            values="0; 18"
            dur="0.6s"
            repeatCount="indefinite"
            begin="0s"
            calcMode="spline"
            keySplines="0.75 0 0.25 1;0.75 0 0.25 1"
            keyTimes="0;1"
            />
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 0 0"
            to="-360 0 0"
            dur="1.8s"
            repeatCount="indefinite"
            />
        </circle>
      </g>
    </svg>
  );
};

export default LoadingAnimation;
