
import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "./Style/Style.css";
import AccrudeCharges from "./Accrude_charges/AccrudeCharges";
import Bank_Loan_Lease from "./Bank_Loan_Lease/Bank_Loan_Lease";
import Provision_for_Tax from "./Provision_for_Tax/Provision_for_Tax";
import Creditors from "./Creditors/Creditors";
import Import_Bill_Payable from "./Import_Bill_Payable/Import_Bill_Payable";
import Directors_C_A from "./Directors_C_A/Directors_C_A";

const tabsData = [
    { label: "-", component: <></> },
    { label: "(B4.1) Accrued Charges", component: <AccrudeCharges/> },
    { label: "Directors C/A", component: <Directors_C_A/> },
    { label: "(B4.2) Creditors", component: <Creditors/> },
    { label: "(B4.3) Bank Loan & Lease", component: <Bank_Loan_Lease/> },
    { label: "Import Bill Payable", component: <Import_Bill_Payable/> },
    { label: "(B4.4) Provision for Taxation", component: <Provision_for_Tax/> },
  ];
  


export default function  Current_Liablities(){
    
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);
    const handleTabChange = (event, newValue) => {
        setSelectedItem(newValue);
      };
    return(
        <>
        <div className="">
          <div className="">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
              className="tabs_Expenses"
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
          </div>
  <br />
          <div className="">
            {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    )
}