import customaxios from "../../helpers/axios-helper";
import { useParams } from 'react-router-dom';
import  { useState, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface RouteParams {
    id: string;
    [key: string]: string;
  }
export default function ProductImage() {

    const { productid } = useParams<RouteParams>();
    
    const [product, setProduct] = useState<any>();


    useEffect(() => {
        // get product data from custom axios
        customaxios.get(`Product/GetProduct_Image/${productid}`)
          .then((res) => {
            setProduct(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
        }, [productid]);
    
    return (
        <div>
        {product && <h1>Product Image for {product.oaM_ACode}</h1>}


        
        {product && <ImageRow productarch={product.productArch} />}

    </div>
    );
}

interface Image {
    src: string;
    alt: string;
  }
  interface ImageRowProps {
    productarch: any;
  }
  
function ImageRow({ productarch }: ImageRowProps) {
  
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  
    useEffect(() => {
      setSelectedImageIndex(0);
    }, [productarch]);
  
    const openModal = (url: string) => {
      setSelectedImage(url);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setSelectedImage("");
      setShowModal(false);
    };
  
    const handleLeftArrowClick = () => {
      setSelectedImageIndex((prevIndex) => {
        const newIndex = prevIndex === 0 ? productarch.archImages.length - 1 : prevIndex - 1;
        setSelectedImage(productarch.archImages[newIndex].url);
        return newIndex;
      });
    };
  
    const handleRightArrowClick = () => {
      setSelectedImageIndex((prevIndex) => {
        const newIndex = prevIndex === productarch.archImages.length - 1 ? 0 : prevIndex + 1;
        setSelectedImage(productarch.archImages[newIndex].url);
        return newIndex;
      });
    };
    const handleRemoveImage = () => {
      const updatedProductArch = {
        ...productarch,
        archImages: productarch.archImages.filter(
          (image) => image.url !== selectedImage
        ),
      };
      console.log("updatedProductArch");
      console.log(selectedImageIndex);
      customaxios
        .post("/api/updateProductArch", updatedProductArch)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      closeModal();
    };
    
    function handleKeyDown(e) {
      if (!showModal) return;
    
      if (e.keyCode === 37) { // left arrow
        handleLeftArrowClick();
      } else if (e.keyCode === 39) { // right arrow
        handleRightArrowClick();
      }
    }
    
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImageIndex]);
    return (
      <>
        
        <div className="imagerow">
  <div className="image-wrapper">
    {productarch.archImages.length > 0 ? (
      productarch.archImages.map((item, index) => (
        <div className="image" key={item.id} onClick={() => openModal(item.url)}>
          <img src={item.url} alt={productarch.productName} />
        </div>
      ))
    ) : (
      <div className="no-image">No image available</div>
    )}
  </div>
</div>
      
      
        {showModal && (
    <div className="imagerowmodal" onClick={closeModal}>
      <div className="imagerowmodal-content" onClick={(e) => e.stopPropagation()}>
        <p className='Title'>{productarch.productName}</p>
        <div className="image-index">{selectedImageIndex + 1} of {productarch.archImages.length}</div>
        <div className="modal-image">
          <img src={selectedImage} alt={productarch.productName} />
        </div>
        <div className="modal-image-arrows">
          <button className="arrow left-arrow" onClick={handleLeftArrowClick}>
            <FiChevronLeft />
          </button>
          <button className="arrow right-arrow" onClick={handleRightArrowClick}>
            <FiChevronRight />
          </button>
         
        </div>
      </div>
    </div>
  )}
      </>
    );
  }
