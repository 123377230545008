import React, { useEffect, useState ,useRef} from "react";  
import customaxios from "../../helpers/axios-helper";
import Loading_Default from "../../components/Loading_Defualt";
import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import {Modal, Box} from "@mui/material";
export default function Customer_Payments({CustomerId}){
    const [Payments, setPayments] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const [selectedAsset, setSelectedAsset] = useState(null);
   
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal
  const modalRef = useRef(null); // Reference to the modal
  
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleBackgroundClick = (event) => {
    // Close the modal if the click event target is the modal background
    if (event.target === modalRef.current) {
      setIsModalOpen(false);
    }
  };
    useEffect(() => {
        setLoading(true);
        setError("");
        console.log(CustomerId)
        customaxios
          .get("payment/GetPaymentBy_customer_Id/" + CustomerId)
          .then((response) => {
            console.log(response.data);
            setPayments(response.data);
          })
          .catch((error) => {
            console.error(error);
            setError("An error occurred while loading the payments.");
          })
          .finally(() => {
            setLoading(false);
          });
      }, [CustomerId]);
      const handleNameClick = (asset) => {
        setSelectedAsset(asset);
        console.log(asset)
      };
      if (loading) {
        return  <Loading_Default />;
      }
    
      if (error) {
        return <div>{error}</div>;
      }
    return(
        <>
 <Payment_Info open={selectedAsset !== null} onClose={() => setSelectedAsset(null)} 
 id={null} Payment={selectedAsset}/>    

         {isModalOpen && (
        <div className="controls-modal" ref={modalRef} onClick={handleBackgroundClick}>
          <div  className={`controls-modal-content`}>
            

          </div>
        </div>
      )}
        
        <table className="ledger_View_Table">
        <thead>
          <tr className="Ledger_View_Table_Row Title">
            <th className="Type">ID</th>
            <th className="voucher_Number">Value</th>
            <th className="Description">Allocation </th>
          
            {/*  <th className="Delete">Delete</th>*/}
          </tr>
        </thead>
        <tbody>
        {Array.isArray(Payments) &&Payments.map((Payment) => (
            <React.Fragment key={Payment.paymentId}>
              <tr className="Ledger_View_Table_Row">
              <td  onClick={() => handleNameClick(Payment)} className="Type">{Payment.paymentId.substring(0, 5)}</td>

                <td className="voucher_Number">{Payment.amount
                ? Payment.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "LKR",
                  })
                : "-"}</td>
                <td className="Description"> {Payment.paymentInvoices &&
                    Payment.amount &&
                    ((Payment.paymentInvoices.reduce(
                      (total, invoice) => total + invoice.amount,
                      0
                    ) /
                      Payment.amount) *
                      100
                    ).toFixed(2)}
                  %</td>
               
              </tr>
             
            </React.Fragment>
          ))}
        </tbody>
      </table>
        
        </>
    )
}

function Payment_Info({ id, open, onClose,Payment }){
    const [Selected_Payment, setSelected_Payment] = useState(null);
    const [loading, setLoading] = useState(false); // Step 1: Add a loading state

    console.log(Selected_Payment)
console.log(Payment)

    return( <Modal
        open={open}
        onClose={() => { onClose(); setSelected_Payment(null);  }}
      >

        <Box sx={{ position: "absolute", overflow: "auto", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "80vw", maxWidth: "1200px", height: "80vh", maxHeight: "900px", bgcolor: "background.paper", boxShadow: 24, p: 4 }}>
          {loading ? (
            <Loading_Default />
          ) : (
            <>
                  <h3>Settling payments for invoices</h3>
                  <table className="payment_info">
    <tbody>
        <tr>
            <td className="label">Amount</td>
            <td>: {Payment?.amount.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</td>
        </tr>
        <tr>
            <td className="label">Created Date</td>
            <td>: {Payment?.paymentDate.toLocaleDateString()}</td>
        </tr>
        <tr>
            <td className="label">Payment Mode</td>
            <td>: {Payment?.paymentMode}</td>
        </tr>
    </tbody>
</table>

                <table className="ledger_View_Table">
                    <thead>
                      <tr className="Ledger_View_Table_Row Title">
                        <th>Adjustment Date</th>
                        <th>Invoice No</th>
                        <th>Order Id</th>
                        <th>Amount</th>
                        <th>Allocated Proportion</th>
                      </tr>
                    </thead>
                    <tbody>
                    {Payment?.paymentInvoices.map((invoice) => {
          const paidProportion = (invoice.amount / Payment.amount) * 100;
          const paidPercentage = paidProportion.toFixed(2);
        
          const data = {
            datasets: [
              {
                data: [paidPercentage, 100 - Number(paidPercentage)],
                backgroundColor: ['#36A2EB', '#E5E5E5'] // blue and light gray
              }
            ]
          };
        
          const options = {
            responsive: false,
            cutoutPercentage: 70,
            legend: {
              display: false
            }
          };
        
          return (
            <tr className="Ledger_View_Table_Row" key={invoice.invoiceId}>
              <td>
                {invoice.paymentDate ? new Date(invoice.paymentDate).toLocaleDateString() : "-"}
              </td>
              <td>{invoice?.invoice?.invoice_Id_Simplified ?? ""}</td>
              <td>
                <Link
                  to={`/SalesOrder/${invoice?.invoice?.order?.salesOrder?.salesOrderId}`}
                  color="primary"
                >
                  {invoice?.invoice?.order?.orderId ?? ""}
                </Link>
              </td>
              <td>
                {invoice.amount
                  ? invoice.amount.toLocaleString("en-US", { style: "currency", currency: "LKR" })
                  : "-"}
              </td>
              <td style={{ display: 'flex', alignItems: 'center' }}>
                {paidPercentage ?? 0}%
                <Doughnut data={data} options={options} width={30} height={30} style={{ marginLeft: 5 }} />
              </td>
            </tr>
          );
        })}
        
        
                    
                    </tbody>
                  </table>
        {Payment?.cheques?.length > 0 ? (<>
            <h2>Cheques</h2>
        
            <table className="ledger_View_Table">
     <thead>
        <tr className="Ledger_View_Table_Row Title">
                        <th>Date</th>
                        <th>Cheque No</th>
                        <th>Bank</th>
                        <th>Amount</th>
                        <th>State</th>
                        <th>dep Account</th>
                        <th>dep Type</th>
                      </tr>
                    </thead>
        {Payment?.cheques?.map((cheque) => {
          return (
            <tbody>
                <tr className="Ledger_View_Table_Row" key={cheque.chequeId}>
              <td>{cheque.chequeDate ? new Date(cheque.chequeDate).toLocaleDateString() : "-"}</td>
              <td>{cheque.chequeNumber ?? "-"}</td>
              <td>{cheque.bank ?? "-"}</td>
              <td>
                {cheque.amount
                  ? cheque.amount.toLocaleString("en-US", { style: "currency", currency: "LKR" })
                  : "-"}
              </td>
              <td>{cheque.state ?? "-"}</td>
              <td>{cheque.deposite_Account ?? "-"}</td>
              <td>{cheque.deposite_Type ?? "-"}</td>
            </tr>
            </tbody>
          );
        })}
     </table>

        </>):(null)}
        
  
          </>
                     
          )}
        </Box>
      </Modal>)
}