
import React, { useState, useEffect } from "react";
import customaxios from "../../../helpers/axios-helper";
import { useAuthUser,useAuth } from '../../../contexts/auth-context'; 
import Loading_Defualt from "../../../components/Loading_Defualt";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate } from 'react-router-dom';
import Product_Purchase from "../Product_Purchase";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const tabsData = [
  { label: "Purchase List", component: <Product_Purchase_List/> },
  { label: "Purchase", component: <Product_Purchase/> },
 
];
export default function Purchase_Dashboard(){
  const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

  const handleTabChange = (event, newValue) => {
    setSelectedItem(newValue);
  };
    const isAuth = useAuth();

    const AuthUser = useAuthUser();
    const navigate = useNavigate();
    if (!isAuth) {
      // Redirect to the login page if not verified
      navigate('/');
    }

    // Replace 'role1', 'role2', etc., with the actual roles you want to check
    const allowedRoles = ['Director','Developer','Praveen'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }


  
    return(
        <>
        <h2>Purchase Dashboard</h2>
        <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={selectedItem}
            onChange={handleTabChange}
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                value={tab.label}
                className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
              />
            ))}
          </Tabs>

          <div className="data_view">
          {tabsData.map((tab, index) => (
            <React.Fragment key={index}>
              {selectedItem === tab.label && tab.component}
            </React.Fragment>
          ))}
        </div>
      
        </>
    )
}


function Product_Purchase_List(){
  const isAuth = useAuth();
  const [isLoading, setIsLoading]  = React.useState<boolean>(true);
  const [PurchaseList, setPurchaseList] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(0);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
    useEffect(() => {
      let url = `/Purchase/Purchase_Order_List_Summery?pageIndex=${page + 1}&pageSize=${rowsPerPage}` ;
    
     
      console.log("url",url);
    
      customaxios.get(url)
        .then((res) => {
       //   console.log(res.data);
          setPurchaseList(res.data);
          setIsLoading(false);
        });
    }, [page, rowsPerPage, isAuth]);


  return(
    <div className="Wrapper">
          
   
    {isLoading ? (
      <Loading_Defualt />
    ) : (
      <>
       <TablePagination
      rowsPerPageOptions={[5,10, 50,100]}
      component="div"
      count={PurchaseList.totalCount ? PurchaseList.totalCount : 0}
      rowsPerPage={rowsPerPage ? rowsPerPage : 25}
      page={page ? page : 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
<table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
<tr>
<th>Date</th>
<th>Id</th>
<th>Created By</th>
<th>Purchase_Order_Count</th>
<th>Status</th>
</tr>
</thead>

<tbody>
{isLoading ? (
<Loading_Defualt/>
):( PurchaseList.items?.map((Purchase) => {
return (
<tr

onClick={() => {

  window.location.href = `/P/${Purchase.purchase_Order_ListId}`;
}}  
key={Purchase.purchase_Order_ListId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">  
<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
{new Date(Purchase.created_Date_Time).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}</td>  
<td>{Purchase.purchase_Order_ListId}</td>  
<td>{Purchase.email}</td>  
<td>{Purchase.purchase_Order_Count}</td>  
<td>{Purchase.status
    }</td>  

  </tr>

);  

})
)}


</tbody>
      </table>


     

      </>
       )}
 
</div>
  )
}