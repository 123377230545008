import CreateAdministration_Expense from "./CreateAdministration_Expense";
import ListExpenses from "./ListExpenses"
export default function AdministrationExpense (){


    return(
        <>
      
        <CreateAdministration_Expense/>
        <br />
        <ListExpenses/>
        </>
    )
}