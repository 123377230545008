import ListCreditors from "./ListCreditors";
import CreateAccrudeCharges from "./CreateCreditors";
export default function  Creditors (){
    
    return(
    <>
   
      <CreateAccrudeCharges/>
        <br />
        <ListCreditors/>
    </>)
}