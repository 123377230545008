import { useParams } from 'react-router-dom';
import React, { useEffect, useState, ChangeEvent, useCallback } from "react";

import customaxios from "../../../helpers/axios-helper";
import { AuthProvider, useAuth, useAuthUser } from "../../../contexts/auth-context";

import OrderItems_comp_2 from './OrderItems_comp_2';

import Loading_Defualt from "../../../components/Loading_Defualt";

interface RouteParams {
  id: string;
  [key: string]: string;
}

const OrderPage = ({ SalesOrder,v2,orderid  }: any,) => {
  const isAuth = useAuth();
  const AuthUser = useAuthUser();
  const { id } = useParams<RouteParams>();

  const [order, setOrders] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    customaxios.get("SalesOrder/GetOrder_info/"+orderid).then((res) => {
    
      setOrders(res.data)
      setIsLoading(false);

    });
 
  }, [orderid]);

  

  const updateOrderStatus = (id: number, newStatus: string) => {
    setOrders((prevOrder) => {
      const updatedOrderItems = prevOrder.ordeOrderItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            orderItemStatus: newStatus,
          };
        }
        return item;
      });
      return {
        ...prevOrder,
        ordeOrderItems: updatedOrderItems,
      };
    });
  };
  
  if (isLoading) {
    return (    <Loading_Defualt />);
  }
  return (
  
    <div 
    className={v2 === true ? (
      `OrderV2 ${
        order.billing_Group_Contained.name === "Maruti OE - RAH Series" ? "Maruti" : 
        order.billing_Group_Contained.name === "Harvestor H Series" ? "Harvestor" :
        order.billing_Group_Contained.name === "Simpsons OE" ? "Simpsons_OE" :
        order.billing_Group_Contained.name === "Tafe OE" ? "Tafe_OE" :
        order.billing_Group_Contained.name
      }`
    ) : (
      `Order`
    )}
  >
   
          <OrderItems_comp_2
            onStatusChange={updateOrderStatus}
            orders={order}
          />
      
  


  
    </div>
  );
};

export default OrderPage;
