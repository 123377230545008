import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthUser,useAuth } from '../../../contexts/auth-context'; 
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef,useContext } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import "./Style/Style.css"
import React, { useEffect  } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../../helpers/axios-helper";
import MovementComparison from '../../Product/Arch/Arch_Page/MovementComparison';
import Movement from '../../Product/Arch/Arch_Page/Movement';


export default function Purchase_Modal({ id}){


  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  }; 
  const AuthUser = useAuthUser();

  const modalRef = useRef(null); // Reference to the modal
  const isAuth = useAuth();
  const handleBackgroundClick = (event) => {
    // Close the modal if the click event target is the modal background
    if (event.target === modalRef.current) {
      setIsModalOpen(false);
    }
  };

  const [archinfo, setarchinfo] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [product_Stats, setproduct_Stats] = useState<any>([]);

  useEffect(() => {
    if (isModalOpen && (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer")) {
      customaxios.get(`ProductArch/Get_Arch/${id}`)
        .then((response) => {
          setarchinfo(response.data.arch_data);
          setProducts(response.data.products);
        });
  
      customaxios.get(`stock/GetPurchaseTrend_Arch/${id}`)
        .then((response) => {
          setproduct_Stats(response.data);
        });
    }
  }, [AuthUser?.role, id, isModalOpen]);


    return(
        <>
        
     {isAuth ? ( <div
     
     onClick={handleModalToggle}
 
   >
    
     <FontAwesomeIcon icon={faBars} />
   </div>):(null)}

   {isModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-slate-600" ref={modalRef} onClick={handleBackgroundClick}>
    <div className="bg-white rounded-lg p-6 shadow-md w-4/5">
      <h3 className="text-xl font-semibold mb-4">{archinfo?.productName}</h3>
      <Movement id={id}/>
    </div>
  </div>
)}

        </>
    )
}