import ListImport_Bill_Payable from "./ListImport_Bill_Payable";
import CreateImport_Bill_Payable from "./CreateImport_Bill_Payable";
export default function  Import_Bill_Payable (){
    
    return(
    <>
   
      <CreateImport_Bill_Payable/>
        <br />
        <ListImport_Bill_Payable/>
    </>)
}