import React, { useEffect, useState } from 'react';
import customaxios from '../../helpers/axios-helper';
import './Style/Style.css';

export default function ChequeSearch() {
  const [keyword, setKeyword] = useState('');
  const [cheques, setCheques] = useState([]);

  useEffect(() => {
    if (keyword.trim() !== '') {
      searchCheques();
    } else {
      setCheques([]);
    }
  }, [keyword]);

  const searchCheques = () => {
    customaxios.get(`cheque/SearchChequesByChequeNumber?chequeNumber=${keyword}`)
      .then(response => {
        setCheques(response.data);
      })
      .catch(error => {
        console.error('Error fetching cheques:', error);
      });
  };

  return (
    <>

        <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input type="text"
        value={keyword}
        onChange={event => setKeyword(event.target.value)}
        placeholder="Search by cheque number..." 
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
        />
    </div>
{cheques.length > 0 ? (  <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
<tr >
                <th>Number</th>
                <th>Bank</th>
                <th>Amount</th>
                <th>Date</th>
                <th>State</th>
              </tr>
            </thead>
      <tbody>
          {cheques.map((cheque) => (
            <tr className={`bg-white border-b dark:bg-gray-800 dark:border-gray-500 hover:bg-gray-50 dark:hover:bg-gray-600 ${cheque.state}`} key={cheque.chequeId}>
            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{cheque.chequeNumber}</td>
            <td>{cheque.bank}</td>
              <td>{cheque.amount}</td>
              <td>{new Date(cheque.chequeDate).toLocaleDateString('en-UK', { day: 'numeric', month: 'short', year: '2-digit' })}</td>
              <td>{cheque.state}</td>
            </tr>
          ))}
        </tbody>
      </table>):(null) }
  

      
    </>
  );
}
