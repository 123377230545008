import { ReactComponent as Spinner } from '../assets/vectors/spinner.svg';
import $ from "jquery";


const DEFAULT_TEXT: string = "Loading...";

export default function Loader() {
  return (
    <div className="loader-wrapper hidden disabled">
      <div className="loader">
          <Spinner />
          <p></p>
      </div>
    </div>
  );
}

export const enableLoad = (text? : string) => {
    text == null || text === "" ?
    $('.loader-wrapper > .loader > p').html(DEFAULT_TEXT)
    :
    $('.loader-wrapper > .loader > p').html(text);

    $('.loader-wrapper').removeClass('hidden');
    setTimeout(() => {
        $('.loader-wrapper').removeClass('disabled').removeClass('enabled').addClass('enabled');
    }, 10);
}

export const disableLoad = () => {
  $('.loader-wrapper').removeClass('enabled').removeClass('disabled').addClass('disabled');
  setTimeout(() => {
      $('.loader-wrapper').removeClass('hidden').addClass('hidden');
  }, 300);
}