import React, { useState, useEffect } from 'react';
import customaxios from '../../../helpers/axios-helper';

export default function SelectBranch({ onBranchSelected }) {
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    customaxios.get('Branch/GetAllBranches')
      .then(response => setCategories(response.data))
      .catch(error => console.log(error));
  }, []);

  const handleBranchChange = (event) => {
    const Branch = event.target.value;
    setSelectedBranch(Branch);
    onBranchSelected(Branch);
  };

  return (
    <div className='selectBranch'>
        <p>Branch:</p>
     <select value={selectedBranch} onChange={handleBranchChange}>
  <option value="">Select Branch...</option>
  {categories.map((Branch) => (
    <option key={Branch.branchId} value={Branch.branchId}>
      {Branch.description}
    </option>
  ))}
</select>

    </div>
  );
}
