import React, { useEffect, useState, useRef } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Defualt from "../../../Loading_Defualt";
import { useAuthUser,useAuth } from '../../../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';
interface Category {
  product_groupId: any;
  group_Name: string;
  itemcount: number;
  archCount: number;
  image_url: string;
}

export default function Category_GridView() {
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [currentLetter, setCurrentLetter] = useState("");
  const letterRefs = useRef<{ [key: string]: HTMLElement }>({});
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const AuthUser = useAuthUser();
  const isAuth = useAuth();
  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles = ['Director','Developer','Praveen','Sales_Rep','Billing_Agent'];

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }
  function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchInput(e.target.value);
  }


  function clearLocalStorage() {
    localStorage.removeItem("categoriesList");
    setCategoriesList([]);
    setCurrentLetter("");
  }
  useEffect(() => {
    const storedData = localStorage.getItem("categoriesList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setCategoriesList(data);
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("categoriesList");
      }
    }
  
    customaxios.get("product/getproductgroups").then((response) => {
      setCategoriesList(response.data);  
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("categoriesList", JSON.stringify(data));
    });
  }, []);
  


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let closestLetter = null;
        let minDistance = Number.MAX_VALUE;
        entries.forEach((entry) => {
          const letter = entry.target.getAttribute("data-letter");
          if (entry.isIntersecting) {
            const distance = Math.abs(entry.boundingClientRect.top);
            if (distance < minDistance) {
              closestLetter = letter;
              minDistance = distance;
            }
          }
        });
        if (closestLetter !== null) {
          setCurrentLetter(closestLetter);
        }
      },
      { threshold: 1 }
    );
  
    Object.values(letterRefs.current).forEach((ref) => {
      observer.observe(ref);
    });
    return () => {
      observer.disconnect();
    };
  }, [currentLetter]);
  
  categoriesList.sort((a, b) => a.group_Name.localeCompare(b.group_Name));
  const filteredCategories = categoriesList.filter((category) =>
  category.group_Name.toLowerCase().includes(searchInput.toLowerCase())
);
  const groupedCategories = filteredCategories.reduce((groups, category) => {
    const firstLetter = category.group_Name.charAt(0).toUpperCase();
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(category);
    return groups;
  }, {});


if (loading) { // Display loading while fetching data
  return <Loading_Defualt/>;
}
  return (
    <div>
        <h3>Search Categories</h3>

      <input className="groupProductview"
      type="text"
      placeholder="Search categories..."
      value={searchInput}
      style={{ border: "2px solid rgb(248, 81, 73)" }}
      onChange={handleSearchInput}
    />
    <button onClick={clearLocalStorage}>Force_Re Cache</button>

      {Object.entries(groupedCategories).map(([letter, categories]: [string, Category[]]) => (
        <div className="CategoryPage" key={letter}>
          <h3
            className={`Letter ${currentLetter === letter ? "active" : ""}`}
            ref={(el) => {
              letterRefs.current[letter] = el!;
            }}
            data-letter={letter}
          >
            {letter}  {/*currentLetter === letter ? "Group Count " +categories.length : ""*/}
          </h3>
          <div className="BrandCard_wrapper">
            {categories.map((category) => (
              <a key={category.product_groupId} href={"/ProductsByCategory/"+ category.product_groupId}>
 <div
                  className="BrandCard"
                  style={{
                    backgroundImage: category.image_url ? `url(${category.image_url})` : '',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p className="ItemName">
                    {!category.image_url && category.group_Name}
                  </p>
                  <p className="ProductCount">
                    {"Products " + category.itemcount}
                  </p>
                  <p className="ArchCount">
                    {"Arch " + category.archCount}
                  </p>
                </div>
              </a>
           
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
