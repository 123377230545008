import PropTypes from 'prop-types';

function Purchase_Price({ value, onChange }) {

  return (
   
    <div className="Nett_Price">
         <div className="Nett_Price-input">
      <p>Purchase Price:</p>
      <div className="Nett_Price-control">
      
        <input
          type="number"
          className="discount-field"
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
        
      </div>
    </div>
    </div>
  );
}

Purchase_Price.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Purchase_Price;
