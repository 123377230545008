import CreateFinanceExpense from "./CreateFinanceExpense";
import ListExpenses from "./ListExpenses"
import LedgerView from "../../Ledger/LedgerView";
export default function FinanceExpenses (){


    return(
        <>
      
        <CreateFinanceExpense/>
        <br />
        <ListExpenses/>
        </>
    )
}