export const ALLOWED_IMG_TYPES: string = ".jpg,.jpeg,.png,.svg,.webp";

export const TAKE : Number = 10;

export const DISTRICTS : string[] = ['', 'Colombo', 'Kandy', 'Galle', 'Ampara', 'Anuradhapura', 'Badulla', 'Batticaloa', 'Gampaha', 'Hambantota', 'Jaffna', 'Kalutara', 'Kegalle', 'Kilinochchi', 'Kurunegala', 'Mannar', 'Matale', 'Matara', 'Moneragala', 'Mullativu', 'Nuwara Eliya', 'Polonnaruwa', 'Puttalam', 'Ratnapura', 'Trincomalee', 'Vavuniya'];

export const GUEST_USERNAME: string = "GuestUser";

export const DEFAULT_SORT = 'Id';
export const USERS_SORT : string[] = ['Name', 'Email'];
export const BRANCHES_SORT : string[] = ['Branch name'];
export const PRODUCTS_SORT : string[] = ['Product name', 'Manufacturer', 'Total stock', 'Units sold', 'Category'];
export const BATCHES_SORT : string[] = ['Product', 'Branch', 'Stock', 'Units sold', 'Selling price', 'Buying price', 'Date of expiry'];
export const SUPPLIERS_SORT : string[] = ['Name', 'No. of orders'];
export const OUTBOUNDS_SORT : string[] = ['Type', 'Date requested', 'Requested by', 'Status', 'Total'];
export const RESUPS_SORT : string[] = ['Date made', 'Date delivered', 'Status'];
export const INBOUNDS_SORT : string[] = ['Cashier', 'Customer', 'Date made', 'Branch', 'Total', 'Status'];

export const STOCK_DEFAULT: string = "All"
export const STOCK_FILTERS: string[] = ["Out of stock", "In stock"]

export const OUT_STATUS_DEFAULT: string = "All"
export const OUT_STATUS_FILTERS: string[] = ["Pending", "Approved", "Completed", "Rejected"]
export const RESUP_STATUS_FILTERS: string[] = ["Shipping", "Arrived"]
export const IN_STATUS_DEFAULT: string = "All"
export const IN_STATUS_FILTERS: string[] = ["Incomplete", "Complete"]

export const DATE_PRESETS: string[] = ["Last 7 days", "Last 30 days", "Last 3 months", "Last 6 months", "Custom"]