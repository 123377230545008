
import { useEffect, useState } from "react";  
import customaxios from "../../helpers/axios-helper";
import 'react-toastify/dist/ReactToastify.css';
import LedgerRow from "./LedgerRow";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function Customer_ledger({Customerid}:any) {
  
    const [CustomerLedgers, setItems] = useState([]);
    //axios call to get items post request
    useEffect(() => {
        customaxios.post('Customer/GetCustomerById/',{
            Customerid: Customerid,
        })
        .then((response) => {
            console.log(response);
            setItems(response.data.transaction_Ledgers);
            console.log(response.data);
        })
    }, [Customerid]);
    return (
      <div className="customerinformation">

     
          <div className="customerlegers_">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"> </TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Voucher no</TableCell>

                    <TableCell align="left">descriptions</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Debit</TableCell>
                    <TableCell align="left">Credit</TableCell>
                    <TableCell align="left">stringbalance</TableCell>
                    <TableCell align="left"> </TableCell>
                    <TableCell align="left"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CustomerLedgers.map((row) => (
                    <LedgerRow key={row.name} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
       
    </div>
    );
  }
  