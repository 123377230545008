import React,{useEffect,useState, Suspense} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import customaxios from "../../helpers/axios-helper";
import { useAuthUser,useAuth } from '../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';
import ProductRow from "../Product/Arch/productrow";
import ProductArch from "../Product/Arch/ProductArch";
import PrintComponent from "../PrintComponent/PrintComponent";
interface RouteParams {
    id: string;
    [key: string]: string;
  }

export default function Supplier() {
    const { id } = useParams<RouteParams>();
    const AuthUser = useAuthUser();
    const isAuth = useAuth();

    const Tabs_list = [
        { label: "Products", component: <Supplier_Items id={id}/> },
        { label: "Archs", component: <Supplier_Items_By_Arch id={id}/> },
      
      ];
  const [selectedItem, setSelectedItem] = useState(Tabs_list[0].label);
  const handleTabChange = (event, newValue) => {
    setSelectedItem(newValue);
  };
    const navigate = useNavigate();
    if (!isAuth) {
      // Redirect to the login page if not verified
      navigate('/');
    }
  
    // Replace 'role1', 'role2', etc., with the actual roles you want to check
    const allowedRoles =['Director','Developer','Praveen','CEO'] ;
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }


   
    
    return(
        <>
<PrintComponent>

        <SupplierInfo id={id}/>
        <Tabs
  orientation="horizontal"
  variant="scrollable"
  value={selectedItem}
  onChange={handleTabChange}
  className="tabs_side"
>
  {Tabs_list.map((tab, index) => (
    <Tab
      key={index}
      label={tab.label}
      value={tab.label}
      className="border-b-2 border-transparent hover:border-gray-500"
    />
  ))}
</Tabs>


<div className="max-w-5xl mx-auto">
  {Tabs_list.map((tab, index) => (
    <React.Fragment key={index}>
      {selectedItem === tab.label && tab.component}
    </React.Fragment>
  ))}
</div>

</PrintComponent>
                
        </>
    )
}

function Supplier_Items_By_Arch({id}){
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        customaxios.get(`supplier/GetSupplierProducts_Arch/${id}`)
            .then((response) => {
                console.log(response);
                setProductList(response.data);
            })
    }, [id]);
    return(
        <Suspense fallback={<div>Loading...</div>}>
        {productList.map((row, index) => (
            <ProductArch key={row.productArch.productArchId} products={row.products} arch={row.productArch}/>
        ))}

      </Suspense>
    )
}

function Supplier_Items({ id }) {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    customaxios.get(`supplier/GetSupplierProducts/${id}`)
      .then((response) => {
        console.log(response);
        setProductList(response.data);
      })
  }, [id]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50">
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Product
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Opening
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Sold/Purchased
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Closing
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {productList.map((row, index) => (
            <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
              <td className="px-6 py-4 whitespace-nowrap">
                <ProductRow handleReturnItem={()=>{}} index={row.productId} IsPos={false} addtocart={true} product={row.product} IsPurchase_list={true} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {row.currentYear_Stocksummary?.openingBalance} {row.currentYear_Stocksummary?.openingUnits}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                ({row.totalOutUnits}/{row.totalInUnits})
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {row.currentYear_Stocksummary?.closingBalance} {row.currentYear_Stocksummary?.closingUnits}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Suspense>
  );
}


interface Supplier {
  supplier_Name: string;
  supplier_Short: string;
  total_Products: number;
  // Add more properties if there are any
}

interface SupplierInfoProps {
  id: string; // Assuming id is of type string, change it accordingly if it's different
}

function SupplierInfo({ id }: SupplierInfoProps): JSX.Element {
  const [Supplier, setSupplier] = useState<Supplier | null>(null);

  useEffect(() => {
    customaxios.get<Supplier>(`supplier/GetSupplier_Information/${id}`)
      .then((response) => {
        console.log(response);
        setSupplier(response.data);
      })
      .catch((error) => {
        console.error('Error fetching supplier information:', error);
      });
  }, [id]);

  return (
    <div className="max-w-lg mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-md overflow-hidden">
      <div className="p-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold dark:text-white">{Supplier?.supplier_Name}</h1>
          <span className="bg-gray-200 dark:bg-gray-700 rounded-full px-3 py-1 text-sm text-gray-700 dark:text-gray-200">
            {Supplier?.total_Products} Products
          </span>
        </div>
        <p className="text-gray-500 dark:text-gray-300 mt-2">{Supplier?.supplier_Short}</p>
      </div>
    </div>
  );
  
}


