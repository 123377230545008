import React,{useState} from 'react';
import './Style_Notes/Style.css';
import customaxios from "../../../../helpers/axios-helper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuthUser } from '../../../../contexts/auth-context';
import { toast } from 'react-toastify';

interface NotesListProps {
  notes: any[] | null;
  refId: any;
}

const NotesList: React.FC<NotesListProps> = ({ notes,refId }) => {
    const user = useAuthUser();
  const currentDate = new Date();
  const [newNote, setNewNote] = useState('');
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote(event.target.value);
  };

  const handleNoteSubmit = async () => {
    try {
      // Customize the Axios request as per your backend API
      const response = await customaxios.post('/Notes/Create_Note', {
        note_Message: newNote,
        createdBy:{
            id:user?.id,
        },
        orderItemId:refId,

      });
      console.log(response.data); // Handle the response accordingly
      notes.push(response.data);
      toast.success("Note Added Successfully");
      // Clear the input field after submitting the note
      setNewNote('');
    } catch (error) {
      console.error(error); // Handle the error case
    }
  };



  const isYesterday = (noteDate: Date): boolean => {
    return (
      noteDate.getDate() === currentDate.getDate() - 1 &&
      noteDate.getMonth() === currentDate.getMonth() &&
      noteDate.getFullYear() === currentDate.getFullYear()
    );
  };

  const getFormattedDateTime = (noteDate: Date): string => {
    if (isYesterday(noteDate)) {
      return noteDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
      });
    } else {
      return noteDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  return (
<>
<div className="Note_ui">

      {notes?.map((note) => (
        <div className="Note" key={note.id}>
          <div className="Info">
            <div className="note_Creator">
            {note.createdBy && note.createdBy.firstName ? note.createdBy.firstName : ""}
            {" "}
            {note.createdBy && note.createdBy.lastName ? note.createdBy.lastName : ""}

         </div>
            <div className="created_Datetime">
              {getFormattedDateTime(new Date(note.created_datetime))}
            </div>
          </div>
          <div className="Message">{note.note_Message}</div>
        </div>
      ))}
      {!notes && (<div className="Note">No Notes Available</div>)}
    </div>
    <div className="note_Creator">
    <input
      type="text"
      value={newNote}
      onChange={handleInputChange}
      placeholder="Enter your note..."
    />
<button className='button_one sendicon' onClick={handleNoteSubmit}>
    <FontAwesomeIcon icon={faPaperPlane} />
</button>
</div>
</>
  );
};

export default NotesList;
