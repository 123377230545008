import React, { useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import { toast } from "react-toastify";
import "./style/Style.css";

export default function Upload_New_Items() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    let timer;
    if (isCompleted) {
      timer = setTimeout(() => {
        setIsCompleted(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isCompleted]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadClick = () => {
    if (selectedFile) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);
      customaxios
        .post("Product/newitem", formData)
        .then((response) => {
          setIsLoading(false);
          setIsCompleted(true);

          toast.success("File uploaded successfully.");
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Upload failed:", error);
          toast.error("Failed to upload file.");
        });
    }
  };

  return (
    <>
    <h3>This will Create New products if ItemDetails dont find existing item</h3>
      <input type="file" onChange={handleFileChange} />
      <button className="button_one" onClick={handleUploadClick} disabled={isLoading || !selectedFile}>
        {isLoading ? "Uploading" : isCompleted ? "Completed" : "Upload"}
        {isLoading && (
          <span className="dots-animation">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        )}
      </button>

      <div className="format">
  <table>
<strong> upload without the header row</strong>
    <thead>
      <tr>
        <th>Product Group</th>
        <th>Item grouping</th>
        <th>model_2</th>
        <th>Suppliercode</th>
        <th>OAM ACode</th>
        <th>Item Details</th>
        <th>Product Name</th>
        <th>Model</th>
        <th>Supplier Name</th>
        <th>Brand Name</th>
        <th>Item Track</th>
        <th>Quantity</th>
        <th>Quantity Units</th>
        <th>Sale Price</th>

      </tr>
    </thead>
    <tbody>
     
      {/* Add more rows as needed */}
    </tbody>
  </table>
</div>

    </>
  );
}
