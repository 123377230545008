import Cookies from 'js-cookie'

export const addCookie = (name: string, value: string, expiry: Date) => {
    Cookies.set(name, value, { 
        path: "/",
        expires: expiry
    });
}

export const removeCookie = (name: string, expiry: Date) => {
    Cookies.remove(name, { 
      path: "/",
      expires: expiry 
    });
}