import React, { useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import { toast } from "react-toastify";
import "./style/Style.css";

export default function Upload_Price_List() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    let timer;
    if (isCompleted) {
      timer = setTimeout(() => {
        setIsCompleted(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isCompleted]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadClick = () => {
    if (selectedFile) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);

      customaxios
        .post("Product/PriceCorrection", formData)
        .then((response) => {
          setIsLoading(false);
          setIsCompleted(true);

          toast.success("File uploaded successfully.");
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Upload failed:", error);
          toast.error("Failed to upload file.");
        });
    }
  };

  return (
    <>
    <h3>this will update  QTY AND UNITS AND PRICE ONLY</h3>
      <input type="file" onChange={handleFileChange} />
      <button className="button_one" onClick={handleUploadClick} disabled={isLoading || !selectedFile}>
        {isLoading ? "Uploading" : isCompleted ? "Completed" : "Upload"}
        {isLoading && (
          <span className="dots-animation">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </span>
        )}
      </button>

      <div className="format">
  <table>
<strong> upload without the header row</strong>
    <thead>
      <tr>
        <th>Acode</th>
        <th>ItemDetails</th>
        <th>OamName</th>
        <th>Qty</th>
        <th>Unit</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Value for Acode</td>
        <td>Value for ItemDetails</td>
        <td>Value for OamName</td>
        <td>Value for Qty</td>
        <td>Value for Unit</td>
        <td>Value for Price</td>
      </tr>
      {/* Add more rows as needed */}
    </tbody>
  </table>
</div>

    </>
  );
}
