import React, { useState } from 'react';
import customaxios from '../../../../helpers/axios-helper';
import Modal from 'react-modal';

interface GeneralExpense {
  name: string;
  description: string;
  ledger_Id?: number;
  Company_Name:string;
  Value: number;
}

const CreateCreditors: React.FC = () => {
  const [formData, setFormData] = useState<GeneralExpense>({
    name: '',
    description: '',
    Value: 0,
    Company_Name: '',

  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: name === 'Value' ? parseFloat(value) : value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await customaxios.post('Creditors/Add', formData);
      console.log('New Creditors ID:', response.data);
      // Optionally, you can perform actions after a successful creation, like redirecting or showing a success message.
      closeModal();
    } catch (error) {
      console.error('Error Creditors Expense:', error);
      // Optionally, you can handle errors here, e.g., showing an error message to the user.
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button className='button_one'  onClick={openModal}>Create Creditors</button>
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Create Creditors Modal"
>
  <div className="p-4 max-w-md mx-auto">
    <h2 className="text-lg font-bold mb-4">Create Creditors</h2>
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block mb-2">Company:</label>
        <select
          name="Company_Name"
          value={formData.Company_Name}
          onChange={handleChange}
          id="Type"
          className="w-full border p-2 rounded"
        >
          <option value="-">-</option>
          <option value="OAM">OAM</option>
          <option value="RAH-Maruti">RAH-Maruti</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full border p-2 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Description:</label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          className="w-full border p-2 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Amount:</label>
        <input
          type="number"
          name="Value"
          value={formData.Value.toString()}
          onChange={handleChange}
          required
          className="w-full border p-2 rounded"
        />
      </div>
      <div className="flex justify-end">
        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2">
          Create
        </button>
        <button onClick={closeModal} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded">
          Cancel
        </button>
      </div>
    </form>
  </div>
</Modal>

    </div>
  );
};

export default CreateCreditors;
