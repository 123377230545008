import ListBank_Loan_Lease from "./ListBank_Loan_Lease";
import CreateBank_Loan_Lease from "./CreateBank_Loan_Lease";
export default function  Bank_Loan_Lease (){
    
    return(
    <>
   
      <CreateBank_Loan_Lease/>
        <br />
        <ListBank_Loan_Lease/>
    </>)
}