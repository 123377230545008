import React, { useState, useEffect } from "react";
import customaxios from "../../helpers/axios-helper";
import { Customer } from '../../models/Customer/Customer';
import { useCart } from '../Cart/CartProvider';
import { useAuth, useAuthUser } from '../../contexts/auth-context';

function SelectedCustomer() {
  const AuthUser = useAuthUser();
  const isAuth = useAuth();
  const { AddCustomer, customer } = useCart();

  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [relatedCustomer, setRelatedCustomer] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    if (isEditingCustomer) {
      customaxios.post("/Customer/SearchCustomer", { keyword: searchKeyword })
        .then((response) => {
          setRelatedCustomer(response.data.customers);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isEditingCustomer, searchKeyword]);

  const handleCustomerChange = (event) => {
    const keyword = event.target.value;
    setSearchKeyword(keyword);
    const emptyCustomer = { ...customer, customerName: keyword }; // Update customer name in local state
    AddCustomer(emptyCustomer);
  };

  const handleSaveChanges = (selectedCustomer) => {
    setIsEditingCustomer(false);
    AddCustomer(selectedCustomer);
  };

  const handleCancelEditing = () => {
    setIsEditingCustomer(false);
    setSearchKeyword('');
  };

  return (
    <>
      {AuthUser?.role !== "Customer" && (
        <div className="">
          {isEditingCustomer ? (
            <div className="Customer_Name">
              <input
                className="border border-gray-300 rounded px-2 py-1 mr-2"
                type="text"
                value={searchKeyword}
                onChange={handleCustomerChange}
              />
             
              <button
                className="bg-gray-500 text-white px-2 py-1 rounded"
                onClick={handleCancelEditing}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
              onClick={() => setIsEditingCustomer(true)}
            >
              Customer: {customer.customerName}
            </button>
          )}

          {isEditingCustomer && relatedCustomer.length > 0 && (
            <ul className='CustomerSelectlist border border-gray-300 rounded mt-2 max-h-40 overflow-y-auto'>
              {relatedCustomer.map(item => (
                <li
                  className='CustomerSelect cursor-pointer px-2 py-1 hover:bg-gray-100'
                  key={item.customerId}
                  onClick={() => handleSaveChanges(item)}
                >
                  {item.customerName}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
}

export default SelectedCustomer;
