import React, { useState } from 'react';
import { toast } from 'react-toastify';
import customaxios from '../../helpers/axios-helper';
import { useAuthUser } from '../../contexts/auth-context';
import { Menu, Modal } from '@mui/material';
import "./Status_Model.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface StatusDropdownProps {
  status: string;
  id: Number;
  item: any;
  onStatusChange: (id: Number, newStatus: string) => void;
}

const StatusDropdown_SO: React.FC<StatusDropdownProps> = ({ status, id, item, onStatusChange }) => {
  const [orderStatus, setOrderStatus] = useState(status);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuthUser();
  const handleStatusChange = async (newStatus: string) => {
    console.log("item",item)
    console.log("newStatus",newStatus)
    setOrderStatus(newStatus);
    try {
      const response = await customaxios.post(`SalesOrder/UpdateOrderStatus/${item.salesOrderId}/${newStatus}`);
      console.log(response.data);

      toast.success(`Successfully updated status for Line Item # ${item.salesOrderId}`);

      onStatusChange(item.id, newStatus);

    } catch (error) {
      console.error(error);
      setOrderStatus(status);
      toast.error(`Failed to update status for Line Item # ${item.salesOrderId}`);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleOptionSelect = (newStatus: string) => {
    handleStatusChange(newStatus);
    handleMenuClose();
  };

  return (
    <>
   <div  className="status">
    <p onClick={() => setIsMenuOpen(true)}>{status === null ? "N/A" : status}</p>
</div>

   
     <Modal
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <Menu
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            className={`State_selector_orders ${orderStatus}`}
          >
            <h3>Item Status</h3>
               <List>
              {["Pending", "Incompleted", "Completed"].map(option => (
                <ListItem
                  key={option}
                  onClick={() => {
                    handleOptionSelect(option);
                    handleMenuClose();
                  }}
                  className={`options_${option}`}
                >
                  <ListItemText primary={option} />
                </ListItem>
              ))}
            </List>
          </Menu>
        </div>
      </Modal>
    
    </>
  );
};

export default StatusDropdown_SO;









{
  /*
  
  <select
  className={`State_selector_orders ${orderStatus}`}
  value={orderStatus}
  onChange={(event) => handleStatusChange(event.target.value)}
  disabled={!["CEO", "Director", "Developer", "Billing"].includes(auth?.role)}
>
  {["CEO", "Director", "Developer", "Billing"].includes(auth?.role) ? (
    <>
      <option className="options_Pending" value="Pending">
        Pending
      </option>
      <option className="options_Approved" value="Approved">
        Approved
      </option>
      <option className="options_Declined" value="Declined">
        Declined
      </option>
      <option className="options_ReadyToPack" value="ReadyToPack">
        Ready To Pack
      </option>
      <option className="options_ReadyToDispatch" value="ReadyToDispatch">
        Ready To Dispatch
      </option>
      <option className="options_Dispatch" value="Dispatch">
        Dispatch
      </option>
      <option className="options_Invoiced" value="Invoiced">
        Invoiced
      </option>
      <option className="options_Refund" value="Refund">
        Refund
      </option>
    </>
  ) : (
    <option className={`options_${orderStatus}`} value={orderStatus}>
      {orderStatus}
    </option>
  )}
</select>
  */
}