import { useState, useRef,useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Globals/GlobalInterface';
import { useAuth,useAuthUser } from '../../contexts/auth-context';
import Export_Products_without_Images from './Export_Products_without_Images/Export_Products_without_Images';
import Export_PriceList from './Export_Data_Feature/Export_PriceList';
import Arch_Refresh from './Arch_refresh/Arch_Refresh';
import { ThemeContext, Theme } from './Theme/ThemeContext';
import Export_Products_without_Images_byBrand from './Export_Products_without_Images_byBrand/Export_Products_without_Images_byBrand';
//import ThemeToggle from './Theme/ThemeToggle';
import Upload_Price_List from './Upload_Data/Upload_Price_List';
import ThemeToggle from '../../helpers/Theme'
import {
  toggleProductName,
  ArchImage,
  Togglezeroproduct,
  ProductLables,
  discountvalue,
  Archminmise,

} from '../../Actions/index';
import Switch from '@mui/material/Switch';
import SelectedCustomer from '../Customer/SelectedCustomer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab } from '@mui/material';
import './Styles/styles.css';
import Upload_New_Items from './Upload_Data/Upload_New_Items';
//  { label: "Discount", component: <DiscountControls /> },
const tabs = [

  { label: "Selected Customer", component: <SelectedCustomer /> },
  { label: "Controls", component: <ProductControls /> },
  { label: `Upload Price List`, component: <Upload_Price_List /> },
  { label: `Upload New Items`, component: <Upload_New_Items /> },
  { label: `Special Links`, component: <Special_Links /> },
];
function Special_Links (){
  return(
    <table>
<thead>
  <tr>
    <th>Desc</th>
    <th>Link</th>
  </tr>
</thead>
<tbody>
  <tr><td>User OTP</td>
  <td><a href="/u" target='_blank'>Open</a></td>
  </tr>
  <tr><td>Pending Products</td>
  <td><a href="/Pending_Products" target='_blank'>Open</a></td>
  </tr>
</tbody>
    </table>
  )
}
export default function Controls() {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const AuthUser = useAuthUser();
  const isAuth = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal
  const [activeTab, setActiveTab] = useState(0); // Add state for active tab
  const [isCogHovered, setIsCogHovered] = useState(false); // Add state for hover animation


  const modalRef = useRef(null); // Reference to the modal

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const filteredTabs = tabs.filter(tab => {
    if (tab.label === "Upload Price List" || tab.label === "Upload New Items" || tab.label === "Special Links"  ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen";
    } else {
      return true; // Show other tabs by default
    }
  });


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleBackgroundClick = (event) => {
    // Close the modal if the click event target is the modal background
    if (event.target === modalRef.current) {
      setIsModalOpen(false);
    }
  };
  return isAuth ? (
    <div className={`controls-container ${theme === Theme.Dark ? 'dark' : ''}`}>
      <div
        className={`controls-icon ${isCogHovered ? 'rotate-animation' : ''}`}
        onClick={handleModalToggle}
        onMouseEnter={() => setIsCogHovered(true)}
        onMouseLeave={() => setIsCogHovered(false)}
      >
        <FontAwesomeIcon icon={faCog} />
      </div>

      {isModalOpen && (
        <div className="controls-modal" ref={modalRef} onClick={handleBackgroundClick}>
          <div  className={`controls-modal-content ${theme === Theme.Dark ? 'dark' : ''}`}>
            
<div  className={`controls_Tabs ${theme === Theme.Dark ? 'dark' : ''}`}>
<Tabs value={activeTab} onChange={handleTabChange} className='home-tabs'>
          {filteredTabs.map((tab, index) => (
            <Tab key={index} label={tab.label} className='home-tab-label' />
          ))}
        </Tabs>
</div>
            {filteredTabs[activeTab].component}
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

function DiscountControls(){
  const dispatch = useDispatch();
  const _globalstate = useSelector((state: RootState) => state);
  const discount = _globalstate.Discount;
  const handlediscont = (discount) => {
    dispatch(discountvalue(discount));
    console.log(discount);
  };
  return(

    <div className="section">
        <input
          type="number"
          value={discount}
          onChange={(e) => handlediscont(parseInt(e.target.value))}
        />
      
  </div>
  )
}

function ProductControls(){
  const dispatch = useDispatch();
  const _globalstate = useSelector((state: RootState) => state);

  const ViewProductName = Boolean(_globalstate.productname);
  const handleSwitchChange = () => {
    dispatch(toggleProductName());
  };
  const ViewzeroProducts = Boolean(_globalstate.zeroqty);
  const handleZeroproductSwitchChange = () => {
    dispatch(Togglezeroproduct());
  };

  const rowlbl = Boolean(_globalstate.lable);
  const handlelables = () => {
    dispatch(ProductLables());
    console.log(rowlbl);
  };
  const archimages = Boolean(_globalstate.ArchImages);
  const handleArchImage = () => {
    dispatch(ArchImage());
    console.log(archimages);
  };

  
  const archiminimised = Boolean(_globalstate.Arch_minmise);
  const handleArch_minmise = () => {
    dispatch(Archminmise());
    console.log("archiminimised");
    console.log(archiminimised);
  };
  const AuthUser = useAuthUser();
  const isAuth = useAuth();

  return(
<div className="section">
{
        (AuthUser?.role === "Customer" ) ? (
         <></>
        ) : (
          <table>

  <tbody>

<tr>

     
    </tr>
    
   

    <tr>
      <td>product name</td>
      <td><Switch checked={ViewProductName} onChange={handleSwitchChange} /></td>
    </tr>
    <tr>
      <td>product zero</td>
      <td><Switch checked={ViewzeroProducts} onChange={handleZeroproductSwitchChange} /></td>
    </tr>
    <tr>
      <td>product labels</td>
      <td><Switch checked={rowlbl} onChange={handlelables} /></td>
    </tr>
    <tr>
      <td>Arch Images</td>
      <td><Switch checked={archimages} onChange={handleArchImage} /></td>
    </tr>
    <tr>
      <td>Arch Minimised</td>
      <td><Switch checked={archiminimised} onChange={handleArch_minmise} /></td>
    </tr>
    <tr>
     <td>
     <p>Price Controls Discount %</p>
     </td>
     <td>
     <DiscountControls />

     </td>
    </tr>
    <tr>
      <td>Download Price List</td>
      <td><Export_PriceList /></td>
    </tr>

  <tr>
    <td>Download Products Without Images</td>
    <td><Export_Products_without_Images /></td>
  </tr>
  <tr>
    <td>Download Products Without Images By Brand</td>
    <td><Export_Products_without_Images_byBrand /></td>
  </tr>


    <tr>
      <td>Arch Refresh</td>
      <td><Arch_Refresh/></td>
    </tr>
   

   
  </tbody>
</table>
        )
       }
               

              </div>
  )
}