import React from 'react';
import SelectedCustomer from './SelectedCustomer';
const ShippingDetails = ({ customer }) => {
  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-lg font-bold mb-4">Shipping Details</h2>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
        <p>{customer?.customerName}</p>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Address:</label>
        <p>{customer?.addressline1}</p>
        <p>{customer?.addressline2}</p>
        <p>{customer?.addressline3}</p>
        <p>{customer?.addressline4}</p>
      </div>
      
    </div>
  );
};

export default ShippingDetails;
