import { AuthUser } from "../models/auth-user";

export const addToLSOnLogin = (id:string,name: string, email: string, role: string, expiry: Date,issue_datetime:Date) => {
    localStorage.setItem('userid', id);
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    localStorage.setItem('role', role);
    localStorage.setItem('expiry', expiry.toUTCString());
    localStorage.setItem('issue_datetime', issue_datetime.toUTCString());
}

export const clearLSOnLogout = () => {
    localStorage.clear();
}

export const getAuthUserFromLS = () => {
    return new AuthUser(localStorage.getItem('userid'),localStorage.getItem('name'), localStorage.getItem('email'), localStorage.getItem('role'));
}

