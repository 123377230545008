import  { useState } from "react";

import customaxios from "../../../helpers/axios-helper";
import { useAuth, useAuthUser } from "../../../contexts/auth-context";
import AddToCartModal from "../../Cart/Add_to_Cart/AddToCartModal";
import { RootState } from "../../../Globals/GlobalInterface";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faEye } from "@fortawesome/free-solid-svg-icons";
import EditProductBrand from "../../../components/Product/Product_Brand_Edit";

import Purchase_Modal from "../../Product_Purchase/Navigation/Purchase_Modal";

export default function ProductRow({IsPurchase_list=undefined, IsPos=undefined, IsReturn=undefined,handleReturnItem ,  addtocart=undefined, product, index }) {

  const discountvalue = useSelector((state: RootState) => state.Discount);
  const [productData, setProductData] = useState(product);
  const isAuth = useAuth();
  const AuthUser = useAuthUser();
  const [flagStatus, setFlagStatus] = useState(Boolean(productData.flag));
  const [showModal, setShowModal] = useState(false);

  const handleAddToCartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };



  const handleFlagItem = () => {
    const updatedStatus = !flagStatus;
    setFlagStatus(updatedStatus);
    customaxios
      .post("/product/FlagPRoduct", {
        productId: productData.productId,
        Flag: updatedStatus,
      })
      .then((response) => {
        console.log(response.data);
      });
  };





  return (
    <>
      {showModal && <AddToCartModal IsPurchase_list={IsPurchase_list} Ispos={IsPos} item={productData} onClose={handleCloseModal} />}

      <div

        className={` product ${
          productData.productQuantity?.quantity <= 0 ? "border-gray-500  dark:bg-gray-700  hover:bg-gray-400" : ""
        } ${productData.unmoveditem ? "border-red-500  hover:bg-red-200 dark:hover:bg-red-400  "
         : "border-green-500 hover:bg-green-200  dark:hover:bg-green-800 "}  
         border-b-2 dark:bg-grey-500 dark:text-white `}
           key={index}
      >

        <div className="productname">
        <a href={`/Product/${productData.productId}`}>
          <p className="font-semibold text-black-900">{productData.itemdetails}</p>
          <p className="font-light text-gray-500 dark:text-gray-200 ">
            {productData.oaM_ACode}{" - "}
            {productData.suppliercode && productData.suppliercode.trim() !== "" ? productData.suppliercode.trim() : "Supplier Code"}

          </p>
        </a>
      </div>
      
        <div className="brand_container">
          <EditProductBrand product={product} />
        </div>

      
       {
        (AuthUser?.role === "Customer" ) ? (
         <></>
        ) : (
          <p className="quantity">
          {productData.productQuantity.quantity +
                      " " +
                      productData.productQuantity.quantity_Units}
            </p>
        )
       }
         
         {
        (AuthUser?.role === "Customer" ) ? (
         <></>
        ) : (
          <p className="Unit_Price">

          {discountvalue > 0 ? (
            <>
         {productData.productPrice.price}{" LKR"}   <br /> 
              {discountvalue}% Disc{" "}
              {(productData.productPrice.price * (1 - discountvalue / 100)).toFixed(2)} 
            </>
          ) : (
           <>
           {
           productData.productPrice.price && productData.productPrice.price.toLocaleString() 
        
          } LKR </>  )}
        </p>
        )
       }
         
       



         {
        (AuthUser?.role === "Customer" ) ? (
         <></>
        ) : (
         <>
         {addtocart && (
  <div className="addtocart">
    {IsPurchase_list ? (
      (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") ? (
        <Purchase_Modal id={productData.productArch.productArchId} />
      ) : (
        <></>
      )
    ) : (
      <button className="addtocartBtn" onClick={handleAddToCartClick}>
        <FontAwesomeIcon icon={faShoppingCart} />
      </button>
    )}
  
  {IsReturn && (
  <button 
    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
    onClick={() => handleReturnItem(productData)}
  >
    Return
  </button>
)}

  </div>
)}

         </>
        )
       }
          


      </div>
    </>
  );
}



