import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import numberToWords from 'number-to-words';

interface PrintObjectProps {
  children?: React.ReactNode;
}

const PrintComponent: React.FC<PrintObjectProps> = ({ children }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function handlePrintOptions() {
    setVisible(true);
    await new Promise((resolve) => setTimeout(resolve, 0.00002));
    handlePrint();
    setVisible(false);
  }

  return (
    <div>
      <button className="bg-teal-500 text-white px-4 py-2 rounded" onClick={handlePrintOptions}>Print</button>
      <div ref={componentRef} className='px-4 py-2'>
        {children}
      </div>
    </div>
  );
};

export default PrintComponent;
