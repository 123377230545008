import React, { useEffect, useState, useRef } from "react";
import customaxios from "../../../../helpers/axios-helper";
import { Link } from "react-router-dom";
import Loading_Defualt from "../../../Loading_Defualt";
import { useAuthUser,useAuth } from '../../../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';

interface Supplier {
  supplierId: string;
  supplier_Name: string;
  supplier_Short: string;
  total_Products: number;
}

export default function Suppliers_GridView() {
  const [suppliersList, setSuppliersList] = useState<Supplier[]>([]);
  const [currentLetter, setCurrentLetter] = useState<string>("");
  const letterRefs = useRef<{ [key: string]: HTMLElement }>({});
  const [loading, setLoading] = useState<boolean>(true); // Add loading state


  const AuthUser = useAuthUser();
  const isAuth = useAuth();

  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles =['Director','Developer','Praveen'] ;

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }
  function clearLocalStorage() {
    localStorage.removeItem("suppliersList");
    setSuppliersList([]);
    setCurrentLetter("");
  }
  useEffect(() => {
    const storedData = localStorage.getItem("suppliersList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setSuppliersList(data);
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("suppliersList");
      }
    }
  
    customaxios.get("supplier/getsupplier").then((response) => {
      setSuppliersList(response.data);  
      setLoading(false);
    const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
    // const data = { data: response.data, expiry: Date.now() + 30 * 1000 };  
   localStorage.setItem("suppliersList", JSON.stringify(data));
    });
  }, []);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let closestLetter: string | null = null;
        let minDistance = Number.MAX_VALUE;
        entries.forEach((entry) => {
          const letter = entry.target.getAttribute("data-letter");
          if (entry.isIntersecting) {
            const distance = Math.abs(entry.boundingClientRect.top);
            if (distance < minDistance) {
              closestLetter = letter;
              minDistance = distance;
            }
          }
        });
        if (closestLetter !== null) {
          setCurrentLetter(closestLetter);
        }
      },
      { threshold: 1 }
    );

    Object.values(letterRefs.current).forEach((ref) => {
      observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, [currentLetter]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  suppliersList.sort((a, b) =>
  (a.supplier_Name ?? "").localeCompare(b.supplier_Name ?? "")
);

  const filteredSuppliers = suppliersList.filter((supplier) =>
  (supplier.supplier_Name ?? "").toLowerCase().includes(searchTerm.toLowerCase())
);

  const groupedSuppliers = filteredSuppliers.reduce((groups, supplier) => {
    const firstLetter = (supplier.supplier_Name ?? "").charAt(0).toUpperCase();

    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(supplier);
    return groups;
  }, {});
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  
if (loading) { // Display loading while fetching data
  return <Loading_Defualt/>;
}
  return (
    
    <>
{
  AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" ? (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search suppliers"
      />
    <button onClick={clearLocalStorage}>Force_Re Cache</button>
        {Object.entries(groupedSuppliers).map(
          ([letter, suppliers]: [string, Supplier[]]) => (
            <div className="CategoryPage" key={letter}>
              <h3
                className={`Letter ${
                  currentLetter === letter ? "active" : ""
                }`}
                ref={(el) => {
                  letterRefs.current[letter] = el!;
                }}
                data-letter={letter}
              >
                {letter}
              </h3>
              <div className="BrandCard_wrapper">
                {suppliers.map((supplier) => (
                  <Link
                    state={supplier}
                    to={`/Supplier/${supplier.supplierId}`}
                    key={supplier.supplierId}
                  >
                    <div className="BrandCard">
                      <p className="ItemName">
                        {supplier.supplier_Name} <br />{" "}
                        {`(${supplier.supplier_Short})`}
                      </p>
                      <p className="ProductCount">
                        {"Products: " + supplier.supplier_Short}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )
        )}
      </div>
  ) : (
    <>no auth</>
  )
}

 
    
    </>
  );
}
