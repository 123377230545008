import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";
import customAxios from "../../helpers/axios-helper";

export default function Towns() {
  const [towns, setTowns] = useState([]);
  const [selectedTown, setSelectedTown] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Fetch all towns
    customAxios.get("/CustomerRoute/All_Town").then((response) => {
      setTowns(response.data);
      console.log(towns)
    });
  }, []);

  const handleTownClick = (town) => {
    setSelectedTown(town);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedTown(null);
    setModalOpen(false);
  };

  return (
    <div>
        <table className="Town_Table">
          <thead>
            <tr className="Town_Wrapper Title"> 
              <th className="Town_Id">ID</th>
              <th className="Town_Name">Town</th>
           
            </tr>
          </thead>
          <tbody>
            {towns.map((town) => (
              <tr className="Town_Wrapper"
                key={town.townId}
             //   onClick={() => handleTownClick(town)}
                style={{ cursor: "pointer" }}
              >
                <td className="Town_Id">{town.townId}</td>
                <td className="Town_Name">
                <a href={`/Town/${town.townId}`}>
                {town.name}
                </a>
              
                </td>
           
              </tr>
            ))}
          </tbody>
        </table>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div>
          {selectedTown && (
            <div>
              <h3>Customer List for Town: {selectedTown.name}</h3>
              {/* Render customer list here */}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
