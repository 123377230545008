import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../../helpers/axios-helper";
import { useAuthUser,useAuth } from '../../../contexts/auth-context'; 
import Purchase_Modal from "../Navigation/Purchase_Modal";
import { useNavigate } from 'react-router-dom';
import PrintComponent from "../../PrintComponent/PrintComponent";
interface RouteParams {
    id: string;
    [key: string]: string;
  }
export default function Purchase_List_Page({ id: routeId }: { id: string | null }){
    const { id: paramId } = useParams<RouteParams>();
    const id = paramId ? paramId : routeId;
    const [PurchaseLists, setPurchaseLists] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const isAuth = useAuth();

    const AuthUser = useAuthUser();
    const navigate = useNavigate();
    const allowedRoles = ['Director','Developer','Praveen'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }

    useEffect(() => {
        customaxios
        .get("/Purchase/Purchase_Order_List_ById/?id="+id, )
          .then((response) => {
            setPurchaseLists(response.data);
  
            setIsLoading(false);
    
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Handle validation errors
              const validationErrors = error.response.data;
              console.log("Validation errors:", validationErrors);
              // Update state or show error messages to the user
            } else {
              console.log("Error:", error);
              // Handle other types of errors
            }
          });
      }, []);
   
    return(<>
    <PrintComponent>
 
        <table  className="w-3/4 mx-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
 <tr >
  <th>product</th>
  <th>USD Rate</th>
  <th>LKR Rate</th>

  <th>QTY</th>
  <th>Received QTY</th>
 </tr>
 </thead>

 <tbody>
 {
        PurchaseLists?.
        purchase_Orders
        ?.map((purchase_Order => {
            return(

         <Purchase_Order purchase_Order={purchase_Order}/>
            )
        }))
        
    }

 </tbody>
   </table>
   </PrintComponent>

        </>)
}


export function Purchase_Order({purchase_Order}){
    const [Purchase_Order, setPurchase_Order] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    
    const handleSave = (fieldName: string, value: number, index: number) => {
      const updatedItems = [...Purchase_Order.purchase_Order_Items];
      updatedItems[index][fieldName] = value;
      setPurchase_Order({
        ...Purchase_Order,
        purchase_Order_Items: updatedItems
      });
      // Here you can make your API call using Axios to update the item
      // Example:
      // axios.post('/api/updateItem', updatedItem);

      // Construct the data payload for the POST request
    const payload = {
      purchase_Order_Id: Purchase_Order.purchase_OrderId,
      updated_Item: updatedItems[index]
    };

    // Make the POST request using Axios
    customaxios.post("Purchase/UpdatePurchaseOrderItems ", updatedItems[index])
      .then((response) => {
        console.log('Item updated successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error updating item:', error);
        // Handle error scenarios
      });


    };
    useEffect(() => {
        customaxios
        .get("/Purchase/GetPurchase_Order_ById/?id="+purchase_Order.purchase_OrderId, )
          .then((response) => {
            setPurchase_Order(response.data);
          //  console.log("Sales Rep list");
            console.log("Pourchase data", response.data);
            setIsLoading(false);
    
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Handle validation errors
              const validationErrors = error.response.data;
              console.log("Validation errors:", validationErrors);
              // Update state or show error messages to the user
            } else {
              console.log("Error:", error);
              // Handle other types of errors
            }
          });
      }, []);


    return(
        <>
<br />
<tr className="border border-red-600 bg-red-100">
<td>
            {Purchase_Order.supplier?.supplier_Short} - {Purchase_Order.purchase_OrderId}
        </td>
<td> </td>
<td></td>
<td></td>
<td></td>
<td></td>
</tr>

 {
    Purchase_Order.purchase_Order_Items?.map(((item,index) => {
        
        return(
        <tr
        
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          
<td>
  
<p className="font-semibold text-black-900">{item.product.itemdetails}</p>
          <p className="font-light text-gray-500 dark:text-gray-200 ">
            {item.product.oaM_ACode}{" - "}
            {item.product.suppliercode && item.product.suppliercode.trim() !== "" ? item.product.suppliercode.trim() : "Supplier Code"}

          </p>


</td>
<EditableCell value={item?.unitPrice_USD} onSave={(newValue) => handleSave('unitPrice_USD', newValue, index)} />
      {/* Pass index to handleSave function */}
      <EditableCell value={item?.unitPrice_LKR} onSave={(newValue) => handleSave('unitPrice_LKR', newValue, index)} />
      {/* Pass index to handleSave function */}
      <td>
    <p>{item.quantity}</p>
</td>
      <EditableCell value={item?.received_Quantity} onSave={(newValue) => handleSave('received_Quantity', newValue, index)} />
      {/* Pass index to handleSave function */}

<td>

<Purchase_Modal id={item?.product?.productArch.productArchId} />
</td>

        </tr>
        )}))
        }

       
        </>
    )
}


const EditableCell: React.FC<{ value: number | undefined; onSave: (newValue: number) => void }> = ({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value || 0);

  const handleSave = () => {
    onSave(newValue);
    setIsEditing(false);
  };

  return (
    <td>
      {isEditing ? (
        <input
          type="number"
          value={newValue}
          onChange={(e) => setNewValue(parseFloat(e.target.value))}
          onBlur={handleSave}
          autoFocus
          className="border border-gray-300 rounded px-2 py-1"
        />
      ) : (
        <div onClick={() => setIsEditing(true)}>{value || 0}</div>
      )}
    </td>
  );
};