
import React, { useState, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import { useAuthUser } from "../../contexts/auth-context";
import ProductInfo from "./ProductInfo";
import {Modal, Box} from "@mui/material";

export default function Product_History({ productId }) {
  const AuthUser = useAuthUser();
  const inputRef = useRef(null);
  const [productHistory, setProductHistory] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchProductHistory = () => {
    customaxios
      .get("/product/getproducthistory/" + productId)
      .then((response) => {
        setProductHistory(response.data);
        console.log("set false");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewHistory = () => {
    const allowedRoles = ['Developer', 'CEO', 'Director','Praveen']; // Specify the roles allowed to view history
    if (allowedRoles.includes(AuthUser.role)) {
      setIsModalOpen(true);
      fetchProductHistory();
    } else {
      toast.error("You don't have permission to view history");
    }
  };
  
  return (
    <>
     <button onClick={handleViewHistory} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
  View History
</button>
      <Modal 
      onClose={() => setIsModalOpen(false)}

open={isModalOpen}>
  <Box sx={{ position:
     "absolute",
      overflow: "auto",
       top: "50%", 
       left: "50%",
        transform: "translate(-50%, -50%)", 
        width: "80vw", height: "80vh", maxHeight: "900px", bgcolor: "background.paper", boxShadow: 24, p: 4 }}>
{productHistory && (
        <div className="flex flex-no-wrap overflow-x-auto">
          {productHistory.map((historyItem, index) => (
            <div key={index} className="bg-gray-100 rounded-lg p-4 m-2 w-64 flex-shrink-0">
              <div className="font-semibold text-gray-700">Period:</div>
              <div className="text-sm text-gray-500 min-w-max truncate">{new Date(historyItem.periodStart).toLocaleDateString('en-US')} - {new Date(historyItem.periodEnd).toLocaleDateString('en-US')}</div>
              <div className="text-sm text-gray-500 opacity-0 transition-opacity duration-300 absolute top-0 left-0 bg-gray-800 text-white p-1 rounded-md text-xs truncate">
                {new Date(historyItem.periodStart).toLocaleTimeString('en-US')}
              </div>
              <div className="font-semibold text-gray-700 mt-2">Product Name:</div>
              <div className="text-sm text-gray-500 truncate">{historyItem.productName}</div>
              <div className="font-semibold text-gray-700 mt-2">Item Details:</div>
              <div className="text-sm text-gray-500 truncate">{historyItem.itemdetails}</div>
              <div className="font-semibold text-gray-700 mt-2">Supplier Code:</div>
              <div className="text-sm text-gray-500 truncate">{historyItem.suppliercode}</div>
              <div className="font-semibold text-gray-700 mt-2">OAM Code:</div>
              <div className="text-sm text-gray-500 truncate">{historyItem.oaM_ACode}</div>
            </div>
          ))}
        </div>
      )}
  </Box>

</Modal>
    

    </>
  );
}
