import  { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import customaxios from "../helpers/axios-helper";

import Search from "../components/Product/Search/Search";
const ProductArch = lazy(() => import("../components/Product/Arch/ProductArch"));

interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function ProductListByCategory() {
  const { id } = useParams<RouteParams>();

  const [category, setCategory] = useState<any>({});
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    // Fetch category information
    customaxios
      .post("/Category/GetCategory", {
        Categoryid: id,
        categoryname: "",
      })
      .then((response) => {
        const { group_Name, itemcount, archCount } = response.data;
        setCategory({ group_Name, itemcount, archCount });
        document.title = group_Name;
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch product list
    customaxios
      .post("/product/getproductsbyproductarch", {
        Categoryid: id,
        categoryname: "",
      })
      .then((response) => {
        /*const sortedProductList = response.data.sort(
          (a, b) =>
            b[0].productArch.total_Product_Qty -
            a[0].productArch.total_Product_Qty
        );*/
        setProductList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Clean up function to reset the title if needed
    return () => {
      document.title = "Overseas Automotive"; // Change this line to reset the title to your preferred default value.
    };
  }, [id]);

 /* const filteredProductList = _globalstate.zeroqty
  ? productList
  : productList.filter((row) => row[0].productArch.total_Product_Qty > 0);
const productListWithEmptyQty = filteredProductList.filter(
  (row) => row[0].productArch.total_Product_Qty <= 0
);
*/
  /*const productListWithEmptyQty = filteredProductList.filter(
    (row) => row[0].productArch.total_Product_Qty <= 0
  );
*/
  /*const productListToShow = filteredProductList.filter(
    (item, index, self) =>
      self.findIndex((i) => i[0].productId === item[0].productId) === index
  );
  */
    return (
      <div className="groupProductview">
    <Search />
    <div className="CategoryInformation">
      <p className="Name">{category.group_Name}</p>
      <p className="itemcount">Total Products: {category.itemcount}</p>
      <p>{"Arch Count :" + category.archCount}</p>
    </div>
    <br />
        <br />
        <br />
        <br />
    <div className="ProductScroll">
      <Suspense fallback={<div>Loading...</div>}>
        {productList.map((row, index) => (
            <ProductArch key={row.productArch.productArchId} products={row.products} arch={row.productArch}/>
        ))}

      </Suspense>
     
      <div className="end" style={{ textAlign: 'center' }}>
  <h4>~~~~~ END OF PRODUCTS ~~~~~</h4>
  <h4>༼ つ ◕_◕ ༽つ</h4>
</div>
    </div>
  </div>
    );
          
  
  };