import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ProductRow from '../../Product/Arch/productrow';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentModeSelector from './Payment_Mode_Selector/PaymentModeSelector';
import "./Style/Style.css"
import { useCart } from '../CartProvider';
import { usePos } from '../../POS/PosProvider';
import DiscountInput from './DiscountInput';
import QuantityInput from './QuantityInput';
import NettPriceInput from './NettPrice';
import Purchase_Price_Comp from './Purchase_Price';
import { usePurchase_Prov } from '../../Product_Purchase/Product_Purchase_Provider';
function AddToCartModal({ item, onClose,Ispos=undefined , IsPurchase_list=undefined}) {
  const [qty, setQty] = useState(1);
  console.log(item)
  const [price, setPrice] = useState(item.productPrice.price);
  const [discount, setDiscount] = useState(10);
  const [NettPrice, setNettPrice] = useState(0);
  const [Purchase_Price, setPurchase_Price] = useState(0);
  const [paymentMode, setPaymentMode] = useState('credit');
  const [creditType, setCreditType] = useState('30');
  const [note, setNote] = useState('');
  const [Total, setTotal] = useState(0);
 // const [Total, setTotal] = useState(qty * price * (1 - (discount / 100)));
const Pos_Mode_local = Boolean(Ispos);
const Purchase_Mode_local = Boolean(IsPurchase_list);
useEffect(() => {
  // Place your side effect code here
  // This code will run whenever Pos_Mode_local changes

  // For example, you can log the new value of Pos_Mode_local
 if(Pos_Mode_local){
  setPaymentMode('cash')
 }
 else{
  setPaymentMode('credit')
 }

}, [Pos_Mode_local]);
 const { addToCart, cartItems } = useCart();
 const { addToPos,PosItems } = usePos();
 const { addToPurchase, PurchaseItems } = usePurchase_Prov();
  useEffect(() => {
    if (NettPrice > 0) {
      setTotal(qty * NettPrice * (1 - (discount / 100)));
    } else {
      setTotal(qty * price * (1 - (discount / 100)));
    }
  }, [qty, NettPrice, price, discount]);

  const [showNotes, setShowNotes] = useState(false);
  useEffect(() => {
    if (NettPrice > 0) {
      setDiscount(0);
    }
  }, [NettPrice]);

  useEffect(() => {
    if (discount > 0) {
      setNettPrice(0);
    }
  }, [discount]);

  const handleAddToCart = () => {
    if (paymentMode === '') {
      toast.error('Please select a payment mode.');
      return;
    }

    if (paymentMode === 'credit' && creditType === '') {
      toast.error('Please select a credit type.');
      return;
    }
const commonProps = {
  OrdeOrderrId: null,
  id: Ispos ? PosItems.length + 1 : (IsPurchase_list ? PurchaseItems.length + 1 : cartItems.length + 1),
  name: item.productName,
  UnitPrice: price,
  Total_Amount: Total,
  Discount_Percentage: discount,
  Quantity: qty,
  Product: item,
  Note: note || null,
  PaymentMode: paymentMode,
  CreditPeriod: paymentMode === "cash" ? '0' : creditType,
  Nett_Price: NettPrice
};

if (Ispos) {
  addToPos(commonProps);
  console.log(Ispos);
  console.log(PosItems);
} else if (IsPurchase_list) {
  addToPurchase(commonProps);
  console.log(IsPurchase_list);
} else {
  addToCart(commonProps);
}

    onClose!();
    setQty(1);
    setPrice(item.productPrice.price);
    setDiscount(0);
    setPaymentMode('');
    setCreditType('');
    setNote('');
    setTotal(0);
  };

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const viewname = true;
  const index = true;

  return (
    <div className='Modal_parent' >
      <div className="modal-overlay" onClick={handleOverlayClick} >
        <div className="modal-content">
          <p className='Cart_title '>{Pos_Mode_local? (<>POS MODE</>): Purchase_Mode_local ? (<>Purchase Item</>): (<>Add to cart </>)}</p>
          <div className="Productincartmodal">
            <ProductRow 
                          handleReturnItem={()=>{}}
            
            addtocart={false} product={item} index={index} />
          </div>

          <QuantityInput value={qty} onChange={setQty} />

      
          {Purchase_Mode_local?(<></>):(    <DiscountInput value={discount} onChange={setDiscount} />)}
            {Pos_Mode_local ? (<>Cash ONLY FOR POS</>):( <PaymentModeSelector
            paymentMode={paymentMode}
            creditType={creditType}
            setPaymentMode={setPaymentMode}
            setCreditType={setCreditType}
          />)}

         

          {Purchase_Mode_local? (
            <Purchase_Price_Comp value={Purchase_Price} onChange={setPurchase_Price}/>
          ):(
            <NettPriceInput value={NettPrice} onChange={setNettPrice} />
          )}


          <div className="Total">
            <p>Total: {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Total)}</p>
          </div>

          <div className="note">
       
       <input placeholder='Notes (optional)' value={note} onChange={(e) => setNote(e.target.value)} />
    </div>

          <div className="addtocart">
          <button className={`button_one ${Purchase_Mode_local ? "danger" : ""}`} onClick={handleAddToCart}>{Purchase_Mode_local ? "Add To Purchase List" : "Add to cart"}</button>
   </div>

       
        </div>
      </div>
    </div>
  );
}

AddToCartModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    unitPrice: PropTypes.number.isRequired,
    imageUrl: PropTypes.string
  }).isRequired
};

export default AddToCartModal;
