import React, { useEffect, useState } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Default from "../../../../components/Loading_Defualt";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the Favorite icon from MUI
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAuth,useAuthUser } from '../../../../contexts/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Category {
    product_groupId: any;
    group_Name: string;
    itemcount: number;
    archCount: number;
    image_url: string;
    favourite:boolean;
  }
  interface Catgory_select_Props {
    isOpen: boolean;
    closeModal: () => void;
  
    selectedCollection_Id: string | null;
    setselectedCollection_Name: (category: string | null) => void;
    setselectedCollection_Type: (category: string | null) => void;
    setselectedCollection_Id: (category: string | null) => void;
  }
export default function Category_Selector(props:Catgory_select_Props) {
  const auth = useAuthUser();
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
    const [categoriesList, setCategoriesList] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(props.selectedCollection_Id);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const filteredCategories = categoriesList.filter((Category) => Category.group_Name.toLowerCase().includes(searchTerm.toLowerCase()));
   
      useEffect(() => {
     /*   const storedData = localStorage.getItem("categoriesList");
        if (storedData) {
          const { data, expiry } = JSON.parse(storedData);
          if (Date.now() < expiry) {
            setCategoriesList(data);
            if (categoriesList.length > 0) {
              setSelectedCategory(categoriesList[0].product_groupId);
            }
            setLoading(false);
            return;
          } else {
            localStorage.removeItem("categoriesList");
          }
        }
        */
        customaxios.get("product/getproductgroups").then((response) => {
          setCategoriesList(response.data);
         
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("categoriesList", JSON.stringify(data));
        });
      }, []);

      
  
  const handle_select = (Category)=>{
    setSelectedCategory(Category.product_groupId)
    props.setselectedCollection_Name(Category.group_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_paged")
    props.setselectedCollection_Id(Category.product_groupId)
    console.log('selected ', Category)
  }
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
      };
      const clearLocalStorage = () => {
        localStorage.removeItem("categoriesList");
        setCategoriesList([]);
        setLoading(true);
        fetchData();
      };
      const fetchData = () => {
        customaxios.get("product/getproductgroups").then((response) => {
          setCategoriesList(response.data);
          
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("categoriesList", JSON.stringify(data));
        });
      };

      const set_favourite_Category = (Category: Category) => {
        if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
        // Handle the double click action here
        console.log('Double click on ', Category.product_groupId);
      
        customaxios
          .post("Category/Category_Favourite?BrandId="+ Category.product_groupId 
          )
          .then((response) => {
            console.log(response);
            fetchData();
          })
          .catch((error) => {
            console.error("Error during double click action:", error);
          });
        }
      };
  
  
    return(
    <>
          <div className="CategoriesList">

        <div className="controls">
          <input
            className="Search_bar"
            type="text"
            placeholder="Search Category..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button className="refresh" onClick={clearLocalStorage}>
            <FontAwesomeIcon icon={faSyncAlt} />{" "}
          </button>
          <a target="_blank" href={`${window.location.origin}/C`}>
      
      <div className="Categorylist_item">
        <div className="CategoryName"><p>&#9734; Open In New Tab</p></div>
      
        
      </div>
         </a>  
        </div>
        {loading ? (
          <Loading_Default />
        ) : (
        
          <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
   
   <tr>
            <th className="px-6 py-3">Logo</th>
            <th className="px-6 py-3">Name</th>
            <th className="px-6 py-3">No of Items</th>
            <th className="px-6 py-3">No of Archs</th>
            <th className="px-6 py-3">Fav</th>
   </tr>
          </thead>
        
        
        <tbody>
        

         
            {filteredCategories.map((Category) => (
            <tr           key={Category.product_groupId} className="bg-white border-b dark:bg-gray-800 dark:border-blue-500 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td>  {Category.image_url && (
                 
                    <img src={Category.image_url} style={{ maxWidth: 50 }}  alt="" />
                )} </td>

                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select(Category); props.closeModal();  }}>
                {Category.group_Name}
                </td>
                <td>
                {Category.itemcount}
                </td>
                <td>
                {Category.archCount}
                </td>
                <td>
                {Category.favourite === true ? (<IconButton
            onClick={() => set_favourite_Category(Category)}
            color="secondary" // Change color as needed
          >
            <FavoriteIcon />
          </IconButton>):(<IconButton onClick={() => set_favourite_Category(Category)}>
            <FavoriteBorderIcon />
          </IconButton>)}
                </td>
            </tr>
               
          
            ))}





        </tbody>
        </table>
        
        )}
      </div>
        </>
        )
}