// Tooltip.tsx

import React from 'react';

interface TooltipProps {
  content: string;
}

const Tooltip: React.FC<TooltipProps> = ({ content }) => {
  return (
    <div className="tooltip p-2 bg-gray-800 text-white rounded-md absolute z-50">
      {content}
    </div>
  );
};

export default Tooltip;
