// Your main component
import React, { useState } from "react";
import { Tabs, Tab, Paper } from "@mui/material";
import Routes from "./Routes"; // Make sure to provide the correct path
import Towns from "./Towns"; // Make sure to provide the correct path
import { useAuthUser,useAuth } from '../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';

export default function Customer_Routes() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const isAuth = useAuth();

  const AuthUser = useAuthUser();
  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles = ['Director','Developer','Praveen','Billing_Agent'] ;

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  return (
    <div>
      <Paper square>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab label="Routes" />
          <Tab label="Towns" />
        </Tabs>
      </Paper>
      <div>
        {activeTab === 0 && <Routes />}
        {activeTab === 1 && <Towns />}
      </div>
    </div>
  );
}
