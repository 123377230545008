import React, { useState } from "react";
import CategorySelect from "./SelectCategory";
import PaymentModeSelect from './PaymentModeSelect';
import SelectBranch  from '../Branches/Select_Branch';
import SelectPaye from '../Payee/Select_Payee';
import TextField from '@mui/material/TextField';

export default function VoucherInfo({ payee, onPayeeChange, onPaymentModeSelected, onCategorySelect, onChequeNumberEntered, onBranchSelected, onInvoiceDateTimeSelect, onInvoiceNumberEntered }) {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDateTime, setInvoiceDateTime] = useState('');

  const handleInvoiceNumberChange = (event) => {
    setInvoiceNumber(event.target.value);
    onInvoiceNumberEntered(event.target.value);
  };

  const handleInvoiceDateTimeChange = (event) => {
    setInvoiceDateTime(event.target.value);
    onInvoiceDateTimeSelect(event.target.value);
  };

  return (
    <div className="voucherinfo">
      <CategorySelect onCategorySelected={onCategorySelect} />
      <PaymentModeSelect onPaymentModeSelected={onPaymentModeSelected} onChequeNumberEntered={onChequeNumberEntered} />
      <SelectBranch onBranchSelected={onBranchSelected} />
      <SelectPaye onPayeSelected={onPayeeChange} />
     
      <TextField
placeholder="Invoice Number"
value={invoiceNumber}
  onChange={handleInvoiceNumberChange}
  sx={{ mr: 2, width: '200px' }}
/>


<TextField
placeholder="Invoice Date Time"
  type="datetime-local"
  value={invoiceDateTime}
  onChange={handleInvoiceDateTimeChange}
  sx={{ width: '250px' }}
/>

    </div>
  );
}
