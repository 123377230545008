import customaxios from "../../helpers/axios-helper" 
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material";
import Account from "../Account/Account";
import PrintComponent from "../../components/PrintComponent/PrintComponent";

export default function Sales_List() {
    const [Sales, setSales] = useState<any[]>([]);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [selectedTab, setSelectedTab] = useState<string | null>('all');
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
  
       // Group Sales by company_Name
       const groupedSales: { [key: string]: any[] } = {};
       Sales.forEach((expense) => {
         const company = expense.company.name
         || "N/A"; // Use "N/A" for null values
         if (!groupedSales[company]) {
           groupedSales[company] = [];
         }
         groupedSales[company].push(expense);
       });
    useEffect(() => {
      fetchSales();
    }, []);
  
    const fetchSales = async () => {
      try {
        const response = await customaxios.get("CompanySales/GetAll");
        setSales(response.data);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching Sales:", error);
        // Optionally, you can handle errors here, e.g., showing an error message to the user.
      }
    };
    const handleNameClick = (asset) => {
        setSelectedAsset(asset);
        console.log(asset)
      };

       // Calculate total sales
  const totalSales = Sales.reduce(
    (total, expense) => total + (expense.account_.balance || 0),
    0
  );
    return (
      <>
                <Account open={selectedAsset !== null} onClose={() => setSelectedAsset(null)} id={selectedAsset?.account_.account_Id
}/>
<PrintComponent>
            <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        centered
      >
          <Tab key="all" label="All" value="all" />
        {Object.keys(groupedSales).map((companyName) => (
          <Tab key={companyName} label={companyName} value={companyName} />
        ))}
      </Tabs>

 {/* Render Sales for the selected tab */}
 <div className="totalsales">
        <h3>Total Sales: {totalSales.toLocaleString("en-US", { style: "currency", currency: "LKR" })}</h3>
      </div>
 <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr >
            <th>Name</th>
            <th>Description</th>
            <th>company Name</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {selectedTab === "all"
            ? Sales.map((expense) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>  
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"  onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.description}</td>
                  <td>{expense.company.name
 || "N/A"}</td>
                  <td>{expense.account_.balance.toLocaleString("en-US", { style: "currency", currency: "LKR" }) || "N/A"}</td>
                </tr>
              ))
            : groupedSales[selectedTab]?.map((expense) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={expense.general_ExpenseId}>  
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"  onClick={() => handleNameClick(expense)}>{expense.name}</td>
                  <td>{expense.description}</td>
                  <td>{expense.company.name
 || "N/A"}</td>
                  <td>{expense.account_.balance.toLocaleString("en-US", { style: "currency", currency: "LKR" }) || "N/A"}</td>
                </tr>
              ))}
        </tbody>
      </table>
      </PrintComponent>
      </>
    );
  }
  