import customaxios from "../../../helpers/axios-helper";
import React, { useState, useEffect } from "react";
import { useAuthUser } from "../../../contexts/auth-context";
import ProductArch from '../Arch/ProductArch';
import ProductRow from "../Arch/productrow";
import Loading_Default from "../../Loading_Defualt";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PendingProducts() {
  const [groupedResults, setGroupedResults] = useState<{ [date: string]: any[] }>({});
  const [isLoading, setIsLoading] = useState(false);
  const AuthUser = useAuthUser();

  useEffect(() => {
    async function fetchPendingItems() {
      setIsLoading(true);
      try {
        const response = await customaxios.post('/product/Get_Pending_Items');
        // Group results by date only (ignore time)
        const grouped = response.data.reduce((acc, result) => {
          const createdDate = new Date(result.created_Date_time); // Convert the date string to a Date object
          createdDate.setHours(0, 0, 0, 0); // Set the time to 00:00:00.000 to ignore time components
          const dateOnly = createdDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

          if (!acc[dateOnly]) {
            acc[dateOnly] = [];
          }
          acc[dateOnly].push(result);
          return acc;
        }, {});
        setGroupedResults(grouped);
        console.log(grouped);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPendingItems();
  }, []); // Empty dependency array ensures this runs only once when the component mounts
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
    {isLoading ? (
      <Loading_Default />
    ) : (
      Object.entries(groupedResults).map(([dateOnly, resultsInGroup]) => (
        <Accordion key={dateOnly} expanded={expanded === dateOnly} onChange={handleChange(dateOnly)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${dateOnly}-content`} id={`panel-${dateOnly}-header`}>
            <h3>{dateOnly}</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {resultsInGroup.map((result, index) => (
                <ProductRow 
                handleReturnItem={()=>{}}
                IsPurchase_list={false} IsPos={false} addtocart={true} product={result} index={index} key={result.id} />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))
    )}
    <p>Pending Products</p>
  </>
  );
}
