import {useSelector} from "react-redux";
import { RootState } from "../../../Globals/GlobalInterface";
import Product_Arch_menu from "../../Product/Product_Arch_menu";
import "./Style/Style.css"
export default function Product_Arch({products,arch}:any) {
  const _globalstate = useSelector((state:RootState) => state);
  const filteredProducts = products.sort((a, b) => b.productQuantity.quantity - a.productQuantity.quantity);
  const filteredlockeditems = filteredProducts.sort((a, b) =>a.unmoveditem - b.unmoveditem);



let stockcount = 0;

filteredlockeditems.map((product, index) => {
  stockcount = stockcount + Number(product.productQuantity.quantity);
});

return (
  !_globalstate.zeroqty ? (
    <>
 {stockcount > 0 && (

      

      <Product_Arch_menu products={products} arch={arch} />
        
 )}
    </>
  ) : (
<>
<Product_Arch_menu products={products} arch={arch}/>
</>
    

  )
);


      
}


