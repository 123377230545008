import React, { useRef,useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { GetApp} from '@mui/icons-material';
import QRCode from 'qrcode.react';

import "./Export_Style/Style.css"
import Logo from '../../assets/images/Artboard 1.png';

interface PrintObjectProps {
  Data: any;
}


const Print_Export: React.FC<PrintObjectProps> = ({ Data }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [visible , setVisible] = useState(false);
  const [ShowQty , setShowQty] = useState(true);
  const [ShowQR , setShowQR] = useState(false);
  const [ShowPrice , setShowPrice] = useState(true);
  const [ShowItemsWithoutStock , setShowItemsWithoutStock] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handlePrint =
    useReactToPrint(
      {
      content: () => componentRef.current,
    });
    function handleToggleShowPrice() {
      setShowPrice((prevShowPrice) => !prevShowPrice);
    }
    function handleToggleShowQR() {
      setShowQR((prevShowQR) => !prevShowQR);
    }
    function handleToggleShowQty() {
      setShowQty((prevShowQty) => !prevShowQty);
    }
    function handleToggleShowItemsWithoutStock() {
      setShowItemsWithoutStock((prevShowItemsWithoutStock) => !prevShowItemsWithoutStock);
    }
  async function handlePrint_options() {
    setVisible(true);
    await new Promise((resolve) => setTimeout(resolve,.00005));
    handlePrint();
   console.log(Data) 
   setVisible(false);
  }

  // Define a Modal component
const Modal = ({ show, onClose, onToggleShowQty,onToggleShowItemsWithoutStock,onToggleShowQR, onhandleToggleShowPrice }) => {
  if (!show) return null;
  return (
    <div className="modal_brand">
      <div className="modal_brand-content">
      <h2>Config</h2>
        <label>
          <input type="checkbox" checked={ShowPrice} onChange={onhandleToggleShowPrice} />
          Show Price
        </label>
<br />
        <label>
          <input type="checkbox" checked={ShowQR} onChange={onToggleShowQR} />
          Show QR
        </label>
<br />

        <label>
          <input type="checkbox" checked={ShowQty} onChange={onToggleShowQty} />
          Show Quantity
        </label>
        <br />
        <label>
          <input type="checkbox" checked={ShowItemsWithoutStock} onChange={onToggleShowItemsWithoutStock} />
          Show 0 Stock items
        </label>
<br />
        <button  className='button_one '  onClick={handlePrint_options}>Get PDF<GetApp/> </button>
      <br />
        <button className='button_one danger' onClick={onClose}>Close</button>
       
      </div>
    </div>
  );
};
/**/
  return (
    <div>
     <p  onClick={() => setIsModalOpen(true)}> <GetApp /></p>
       <Modal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onToggleShowQty={handleToggleShowQty}
        onToggleShowItemsWithoutStock={handleToggleShowItemsWithoutStock}
        onToggleShowQR={handleToggleShowQR}
        onhandleToggleShowPrice={handleToggleShowPrice}
      />
       <div ref={componentRef}>
<div  className={`Prodcut_Catelog_Export_Pdf_wrapper 
${
  visible ? 'visible' : 'hidden'
}

`}>
<img className="Watermark" src={Logo} alt="" />

<table  className="Prodcut_Catelog_Export_Pdf">
              <thead>
                <tr className='Main_Productrow Title'>
                <th>Image</th>
                  <th>Product</th>
                </tr>
              </thead>

              <tbody>
              {Data?.length > 0 ? (
  Data.map((row) => {
    // Check if all products have quantity > 0
    const allProductsHaveQuantityGreaterThanZero = row.products?.reduce((sum, product) => sum + product.productQuantity.quantity, 0) > 0 ? true : false;


    if (!ShowItemsWithoutStock && !allProductsHaveQuantityGreaterThanZero) {
      // If not all products meet the condition, skip rendering this row
      return (  <></>);

    }

    return (
      <tr className='Main_Productrow' key={row.productArch.productName}>
        <td className='Product_image'>
          <p><strong>{row.productArch.productName}</strong></p>
            {row.productArch.archImages?.length > 0 ? (
           <>
              <img
                key={row.productArch.archImages[0].url}
                src={row.productArch.archImages[0].url}
                alt={row.productArch.archImages[0].productName}
                style={{ maxWidth: '100px',maxHeight:'100px' }}
              />
        
          {ShowQR ? (    <QRCode value={`${window.location.origin}/ProductImage/${row.products[0].productId}`} size={75} />):(<></>)}
           </>
            ) : (
              'No Image'
            )}
          <br />

{row.productArch.archImages?.length > 1 ? (
  <a href={`${window.location.origin}/ProductImage/${row.products[0].productId}`}>&#9734; more images</a>
) : (
  <></>
)}


          {/*row.productArch.archImages?.length > 0 ? (
            row.productArch.archImages.map((image) => (
              <img
                key={image.url}
                src={image.url}
                alt={image.productName}
                style={{ maxWidth: '100px' }}
              />
            ))
          ) : (
            'No images available'
          )*/}
        </td>
     
<tbody className='Product_info'>
  <tr className='data_row'>
    <td>Supplier Code</td>
    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
    <td>ACode</td>
    <td>Brand</td>
    {ShowQty && <td>QTY</td>}
    {ShowPrice &&    <td>Price</td>}


  </tr>
  {row.products?.length > 0 ? (
    row.products.map((product, index) => {
      const showProduct = ShowItemsWithoutStock || product.productQuantity.quantity > 0;

      return showProduct ? (
        <tr className='data_row' key={index}>
          <td>{product.suppliercode || 'No Code'}</td>
          <td>{product.supplier.supplier_Short}</td>
          <td>{product.oaM_ACode}</td>
          <td>{product.brand.brand_Name}</td>
          {ShowQty && (
            <td>
              {`${product.productQuantity.quantity} ${product.productQuantity.quantity_Units}`}
            </td>
          )}
    {ShowPrice &&  ( <td>{`${product.productPrice.price.toLocaleString()} LKR`}</td>)}

         
        </tr>
      ) : (<tr></tr>);
    })
  ) : (
    <tr>
      <td>N/A</td>
    </tr>
  )}
</tbody>

      </tr>
    );
  })
) : (
  <></> // Display loading component while data is being fetched
)}

          </tbody>
                </table>

</div>
     
    
      </div>
    </div>
  );
};

export default Print_Export;
