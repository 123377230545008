import React, { useEffect, useState } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Default from "../../../Loading_Defualt";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuthUser,useAuth } from '../../../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface Supplier {
  supplierId: string;
  supplier_Name: string;
  supplier_Short: string;
  total_Products: number;
  archCount: number;
  logo_url: string;
}
  interface Catgory_select_Props {
    isOpen: boolean;
    closeModal: () => void;
  
    selectedCollection_Id: string | null;
    setselectedCollection_Name: (category: string | null) => void;
    setselectedCollection_Type: (category: string | null) => void;
    setselectedCollection_Id: (category: string | null) => void;
  }
export default function Supplier_Selector(props:Catgory_select_Props) {
  const [selectedSupplier, setSelectedSupplier] = useState<string | null>(props.selectedCollection_Id);
  const [SuppliersList, setSuppliersList] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const AuthUser = useAuthUser();
  const isAuth = useAuth();

  const navigate = useNavigate();
  if (!isAuth) {
    // Redirect to the login page if not verified
    navigate('/');
  }

  // Replace 'role1', 'role2', etc., with the actual roles you want to check
  const allowedRoles =['Director','Developer'] ;

  // Check if the user has at least one of the allowed roles
  const isVerified = allowedRoles.some(role => AuthUser.role === role);

  if (!isVerified) {
    // Redirect to the login page if not verified
    navigate('/');
  }
  const filteredSuppliers = SuppliersList.filter((Supplier) => {
    const supplierName = Supplier.supplier_Name;
    if (supplierName && typeof supplierName === 'string') {
      return supplierName.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });
  

  const fetchData = () => {
    customaxios.get("supplier/getsupplier").then((response) => {
      setSuppliersList(response.data);
      if (SuppliersList.length > 0) {
        setSelectedSupplier(SuppliersList[0].supplierId);
      }
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("SuppliersList", JSON.stringify(data));
    });
  };
  
  useEffect(() => {
    const storedData = localStorage.getItem("SuppliersList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setSuppliersList(data);
       
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("SuppliersList");
      }
    }
    
    fetchData();
  }, []);

  function clearLocalStorage() {
    localStorage.removeItem("SuppliersList");
    setSuppliersList([]);
    setLoading(true);
    fetchData();
  }



  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  
  const handle_select = (Supplier:Supplier)=>{
    setSelectedSupplier(Supplier.supplierId)
    props.setselectedCollection_Name(Supplier.supplier_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_BySupplier_paged")
    props.setselectedCollection_Id(Supplier.supplierId)
    console.log('selected ', Supplier)
  }
   
    return(
    <>
           <div className="CategoriesList">

         <div className="controls">
         <input className="Search_bar" type="text" placeholder="Search Suppliers..." value={searchTerm} onChange={handleSearchChange} />
         <button className="refresh" onClick={clearLocalStorage}><FontAwesomeIcon icon={faSyncAlt} /> </button>
         <a target="_blank" href={`${window.location.origin}/s`}>
      
      <div className="Categorylist_item">
        <div className="CategoryName"><p>&#9734; Open In New Tab</p></div>
      
        
      </div>
         </a>  
         </div>
          {loading ? (
            <Loading_Default />
          ) : (
            <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
               
               <tr>
                        <th className="px-6 py-3">Logo</th>
                        <th className="px-6 py-3">Short</th>
                        <th className="px-6 py-3">Name</th>
                        <th className="px-6 py-3">No of Items</th>
               </tr>
                      </thead>
                    
                    
                    <tbody>
                      
             
                      {filteredSuppliers.map((Supplier) => (
                        <tr             key={Supplier.supplierId} className="bg-white border-b dark:bg-gray-800 dark:border-red-500 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td>  {Supplier.logo_url && (
                             
                                <img src={Supplier.logo_url} style={{ maxWidth: 50 }}  alt="" />
                            )} </td>
               <td  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" >
                            {Supplier.supplier_Short}
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" key={Supplier.supplierId} onClick={() => {handle_select(Supplier); props.closeModal();}}>
                            {Supplier.supplier_Name}
                            </td>
                            <td>
                            {Supplier.total_Products}
                            </td>
                         
                            <td>
                            
                            </td>
                        </tr>
                           
                      
                        ))}
                    </tbody>
                    </table>
            
          )}
        </div>
        </>
        )
}