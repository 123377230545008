import customaxios from "../../../../helpers/axios-helper";
import React, { useEffect, useState ,useRef} from "react";
import { useAuth, useAuthUser } from "../../../../contexts/auth-context";
import AddToCartModal from "../../../Cart/Add_to_Cart/AddToCartModal";
import { faShoppingCart, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Product_Sensitive_Info from "../../Product_Sensitive_Info";
import Tooltip from "../../../Tooltip";
export default function Movement ({id}:any){
  const [Movement, setMovement] = useState<any>([]);
  const AuthUser = useAuthUser();
  const [product, setProduct] = useState<any>();

  const tooltipRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAddToCartClick = (prd) => {
    setProduct(prd)
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setProduct(null);
    setShowModal(false);
  };
  useEffect(() => {
    if (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") {
     

        customaxios.get(`productarch/Get_Arch_Product_Performance/${id}`)
        .then((response) => {
        //  console.log(response);
          // setarchinfo(response.data.arch_data);
          setMovement(response.data);

        //  console.log("response.data",response.data);
        });
    }
  }, [AuthUser?.role, id]);

  console.log(Movement)
    return(    <div className="movement">
      {showModal && <AddToCartModal IsPurchase_list={true} Ispos={false} item={product} onClose={handleCloseModal} />}

    <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
     <tr >
            <th></th>
            <th>name</th>
            <th>Brand </th>
            <th>Supplier </th>
            <th  onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>LPP
      
      {showTooltip && (
        <Tooltip content="Last Purchase Price" />
      )}
      </th>
            <th>Sold</th>
            <th>Purchased</th>
            <th>Purchase List</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
         {Movement?.map((Movement:any) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={Movement.product.productId}>
      
          <td><Product_Sensitive_Info product={Movement.product}/></td>
            <td> <div className="productname">
        <a href={`/Product/${Movement.product.productId}`}>
          <p className="font-semibold text-black-900">{Movement.product.itemdetails}</p>
          <p className="font-light text-gray-500 dark:text-gray-200 ">
            {Movement.product.oaM_ACode}{" - "}
            {Movement.product.suppliercode && Movement.product.suppliercode.trim() !== "" ? Movement.product.suppliercode.trim() : "Supplier Code"}

          </p>
        </a>
      </div></td>
            <td>{Movement.product.brand.brand_Name} </td>
            <td>{Movement.product.supplier
.supplier_Name} - ({Movement.product.supplier.supplier_Short})</td>
            <td>{Movement.product.productPrice.last_PurchasePrice} </td>
            <td>{Movement.totalOutUnits}</td>
            <td>{Movement.totalInUnits}</td>
            <td>({Movement.totalPurchaseReceivedQuantity} / {Movement.totalPurchaseQuantity})</td>
            <td>
  <button className="addtocartBtn" onClick={() => handleAddToCartClick(Movement.product)}>
    <FontAwesomeIcon icon={faShoppingCart} />
  </button>
</td>

          </tr>
          ))
         }
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>Total</td>
    <td>
      {Movement.reduce((totalOut, movement) => totalOut + movement.totalOutUnits, 0)}
    </td>
    <td>
      {Movement.reduce((totalIn, movement) => totalIn + movement.totalInUnits, 0)}
    </td>
  </tr>
        </tbody>
      </table>
    </div>)
}