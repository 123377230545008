import React, { useState, useEffect } from "react";
import "./Style/Style.css";
import customaxios from "../../helpers/axios-helper";
import _debounce from "lodash/debounce";
import { toast } from 'react-toastify';

interface Account {
  account_Id: number;
  name?: string | null;
}


interface From {
  Account?: Account;
  reference?: string;
}

interface To {
  Account?: Account;
  reference?: string;
}
interface AccountTransaction {
  FromAccountId: number;
  ToAccountId: number;
  Amount: number;
  Transaction_Date_Time: string; // You might want to format this accordingly
}


export default function Transaction() {
  const [isEditingFrom, setIsEditingFrom] = useState(false);
  const [isEditingTo, setIsEditingTo] = useState(false);
  const [from, setFrom] = useState<From | null>(null);
  const [to, setTo] = useState<To | null>(null);
  const [relatedLedgersFrom, setRelatedLedgersFrom] = useState<Account[]>([]);
  const [relatedLedgersTo, setRelatedLedgersTo] = useState<Account[]>([]);
  const [reference, setReference] = useState('');
  const [Amount, setAmount] = useState(0);

  const handleFromClick = () => {
    setIsEditingFrom(true);
  };

  const handleToClick = () => {
    setIsEditingTo(true);
  };

  const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReference(event.target.value);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
  
    // Use parseFloat to parse the input as a floating-point number
    const newValue = parseFloat(inputValue);
  
    // Check if newValue is a valid number
    if (!isNaN(newValue)) {
     // Check if the parsed value is a valid number
      setAmount(newValue);
    } else {
      // Handle invalid input here, e.g., show an error message
      toast.error("Invalid Char")
    }
  };
  
  useEffect(() => {
    
    setRelatedLedgersFrom([]);
    setRelatedLedgersTo([]);
    const fetchData = async (keyword: string, setRelatedLedgers: React.Dispatch<React.SetStateAction<Account[]>>) => {
      try {
        const response = await customaxios.post("ledger/search", { Keyword: keyword });
        setRelatedLedgers(response.data.ledgers);
      } catch (error) {
        console.error(error);
      }
    }

    if (isEditingFrom && from?.Account?.name && from.Account.name.length > 3) {
      fetchData(from.Account.name, setRelatedLedgersFrom);
    }

    if (isEditingTo && to?.Account?.name && to.Account.name.length > 3) {
      fetchData(to.Account.name, setRelatedLedgersTo);
    }
    console.log("Account from",from)
    console.log("Account to",to)
  }, [isEditingFrom, isEditingTo, from, to]);

  const handleFromInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFrom({ Account: { ...from?.Account, name: event.target.value } });
  };

  const handleToInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTo({ Account: { ...to?.Account, name: event.target.value } });
  };

  const handleFromSelect = (Account: Account) => {
    setIsEditingFrom(false);
    setFrom({ Account });
  };

  const handleToSelect = (Account: Account) => {
    setIsEditingTo(false);
    setTo({ Account });
  };
/*
  const handleSubmit = () => {
  //  console.error("Not authorized");
    customaxios.post("accounts/Create_Transaction",{
      FromAccountId: from.Account.account_Id,
      ToAccountId:to.Account.account_Id,
      Amount: Amount,
      Sender_Description: reference,
      Beneficiary_Description:reference
    }).then((res)=>{
      console.log(res);
    })
  };
*/  const handleSubmit = () => {
  if (from?.Account?.account_Id === to?.Account?.account_Id) {
    // Display an error message or handle the situation where the same account is selected in both "From" and "To".
    toast.error("From and To accounts cannot be the same.");
  } else {
    // The "From" and "To" accounts are different, so you can proceed with the transaction.
    customaxios.post("accounts/Create_Transaction", {
      FromAccountId: from.Account.account_Id,
      ToAccountId: to.Account.account_Id,
      Amount: Amount,
      Sender_Description: reference,
      Beneficiary_Description: reference
    }).then((res) => {
      console.log(res);
      toast.success("Transaction Created Succesfully")
      setFrom(null)
      setTo(null)
      setAmount(0)
      setReference('')
    });
  }
};
  return (
    <div className="transaction-container">
      <div className="transaction-fields from">
        <div className="selector">
     <div className="input">
      <h2>From</h2>
     {isEditingFrom ? (
            <div>
              <input
                type="text"
                value={from?.Account?.name || ""}
                onChange={handleFromInputChange}
                placeholder="From Acount"
              />
            </div>
          ) : (
            <p onClick={handleFromClick}>
              {from?.Account?.name ? from.Account.name : "Click To Select From Acount"} 
            </p>
          )}
     </div>

          <br />
          <br />
         <div className="list">
         {isEditingFrom && relatedLedgersFrom.length > 0 && (
            <ul className="ledger-select-list">
              {relatedLedgersFrom.map((ledger) => (
                <li
                  className="ledger-select-item"
                  key={ledger.account_Id}
                  onClick={() => handleFromSelect(ledger)}
                >
                  {ledger.name}
                </li>
              ))}
            </ul>
          )}
         </div>
        </div>
      </div>
      <div className="transaction-fields to">
       <div className="input">
      <h2>To</h2>

       {isEditingTo ? (
          <div>
            <input
              type="text"
              value={to?.Account?.name || ""}
              onChange={handleToInputChange}
              placeholder="To Acount"
            />
          </div>
        ) : (
          <p onClick={handleToClick}>
            {to?.Account?.name ? to.Account.name : "Click To Select To Acount"} 
          </p>
        )}
       </div>
        {isEditingTo && relatedLedgersTo.length > 0 && (
          <ul className="ledger-select-list">
            {relatedLedgersTo.map((ledger) => (
              <li
                className="ledger-select-item"
                key={ledger.account_Id}
                onClick={() => handleToSelect(ledger)}
              >
                {ledger.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <br />
          <br />
      <div className="reference-field reference">
      <p>Reference</p>
        <input
          type="text"
          value={reference}
          onChange={handleReferenceChange}
          placeholder="Reference"
        />
      </div>
      <br />
          <br />
      <div className="amount-field amount">
        <p>Amount</p>
      <input
          type="text"
          value={Amount}
          onChange={handleAmountChange}
          placeholder="Amount"
        />
      </div>
      <div className="transaction-submission submit">
        <button className="button_one" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}