import React, { useState, useEffect ,useContext} from "react";
import customaxios from "../../helpers/axios-helper";
import EditProduct_Brand from "./Product_Brand_Edit";
import EditProduct_Category from "./Product_Category_Edit";
import {  useAuthUser } from "../../contexts/auth-context";
import {  Link } from "react-router-dom";

import Product_Sensitive_Info from "./Product_Sensitive_Info";
import Loading_Defualt from "../Loading_Defualt";
import {  toast } from 'react-toastify';
import ImageRow from "../Images/ImageRow";
import Product_Name_Supplier_Code_Change from "./product_Name_Supplier_Code_Change";
import AddToCartModal from "../Cart/Add_to_Cart/AddToCartModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faEye } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from 'react-confirm-alert';

import Product_Itemdetails_change from "./product_Itemdetails_change";
import { ThemeContext, Theme } from '../Controls/Theme/ThemeContext';
import Product_History from "./Product_History";
import Product_Name_change from "./product_Name_change";
interface ProductInfoProps {
  id: string;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ id }) => {
  const [product, setProduct] = useState<any>();
  const AuthUser = useAuthUser();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const { theme } = useContext(ThemeContext);


  const handleAddToCartClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Update the inactive state when the product changes
    if (product) {
      setIsInactive(product.deactive !== null ? true : product.deactive);
    }
  }, [product]);

  useEffect(() => {
    // get product data from custom axios
    customaxios.get(`Product/GetProduct/${id}`)
      .then((res) => {
        setProduct(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    }, [id]);

   // console.log(product);
    const handleCopyLink = async () => {
      try {
        const baseUrl = window.location.origin; // Get the domain URL
        const linkToCopy = `${baseUrl}/ProductImage/${product.productId}`;
        await navigator.clipboard.writeText(linkToCopy);
        toast.success("Link copied to clipboard");
        // You can display a success message or perform any other desired action
      } catch (error) {
        console.error("Copy failed: ", error);
        toast.error("copy failed");
        // You can display an error message or handle the error in an appropriate way
      }
    };
    

    const handleDeactivate = async () => {
      const confirmationMessage = 'Are you sure you want to Deactivate this item?';

      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios.put(`/product/Deactive_Product/${product.productId}`);
              setIsInactive(true);
              toast.success("Deactivated successfully");
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });

    
    };

    const handleDeactive_Product_singular = async () => {
      const confirmationMessage = 'Are you sure you want to update this item?';

      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios.post(`/product/Deactive_Product_singular/${product.productId}`);
              setIsInactive(!isInactive);
              toast.success("updated successfully");
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });

    
    };

    const handleactivate = async () => {
      const confirmationMessage = 'Are you sure you want to activate this item?';

      confirmAlert({
        title: 'Confirmation',
        message: confirmationMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              customaxios.put(`/product/activate_Product/${product.productId}`);
              setIsInactive(false);
              toast.success("activated successfully");
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });

    
    };
    const handleVerify_Item = async () => {
      try {
        await customaxios.put(`/product/Verify_Item/${product.productId}`);

      //  setIsInactive(true);
        toast.success("Verified successfully");

      } catch (error) {
        console.error('Error Verifiying product:', error);
      }
    };


  return (
   
   <>

{loading ?(<Loading_Defualt />):(<>
  {showModal && <AddToCartModal item={product} onClose={handleCloseModal} />}
  {product && (




<div className={`border border-gray-200 p-4 mb-4 rounded-lg dark:bg-gray-800 dark:text-white`}>
  <div className="grid gap-4">
      <table className="table-auto">
        <tbody>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">Product Name:</td>
            <td><Product_Name_change product={product} /></td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">Item Details:</td>
            <td><Product_Itemdetails_change product={product} /></td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">Arch:</td>
            <td>
              <table className="table-auto">
                <tbody>
                  <tr className="border-b border-gray-200">
                    <td>{product.productArch.productName}</td>
                  </tr>
                  <tr>
                    <td>
                      <Link to={`/Arch/${product.productArch.productArchId}`} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        {product.productArch.arcCode}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">Collection:</td>
            <td>
              <table className="table-auto">
                <tbody>
                  <tr className="border-b border-gray-200">
                    <td className="font-semibold">Category</td>
                    <td>
                      {AuthUser.role === "Director" || AuthUser?.role === "Praveen" || AuthUser.role === "Developer" ? (
                        <EditProduct_Category product={product} />
                      ) : (
                        product.product_Group.group_Name
                      )}
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200">
                    <td className="font-semibold">Brand</td>
                    <td>
                      {AuthUser.role === "Director" || AuthUser?.role === "Praveen" || AuthUser.role === "Developer" ? (
                        <EditProduct_Brand product={product} />
                      ) : (
                        product.brand.brand_Name
                      )}
                    </td>
                  </tr>
                  <tr >
                    <td className="font-semibold">Model</td>
                    <td>{product.model}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">Supplier Code:</td>
            <td>      <Product_Name_Supplier_Code_Change product={product} />
</td>
          </tr>
          <tr className="border-b border-gray-200">
            <td className="font-semibold">OAM Code:</td>
            <td>        <span>{product.oaM_ACode}</span>
</td>
          </tr>
       

          {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
               <tr className="border-b border-gray-200">
        <td className="font-semibold">Supplier :</td>
        <td>{product.supplier.supplier_Name}</td>
    </tr>

  )}
            {
                    AuthUser.role === "Director" || AuthUser.role === "Developer"  && (
        
        <tr className="border-b border-gray-200">
            <td  className="font-semibold">Director Tools:</td>
            <td><Product_Sensitive_Info
                      product={product}
                    /></td>
          </tr>
        
        
                       
                   )
                  }
                  <tr className="border-b border-gray-200">
                  {AuthUser?.role !== "Customer" && (
        <div className="addtocart">
          <button className={`addtocartBtn px-4 py-2 rounded-lg `} onClick={handleAddToCartClick}>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="ml-2">Add to Cart</span>
          </button>
        </div>

      )}
<td>        <Product_History productId={product.productId}/></td>

                  </tr>

                  <tr className="border-b border-gray-200">
            <td  className="font-semibold">Stocks & Rate</td>
<tr className="border-b border-gray-200">
<td>Sale Price</td>
            <td>: {product.productPrice.price.toLocaleString('en-GB', { maximumFractionDigits: 2, style: 'currency', currency: 'LKR' })}</td>
        
</tr>
<tr >
            <td>Quantity</td>
            <td>: {product.productQuantity.quantity.toLocaleString('en-GB', { maximumFractionDigits: 2 })} {product.productQuantity.quantity_Units}</td>
          </tr>
                  </tr>


                  <tr className="border-b border-gray-200">
                  <td  className="font-semibold">Inventory Status</td>
                  <td>
            {product.pending ? (
             <>
              <span>Pending</span>
              <button className="button_one" onClick={handleVerify_Item}>
              {product.pending ? ('Verify'):('Mark Pending')}
            </button>
             </>
            ) : (
              <span>Verified</span>
            )}
          
          </td>
                  </tr>
                  <tr className="border-b border-gray-200">
                  <td  className="font-semibold">  Status : {product.deactive  == true   ? ( 'Inactive ') :('Active')}</td>

                <td className="border-b border-gray-200">
                {
  (AuthUser.role === "Director" || AuthUser?.role === "Praveen"|| AuthUser.role === "Developer") && (
    <button
      className={`button_one ${product.deactive == true ? '' : 'danger'}`}
      onClick={handleDeactive_Product_singular}
    >
      {product.deactive == true   ? ('Activate') : ('Deactivate')}
    </button>
  )
}
                </td>
                  </tr >


 
     
          {AuthUser.role === "Director" || AuthUser.role === "Developer" ? (
       
         
 
           <tr className="border-b border-gray-200">
                <th>Year</th>
                <th>Opening Stock</th>
                <th>Closing Stock</th>
              </tr>
            
     
        ) : null}
        
         
 
       {AuthUser.role === "Praveen" || AuthUser.role === "Director" || AuthUser.role === "Developer" ? 
       (
       product.stockBalances.map((stockBalance) => (
        <tr className="border-b border-gray-200" key={stockBalance.years}>
          <td>{stockBalance.years}</td>
          <td>
            <p>
             {stockBalance.openingBalance.toLocaleString('en-GB', { maximumFractionDigits: 2 })} {stockBalance.openingUnits}
            </p>
          </td>
          <td>
          <p>
             {stockBalance.closingBalance.toLocaleString('en-GB', { maximumFractionDigits: 2 })} {stockBalance.closingUnits}
            </p>
          </td>
        </tr>
      ))
  ):(null)
      }
            
 
               
        </tbody>
      </table>
  
  </div>

  






 {/*
 
 
 {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer" || AuthUser?.role === "Praveen") &&  (
  <div className=" Pending">
    <h3>Inventory Status</h3>
        <tr>
          <td>
            {product.pending ? (
              <span>Pending</span>
            ) : (
              <span>Verified</span>
            )}
            <button className="button_one" onClick={handleVerify_Item}>
              {product.pending ? ('Verify'):('Mark Pending')}
            </button>
          </td>
        </tr>
  </div>
) }
 Add a null check for cases where the condition is not met */}





<div className=" Images" onClick={handleCopyLink} >

<ImageRow minfied={true} productarch={product.productArch}/>

</div>


</div>

    )}

</>)}

  
   </>
  );
};

export default ProductInfo;


/*
<button onClick={handleCopyLink} >click to copy link</button>
*/{/*
isInactive != true ? (
      <button className="button_one danger" onClick={handleDeactivate}>
        Deactivate
      </button>
    ) : (
      <button className="button_one " onClick={handleactivate}>
        Activate
      </button>
    )
 */}