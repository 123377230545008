import react from 'react';

export default function Loading_Defualt() {

    return (
        <div className="loading-animation">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
    );
}