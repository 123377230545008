import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthUser,useAuth } from '../../contexts/auth-context'; 
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef,useContext } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import "./Style/Style.css"

// Assuming your links and roles data are structured like this
const links = [
    { path: '/List_Sales_Reps', label: 'Sales Orders', roles: ['Director','Developer','Praveen','Billing_Agent'] },
    { path: '/products', label: 'Products', roles:  ['Director','Developer','Praveen','Sales_Rep','Billing_Agent'] },
    { path: '/Customer_Dash_V2', label: 'Customers', roles: ['Director','Developer','Praveen','Billing_Agent'] },
    { path: '/Customer_Routes', label: 'Routes', roles:['Director','Developer','Praveen','Billing_Agent'] },
    { path: '/Accounts', label: 'Accounts', roles: ['Director','Developer','Praveen'] },
    { path: '/Cart', label: 'Cart', roles: ['Director','Developer','Praveen','Sales_Rep','Billing_Agent'] },
    { path: '/Pos', label: 'Pos', roles:['Director','Developer','Praveen','Billing_Agent'] },
    { path: '/Product_Purchase', label: 'Purchase', roles: ['Director','Developer','Praveen'] },
    // ... other links
  ];

export default function Navigation(){
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  }; 
  const modalRef = useRef(null); // Reference to the modal
  const AuthUser = useAuthUser();
  const isAuth = useAuth();
  
  const handleBackgroundClick = (event) => {
    // Close the modal if the click event target is the modal background
    if (event.target === modalRef.current) {
      setIsModalOpen(false);
    }
  };

  const allowedLinks = links.filter(link => {
    // If the link has no specified roles, include it
    if (!link.roles || link.roles.length === 0) {
      return true;
    }

    // Include the link if the user has at least one of the required roles
    return link.roles.some(role => AuthUser.role === role);
  });
    return(
        <>
        
     {isAuth ? ( <div
     
     onClick={handleModalToggle}
 
   >
    
     <FontAwesomeIcon icon={faBars} />
   </div>):(null)}

         {isModalOpen && (
        <div className="Navigation-modal" ref={modalRef} onClick={handleBackgroundClick}>
          <div  className={`Navigation-modal-content `}>
            <h2>Menu</h2>
            <ul>
              {allowedLinks.map(link => (
                <li key={link.path}>
                  <a  href={link.path}>{link.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
        </>
    )
}