import React, { useState, useEffect } from "react";
import Invoice from "./Invoice";
import customaxios from "../../helpers/axios-helper";
import PrintInvoice from "./PrintInvoice";
import { useParams } from 'react-router-dom';
import InvoiceModal from './InvoiceModal';
interface RouteParams {
  id: string;
  [key: string]: string;
}
export default function Invoice_Controller({ id: routeId }: { id: string | null }) {
  const { id: paramId } = useParams<RouteParams>();
  const orderId = paramId ? paramId : routeId;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [invoices, setInvoices] = useState<any[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<any | null>(null);

  const openModal = (invoice: any) => {
    setSelectedInvoice(invoice);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };
  useEffect(() => {
    setLoading(true);
    setError("");
    customaxios
      .get("invoice/getinvoicesbyorderid/" + orderId)
      .then((response) => {
        setInvoices(response.data);
      console.log(invoices)
      })
      .catch((error) => {
      //  console.error(error);
        setError("An error occurred while loading the invoices.");
      })
      .finally(() => {
        setLoading(false);
      });

     
  }, [orderId]);



  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoices || invoices.length === 0) {
    return <div>No invoices found.</div>;
  }
  //console.log(invoices)

  return (
    <>
    <br />
    <br />
    <br />
    <br />
    <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
   <tr className="_Table_Custom_Row Title">
          <th>
            Invoice Group
          </th>
          <th>
            Invoice No
          </th>
          <th>
           Customer
          </th>
          <th>
           Created
          </th>
          <th>
           Total
          </th>
          <th>
            Due Amount
          </th>
          <th>
           Pay Type
          </th>
          <th>
           Print
          </th>
          <th>
           View
          </th>
        </tr>

      </thead>
    
    <tbody>
    {invoices.map((invoice, index) => (
      <tr key={invoice.invoiceId} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
      <td>
{invoice?.billing_Group_Contained
.name}
      </td>
      <td>
{invoice.invoice_Id_Simplified}
      </td>
      <td>
{invoice.customer?.customerName
}
      </td>
      <td>
{invoice.createdDateTime
.toLocaleDateString()}
      </td>
      <td>
{invoice.invoiceValue.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}
      </td>
      <td>
{invoice.paymentDue.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}
      </td>
      <td>
{invoice.paymentType
+" "} 
({invoice.creditDays
} Days)
      </td>
    
      <td>
      <PrintInvoice  voucher={invoice} />
      </td>
      <td>
      <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => openModal(invoice)}
              >
                View
              </button>
      </td>
    </tr>
        
        ))}
      
    </tbody>
    </table>
    {selectedInvoice && (
        <InvoiceModal isOpen={true} onClose={closeModal} invoiceid={selectedInvoice.invoiceId} />
      )}
    </>
  );
}



{
  /*
     <Tabs value={activeTab} onChange={handleTabChange}>
      {invoices.map((invoice, index) => (
        <Tab key={invoice.invoiceId} label={invoice.invoice_Id_Simplified} />
      ))}
    </Tabs>
    {invoices.map((invoice, index) => (
      <Box key={invoice.invoiceId} hidden={activeTab !== index}>
        <Invoice invoice={invoice} />
      </Box>
    ))}
  */
 }