import  { useState, useCallback } from "react";
import customaxios from "../../helpers/axios-helper";

export default function Twofactor() {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOtpChange = useCallback((e) => {
    setOtp(e.target.value.trim());
  }, []);

  const processOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const user = {
      email: "t",
      password: "t",
      location: "t",
      otp,
    };

    // Add your OTP submission logic here
    customaxios
      .post("auth/login_2fa", user)
      .then((response) => {
        console.log(response);

        const data = response.data;

        if (data) {
          if (typeof data !== "string") {
            // Reset the form and perform any success action
         //   document.getElementById("otp-form").reset();
            // alert('You have been logged in successfully.');
            // notify();
          } else {
            setErrorMessage(data);
          }
        } else {
          setErrorMessage("Something went wrong");
        }
      })
      .catch((error) => {
        setErrorMessage("An error occurred during OTP submission.");
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-form-wrapper">
      <form className="login-form" id="otp-form" onSubmit={processOTP}>
        <div className="floating-input">
          <input
            type="text"
            id="otp-code"
            placeholder="OTP Code"
            required
            onChange={handleOtpChange}
            value={otp}
            disabled={isLoading}
          />
          <label htmlFor="otp-code">OTP Code</label>
        </div>
        <input
          type="submit"
          id="otp-submit"
          value="Submit OTP"
          disabled={isLoading}
        />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
}
