import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import customaxios from '../../../helpers/axios-helper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
export default function  Payee (){
    const [Payee, setPayee] = useState([]);
    const [Payee_, setPayee_] = useState<any>({});


    useEffect(() => {
        async function fetchData() {
          try {
            const response = await customaxios.get("Payee/GetAllPayee");
            setPayee(response.data);
            console.log(response.data);
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }, []);



      const handleChange = (e) => {
        setPayee_({ ...Payee_, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await customaxios.post("Payee/CreatePayee", Payee_);
          alert("Payee_ Created successfully!")
        } catch (error) {
          console.error(error);
          alert("Failed to Create Payee_");
        }
      };


    return(
        <div>

 <form onSubmit={handleSubmit}>
        <label htmlFor="Payee_">Payee_:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={Payee_.Name}
          placeholder="Name"
          onChange={handleChange}
        />
             <input
          type="text"
          id="Description"
          name="Description"
          placeholder="Description"
          value={Payee_.Description}
          onChange={handleChange}
        />
             <input
          type="text"
          id="PayeeType"
          name="PayeeType"
          placeholder="PayeeType"
          value={Payee_.PayeeType}
          onChange={handleChange}
        />
            
        <button type="submit">Create Payee</button>
      </form>


         <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Payee_ ID</TableCell>
            <TableCell>name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>PayeeType</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {Payee.map((Payee_) => (
    <TableRow key={Payee_.payeeId} component={Link} to={`/payee/${Payee_.payeeId}`}>
      <TableCell>{Payee_.payeeId}</TableCell>
      <TableCell>{Payee_.name}</TableCell>
      <TableCell>{Payee_.description}</TableCell>
      <TableCell>{Payee_.payeeType}</TableCell>
    </TableRow>
  ))}
</TableBody>
      </Table>
    </TableContainer>
        </div>
    )
}