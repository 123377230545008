import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material";
import customaxios from "../../helpers/axios-helper"
import Profit_Loss_Transaction_Report from "./Profit_Loss_Transaction_Report";
export default function Profit_Loss(){
    const [Compaies, setCompaies] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<string | null>('all');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

           // Group expenses by company_Name
  const groupedExpenses: { [key: string]: any[] } = {};
  Compaies.forEach((expense) => {
    const company = expense.billingGroup || "N/A"; // Use "N/A" for null values
    if (!groupedExpenses[company]) {
      groupedExpenses[company] = [];
    }
    groupedExpenses[company].push(expense);
  });
    useEffect(() => {
      fetchExpenses();
    }, []);
  
    const fetchExpenses = async () => {
      try {
        const response = await customaxios.get("companysales/profit_loss");
        setCompaies(response.data);
      } catch (error) {
        console.error("Error fetching profit_loss:", error);
        // Optionally, you can handle errors here, e.g., showing an error message to the user.
      }
    };
return(<>
<Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        centered
      >
          <Tab key="all" label="All" value="all" />
        {Object.keys(groupedExpenses).map((companyName) => (
          <Tab key={companyName} label={companyName} value={companyName} />
        ))}
      </Tabs>

       {/* Render expenses for the selected tab */}
 <table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr >
            <th>Name</th>
            <th className="text-end">Gross Profit</th>
            <th className="text-end">Total Returns</th>
            <th className="text-end">Profit</th>
          </tr>
        </thead>
        <tbody>
          {selectedTab === "all"
            ? Compaies.map((company) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={company.general_ExpenseId}>  
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white "  >
                    <Profit_Loss_Transaction_Report Billing_Group={company.billingGroup}/>
                   </td>
                  <td className="text-end">{company.grossProfit.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</td>
                  <td className="text-end">{company.totalReturns.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) || "N/A"}</td>    
                         <td className="text-end">{company.netProfit.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) || "N/A"}</td>
                </tr>
              ))
            : groupedExpenses[selectedTab]?.map((company) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={company.general_ExpenseId}>  
              
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" > <Profit_Loss_Transaction_Report Billing_Group={company.billingGroup}/></td>
                  <td className="text-end">{company.grossProfit.toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</td>
                  <td className="text-end">{company.totalReturns.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) || "N/A"}</td>    
                         <td className="text-end">{company.netProfit.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) || "N/A"}</td>
                </tr>
              ))}
        </tbody>
      </table>
</>)
}