import SalesOrderDate from "./SalesOrderDate";
import { Menu, MenuItem } from "@mui/material";
import { useRef } from 'react';
import React, { useState } from 'react';

import StatusDropdown_SO from "./Status_Model/StatusDropdown_SO";
import "./Style_sales_order_V2/Style.css";
export default function OrderList_V2({ salesOrders, selectedOrder, handleOrderClick }) {
  const [contextMenu, setContextMenu] = useState<{
    open: boolean;
    anchorEl: null | HTMLElement;
  }>({ open: false, anchorEl: null });

  const handleOrderContextMenu = (event: React.MouseEvent<HTMLTableRowElement>, salesOrderId: string) => {
    event.preventDefault();
    setContextMenu({ open: true, anchorEl: event.currentTarget });
    handleOrderClick(salesOrderId);
  };

  const handleContextMenuClose = () => {
    setContextMenu({ open: false, anchorEl: null });
  };
  console
  .log(salesOrders)


  // Modify the data structure of salesOrders to group by day
const salesOrdersByDay = salesOrders.items.reduce((acc, salesOrder) => {
  const createdDate = new Date(salesOrder.created_Date_Time);
  const dayKey = createdDate.toDateString(); // Using only the date portion

  if (!acc[dayKey]) {
    acc[dayKey] = [];
  }

  acc[dayKey].push(salesOrder);
  return acc;
}, {});

console.log("organised",salesOrdersByDay)
  return (
    <div className="orderlist-container"> {/* Wrap the table inside a container */}
<table className="w-full text-sm text-gray-500 dark:text-gray-400">
  <thead className="bg-gray-50 dark:bg-gray-700">
    <tr className="text-xs text-gray-700 uppercase">
      <th className="px-4 py-2">Urgent</th>
      <th className="px-4 py-2">S/O No</th>
      <th className="px-4 py-2">Date</th>
      <th className="px-4 py-2">Customer</th>
      <th className="px-4 py-2">Est Total</th>
      <th className="px-4 py-2">Sales Rep</th>
      <th className="px-4 py-2">Status</th>
    </tr>
  </thead>
  <tbody>
    {Object.entries(salesOrdersByDay).map(([day, salesOrdersForDay]: [string, any[]]) => (
      <React.Fragment key={day}>
        <tr className="bg-gray-100 dark:bg-gray-800">
          <td colSpan={7} className="px-4 py-2 font-bold">{day}</td>
        </tr>
        {salesOrdersForDay.map((salesOrder: any) => (
          <tr
            key={salesOrder.salesOrderId}
            className={`hover:bg-gray-200 dark:hover:bg-gray-600 ${selectedOrder === salesOrder.salesOrderId ? 'bg-blue-100 dark:bg-blue-800' : 'bg-white dark:bg-gray-700'}`}
            onClick={() => {
              handleOrderClick(salesOrder.salesOrderId);
              window.location.href = `/SalesOrder_v2/${salesOrder.salesOrderId}`;
            }}
            onContextMenu={(event) => handleOrderContextMenu(event, salesOrder.salesOrderId)}
          >
            <td className="px-4 py-2">{salesOrder.urgent ? "Urgent" : ""}</td>
            <td className="px-4 py-2">#{salesOrder.salesOrderId}</td>
            <td className="px-4 py-2"><SalesOrderDate createdDateTime={salesOrder.created_Date_Time} /></td>
            <td className="px-4 py-2">{salesOrder.customerName}</td>
            <td className="px-4 py-2">{salesOrder.total ? salesOrder.total.toLocaleString() : "0"} LKR</td>
            <td className="px-4 py-2">{salesOrder.salesRepFullName}</td>
            <td className="px-4 py-2">
              <StatusDropdown_SO onStatusChange={() => { }} id={salesOrder.salesOrderId} status={salesOrder.status} item={salesOrder} />
            </td>
          </tr>
        ))}
      </React.Fragment>
    ))}
  </tbody>
</table>


      <OrderContextMenu
        salesOrderId={selectedOrder}
        anchorEl={contextMenu.anchorEl}
        handleClose={handleContextMenuClose}
      />
    </div>
  );
}

interface OrderContextMenuProps {
  salesOrderId: string;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

function OrderContextMenu({ salesOrderId, anchorEl, handleClose }: OrderContextMenuProps) {

  const handleCopyLink = async () => {
    try {
      const currentUrl = window.location.href;
      const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf("/") + 1);
      const linkToCopy = `${baseUrl}SalesOrder/${salesOrderId}`;
      await navigator.clipboard.writeText(linkToCopy);
      handleClose();
    } catch (error) {
      console.error("Copy failed: ", error);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleCopyLink}>Copy Link</MenuItem>
    </Menu>
  );
}
