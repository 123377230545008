import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import customaxios from "../../../helpers/axios-helper";
import { useAuthUser,useAuth } from '../../../contexts/auth-context'; 
import Purchase_Modal from "../Navigation/Purchase_Modal";
import { useNavigate } from 'react-router-dom';
interface RouteParams {
    id: string;
    [key: string]: string;
  }
export default function Purchase_Order_Page({ id: routeId }: { id: string | null }){
    const { id: paramId } = useParams<RouteParams>();
    const id = paramId ? paramId : routeId;
    const [PurchaseLists, setPurchaseLists] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const isAuth = useAuth();

    const AuthUser = useAuthUser();
    const navigate = useNavigate();
    const allowedRoles = ['Director','Developer','Praveen'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }

    useEffect(() => {
        customaxios
        .get("/Purchase/Purchase_Order_List_ById/?id="+id, )
          .then((response) => {
            setPurchaseLists(response.data);
  
            setIsLoading(false);
    
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Handle validation errors
              const validationErrors = error.response.data;
              console.log("Validation errors:", validationErrors);
              // Update state or show error messages to the user
            } else {
              console.log("Error:", error);
              // Handle other types of errors
            }
          });
      }, []);
    
    return(<>
        <table  className="w-3/4 mx-auto text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
 <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
 <tr >
  <th>product</th>
  <th>QTY</th>
 </tr>
 </thead>

 <tbody>
 <Purchase_Order id={id}/>

 </tbody>
   </table>
        </>)
}


function Purchase_Order({id}){
    const [Purchase_Order, setPurchase_Order] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    useEffect(() => {
        customaxios
        .get("/Purchase/GetPurchase_Order_ById/?id="+id, )
          .then((response) => {
            setPurchase_Order(response.data);
          //  console.log("Sales Rep list");
            console.log("ourchase data", response.data);
            setIsLoading(false);
    
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // Handle validation errors
              const validationErrors = error.response.data;
              console.log("Validation errors:", validationErrors);
              // Update state or show error messages to the user
            } else {
              console.log("Error:", error);
              // Handle other types of errors
            }
          });
      }, []);


    return(
        <>
<br />
<tr className="border border-red-600 bg-red-100">
<td>
            {Purchase_Order.supplier?.supplier_Short} - {Purchase_Order.purchase_OrderId}
        </td>
<td> </td>
<td></td>
</tr>

 {
    Purchase_Order.purchase_Order_Items?.map((item => {
        
        return(
        <tr
        
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
<td>
<p className="font-semibold text-black-900">{item.product.itemdetails}</p>
          <p className="font-light text-gray-500 dark:text-gray-200 ">
            {item.product.oaM_ACode}{" - "}
            {item.product.suppliercode && item.product.suppliercode.trim() !== "" ? item.product.suppliercode.trim() : "Supplier Code"}

          </p>


</td>
<td>
    <p>{item.quantity}</p>
</td>
<td>

<Purchase_Modal id={item?.product?.productArch.productArchId} />
</td>

        </tr>
        )}))
        }

       
        </>
    )
}