import React, { useState, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { toast } from 'react-toastify';
import { useAuthUser } from "../../contexts/auth-context";

export default function Product_Name_Supplier_Code_Change({ product }) {
  const [isEditing, setIsEditing] = useState(false);
  const [suppliercode, setSupplierCode] = useState(product.suppliercode);
  const AuthUser = useAuthUser();
  const inputRef = useRef(null);

  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/product/update_Product_SupplierCode", {
        SupplierCode: suppliercode,
        Product: product.productId,
      })
      .then((response) => {
        console.log("set Item Deitals");
        console.log(response.data);
        console.log("set false");

        setIsEditing(false);
        toast.success('Supplier Code Updated');

        product.suppliercode = suppliercode; // This might not update the UI, consider updating the state instead
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setSupplierCode(product.suppliercode);
  };

  const handleDoubleClick = () => {
    if (AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Praveen" || AuthUser?.role === "Developer") {
      setIsEditing(true);
    }
  };

  const handleInputChange = (event) => {
    setSupplierCode(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveChanges();
    } else if (event.key === 'Escape') {
      handleCancelEditing();
    }
  };

  return (
    <>
      {isEditing ? (
        <input
          type="text"
          value={suppliercode}
          onChange={handleInputChange}
          onBlur={handleSaveChanges}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          autoFocus
        />
      ) : (
        <p onDoubleClick={handleDoubleClick}>{product.suppliercode}</p>
      )}
    </>
  );
}
