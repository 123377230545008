import ListProvision_for_Tax from "./ListProvision_for_Tax";
import CreateProvision_for_Tax from "./CreateProvision_for_Tax";
export default function  Provision_for_Tax (){
    
    return(
    <>
   
      <CreateProvision_for_Tax/>
        <br />
        <ListProvision_for_Tax/>
    </>)
}