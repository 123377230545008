import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

export default function VoucherTable({ voucherItems, onDelete, onNoteAdd, onInvoiceAdd }) {
  const [noteIndex, setNoteIndex] = useState(null);
  const [note, setNote] = useState("");
  const [invoiceIndex, setInvoiceIndex] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const handleNoteChange = (event) => {
    setNoteIndex(parseInt(event.target.dataset.index));
    setNote(event.target.value);
  };

  const handleNoteSave = () => {
    onNoteAdd(noteIndex, note);
    setNoteIndex(null);
    setNote("");
  };

  const handleInvoiceChange = (event) => {
    setInvoiceIndex(parseInt(event.target.dataset.index));
    setInvoiceNumber(event.target.value);
  };

  const handleInvoiceSave = () => {
    onInvoiceAdd(invoiceIndex, invoiceNumber);
    setInvoiceIndex(null);
    setInvoiceNumber("");
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Description</th>
          <th>Amount</th>
          <th>Note</th>
          <th>Invoice Number</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {voucherItems.map((item, index) => (
          
          
          <tr key={index}>

            <td>{item.voucherDescription} ({item.selectedCategory || "No category selected"})</td>
            <td>{item.voucherAmount.toLocaleString()} LKR</td>
            <td>
              {noteIndex === index ? (
                <>
                  <input
                    type="text"
                    value={note}
                    data-index={index}
                    onChange={handleNoteChange}
                    placeholder="Enter note..."
                  />
                  <button onClick={handleNoteSave}>Save</button>
                </>
              ) : (
                <>
                  {item.note ? (
                    <>
                      <span>{item.note}</span>
                      <button onClick={() => setNoteIndex(index)}><FontAwesomeIcon icon={faPen} /></button>
                    </>
                  ) : (
                    <button onClick={() => setNoteIndex(index)}><FontAwesomeIcon icon={faPen} /></button>
                  )}
                </>
              )}
            </td>
            <td>
              {invoiceIndex === index ? (
                <>
                  <input
                    type="text"
                    value={invoiceNumber}
                    data-index={index}
                    onChange={handleInvoiceChange}
                    placeholder="Enter invoice number..."
                  />
                  <button onClick={handleInvoiceSave}>Save</button>
                </>
              ) : (
                <>
                  {item.Invoice_Number ? (
                    <>
                      <span>{item.Invoice_Number}</span>
                      <button onClick={() => setInvoiceIndex(index)}><FontAwesomeIcon icon={faPen} /></button>
                    </>
                  ) : (
                    <button onClick={() => setInvoiceIndex(index)}><FontAwesomeIcon icon={faPen} /></button>
                  )}
                </>
              )}
            </td>
            <td>
              <button onClick={() => onDelete(index)}><FontAwesomeIcon icon={faTrash} /></button>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={5}>Item Count: {voucherItems.length} | Total Value: {voucherItems.reduce((total, item) => total + parseFloat(item.voucherAmount), 0)} LKR</td>
        </tr>
      </tbody>
    </table>
 
  );
          }