import { doesContainAlphabets, doesContainAlphabetsWithCase, doesContainNumbers, doesContainNumOfChars, doesMatch } from "../helpers/password-helper";

import { ReactComponent as TickSVG } from '../assets/vectors/tick.svg';
import { useEffect, useRef, useState } from "react";


export default function CheckPasswordRequirements({ checkPasswordMatch, setIsPasswordValid, setPassword }) {
    const [cPassword, setCPassword] = useState<string>("");

    const [doesPassReq, setDoesPassReq] = useState<boolean>(false);
    const [doPasswordsMatch, setDoPasswordsMatch] = useState<boolean>(false);

    const pwdEl = useRef<HTMLInputElement>(null);
    const cPwdEl = useRef<HTMLInputElement>(null);

    const PASSED_CLASS: string = "passed";
    const NUMBER_OF_CHARS: number = 8;


    useEffect(() => {
        setIsPasswordValid(doesPassReq && doPasswordsMatch);
    }, [doesPassReq, doPasswordsMatch]);


    const validatePassword = () => {
        const pwd: string = pwdEl?.current?.value;
        const cPwd: string = cPwdEl?.current?.value;
        
        const checkPasswordReq = (pwd: string) => {
            if(doesContainAlphabets(pwd) &&
            doesContainAlphabetsWithCase(pwd, true) && doesContainAlphabetsWithCase(pwd, false) &&
            doesContainNumbers(pwd) &&
            doesContainNumOfChars(pwd, 8)){
                return true;
            }
    
            return false;
        }
    
        setDoesPassReq(checkPasswordReq(pwd));
    
        if(checkPasswordMatch) {
            if(cPwd) setDoPasswordsMatch(doesMatch(pwd, cPwd));
        }
    }


    return(
        <>
            <div className="floating-input">
                <input ref={pwdEl} type="password" placeholder=" " required
                onChange={(e) => setPassword(e.target.value.trim())}
                onInput={validatePassword} />

                <label>Password</label>
            </div>

            {
                checkPasswordMatch ?
                <div className="floating-input">
                    <input ref={cPwdEl} type="password" placeholder=" " required
                    onChange={(e) => setCPassword(e.target.value.trim())}
                    onInput={validatePassword} />
    
                    <label>Confirm Password</label>
                </div>
                :
                <></>
            }

            <ul className="password-requirements" id="password-requirements">
                <li className={doesPassReq || doesContainAlphabets(pwdEl?.current?.value) ? PASSED_CLASS : ''} id="alph-req"><TickSVG />Password must contain alphabets</li>
                <li className={doesPassReq || doesContainAlphabetsWithCase(pwdEl?.current?.value, true) ? PASSED_CLASS : ''} id="alph-case-req"><TickSVG />Password must contain uppercase & lowercase characters</li>
                <li className={doesPassReq || doesContainNumbers(pwdEl?.current?.value) ? PASSED_CLASS : ''} id="num-req"><TickSVG />Password must contain numbers</li>
                <li className={doesPassReq || doesContainNumOfChars(pwdEl?.current?.value, NUMBER_OF_CHARS) ? PASSED_CLASS : ''} id="char-req"><TickSVG />Password must be at least 8 characters</li>
                {
                    checkPasswordMatch ? <li className={doPasswordsMatch ? PASSED_CLASS : ''} id="match-req"><TickSVG />Passwords must match</li> : <></>
                }
            </ul>
        </>
    )
}


//Password validation
/*export const validatePassword = (pwd: string, cPwd: string, checkPasswordMatch: boolean, setDoesPassReqFunc: Function, setDoPasswordsMatchFunc: Function) => {
    const checkPasswordReq = (pwd: string) => {
        var isValid = false;

        if(doesContainAlphabets(pwd) &&
        doesContainAlphabetsWithCase(pwd, true) && doesContainAlphabetsWithCase(pwd, false) &&
        doesContainNumbers(pwd) &&
        doesContainNumOfChars(pwd, 8)){
            isValid = true;

            $('#password-requirements > li').removeClass().addClass('passed');
        }
        else{
            doesContainAlphabets(pwd) ? $('#alph-req').removeClass().addClass('passed') : $('#alph-req').removeClass();
            doesContainAlphabetsWithCase(pwd, true) && doesContainAlphabetsWithCase(pwd, false) ? $('#alph-case-req').removeClass().addClass('passed') : $('#alph-case-req').removeClass();
            doesContainNumbers(pwd) ? $('#num-req').removeClass().addClass('passed') : $('#num-req').removeClass();
            doesContainNumOfChars(pwd, 8) ? $('#char-req').removeClass().addClass('passed') : $('#char-req').removeClass();
        }

        return isValid;
    }

    setDoesPassReqFunc(checkPasswordReq(pwd));

    if(checkPasswordMatch) {
        if(cPwd) setDoPasswordsMatchFunc(doesMatch(pwd, cPwd));
    }
}*/