import PropTypes from 'prop-types';

function DiscountInput({ value, onChange }) {


  return (
   
    <div className="discount">
         <div className="discount-input">
      <p>Discount:</p>
      <div className="discount-control">
      {
        /*    <button className="decrement-button" onClick={handleDecrement}>
          <RemoveCircleOutlineIcon />
        </button>
       */
      }  <input
          type="number"
          className="discount-field"
          value={value}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      {
        /*
          <button className="increment-button" onClick={handleIncrement}>
          <AddCircleOutlineIcon />
        </button>
        */
      }
      </div>
    </div>
    </div>
  );
}

DiscountInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DiscountInput;
