import React, { useState } from 'react';
import Modal from 'react-modal';

interface RefundModalProps {
  isOpen: boolean;
  onClose: () => void;
  items: any[]; // Adjust the type based on your actual data structure
  onSubmit: (quantities: { [itemId: number]: number }) => void;
  invoice:any;
}

const RefundModal: React.FC<RefundModalProps> = ({ isOpen, onClose, items, onSubmit,invoice }) => {
  const [refundQuantities, setRefundQuantities] = useState<{ [itemId: number]: number }>({});
    console.log(invoice)
  const handleQuantityChange = (itemId: number, quantity: number) => {
    setRefundQuantities((prevQuantities) => ({ ...prevQuantities, [itemId]: quantity }));
  };

  const calculateRefundAmount = (itemId: number) => {
    const quantity = refundQuantities[itemId] || 0;
    const item = items.find((item) => item.id === itemId);
    if (item) {
      return quantity * item.price      ;
    }
    return 0;
  };

  const calculateTotalRefund = () => {
    return items.reduce((total, item) => total + calculateRefundAmount(item.id), 0);
  };

  const handleRefundSubmit = () => {
    onSubmit(refundQuantities);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Refund Details" ariaHideApp={false}>
      <div className="p-4">
        <h2 className="text-lg font-bold mb-4">Refund Details</h2>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th>Product</th>
              <th>Quantity</th>
              <th>Refund Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>
                  <p>{item.product.productName}</p>
                </td>
                <td>
                  <input
                    type="number"
                    min={item.quantity}
                    max={item.quantity}
                    value={refundQuantities[item.id] || 0}
                    onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                  />
                </td>
                <td>
                  {calculateRefundAmount(item.id).toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}
                </td>
              </tr>
            ))}
            <tr>
              <td><strong>Total Refund</strong></td>
              <td></td>
              <td><strong>{calculateTotalRefund().toLocaleString('en-US', { style: 'currency', currency: 'LKR' })}</strong></td>
            </tr>
          </tbody>
        </table>
        
        <button className="bg-blue-500 text-white px-4 py-2 mt-4" onClick={handleRefundSubmit}>
          Submit Refund
        </button>
      </div>
    </Modal>
  );
};

export default RefundModal;
