
import React, { useEffect, useState } from "react";
import customaxios from "../../../../helpers/axios-helper";
import Loading_Default from "../../../../components/Loading_Defualt";
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the Favorite icon from MUI
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAuth,useAuthUser } from '../../../../contexts/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Category {
    product_groupId: any;
    group_Name: string;
    itemcount: number;
    archCount: number;
    image_url: string;
    favourite:boolean;
  }
interface Brand {
    id: string;
    brandId: any;
    brand_Name: string;
    productcount: number;
    archCount: number;
    image_url: string;
    favourite:boolean;
  }
  interface Model {
    product_ModelId: any;
    model_Name: string;
    itemcount: number;
    archCount: number;
    image_url: string;  favourite:boolean;
  }
    interface Brand_select_Props {
      isOpen: boolean;
      closeModal: () => void;
  
      selectedCollection_Id: string | null;
      setselectedCollection_Name: (category: string | null) => void;
      setselectedCollection_Type: (category: string | null) => void;
      setselectedCollection_Id: (category: string | null) => void;
    }


export default function Favourites(props:Brand_select_Props) {
    const [loading, setLoading] = useState<boolean>(true); // Add loading state
    const [brandsList, setBrandsList] = useState<Brand[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<string | null>(props.selectedCollection_Id);
  const auth = useAuthUser();
    
    console.log('From main ',selectedBrand)
    const [searchTerm, setSearchTerm] = useState<string>("");
    console.log(props)
const filteredBrands = brandsList.filter((brand) => {
  const brandName = brand.brand_Name;
  if (brandName && typeof brandName === 'string' && brand.favourite === true) {
    return brandName.toLowerCase().includes(searchTerm.toLowerCase());
  }
  return false;
});


   
  const fetchData = () => {
    customaxios.get("product/Getproductbrands").then((response) => {
      setBrandsList(response.data);
      if (brandsList.length > 0) {
        setSelectedBrand(brandsList[0].brandId);
        console.log("default brand",brandsList[0].brandId)
      }
      setLoading(false);
      const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
      localStorage.setItem("brandsList", JSON.stringify(data));
    });
  };


  useEffect(() => {
   
    /*const storedData = localStorage.getItem("brandsList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setBrandsList(data);
        if (brandsList.length > 0) {
          setSelectedBrand(brandsList[0].brandId);
        }
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("brandsList");
      }
    }
    */
    fetchData();
  }, []);
  

  function clearLocalStorage() {
    localStorage.removeItem("brandsList");
    setBrandsList([]);
    setLoading(true);
    fetchData();
  }

  const handleBrandClick = (brandId: string) => {
    console.log(brandId);
    setSelectedBrand(brandId);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const handle_select = (Brand:Brand)=>{
    
    console.log(Brand)
    setSelectedBrand(Brand.brandId)
    props.setselectedCollection_Name(Brand.brand_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_ByBrand_paged")
    props.setselectedCollection_Id(Brand.brandId)
    console.log('selected ', Brand)
    props.closeModal();

  }



  const set_favourite_brand = (brand: Brand) => {
    if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
    // Handle the double click action here
    console.log('Double click on ', brand.brandId);
  
    customaxios
      .post("Brand/Brand_Favourite?BrandId="+ brand.brandId 
      )
      .then((response) => {
        console.log(response);
        fetchData();
      })
      .catch((error) => {
        console.error("Error during double click action:", error);
      });
    }
  };







    const [categoriesList, setCategoriesList] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(props.selectedCollection_Id);
    const filteredCategories = categoriesList.filter((Category) => {
        const categoryName = Category.group_Name;
        if (categoryName && typeof categoryName === 'string' && Category.favourite === true) {
          return categoryName.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });
         useEffect(() => {
        /*   const storedData = localStorage.getItem("categoriesList");
        if (storedData) {
          const { data, expiry } = JSON.parse(storedData);
          if (Date.now() < expiry) {
            setCategoriesList(data);
            if (categoriesList.length > 0) {
              setSelectedCategory(categoriesList[0].product_groupId);
            }
            setLoading(false);
            return;
          } else {
            localStorage.removeItem("categoriesList");
          }
        }
        */
    
        customaxios.get("product/getproductgroups").then((response) => {
          setCategoriesList(response.data);
         
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("categoriesList", JSON.stringify(data));
        });
      }, []);

      
  
  const handle_select_category = (Category)=>{
    setSelectedCategory(Category.product_groupId)
    props.setselectedCollection_Name(Category.group_Name)
    props.setselectedCollection_Type("GetProductsByProductArch_paged")
    props.setselectedCollection_Id(Category.product_groupId)
    console.log('selected ', Category)
  }

      const fetchData_category = () => {
        customaxios.get("product/getproductgroups").then((response) => {
          setCategoriesList(response.data);
          
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("categoriesList", JSON.stringify(data));
        });
      };

      const set_favourite_Category = (Category: Category) => {
        if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
        // Handle the double click action here
        console.log('Double click on ', Category.product_groupId);
      
        customaxios
          .post("Category/Category_Favourite?BrandId="+ Category.product_groupId 
          )
          .then((response) => {
            console.log(response);
            fetchData();
          })
          .catch((error) => {
            console.error("Error during double click action:", error);
          });
        }
      };







      const [ModelsList, setModelsList] = useState<Model[]>([]);
      const [selectedCategory_models, setselectedCategory_models] = useState<string | null>(props.selectedCollection_Id);





      const filteredCategories_models = ModelsList.filter((Model) => {
        const modelName = Model.model_Name;
        if (modelName && typeof modelName === 'string' && Model.favourite === true) {
          return modelName.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });
      



      useEffect(() => {
         /*const storedData = localStorage.getItem("ModelsList");
    if (storedData) {
      const { data, expiry } = JSON.parse(storedData);
      if (Date.now() < expiry) {
        setCategoriesList(data);
        if (ModelsList.length > 0) {
          setSelectedCategory(ModelsList[0].product_ModelId);
        }
        setLoading(false);
        return;
      } else {
        localStorage.removeItem("ModelsList");
      }
    }*/
        customaxios.get("ProductModel/GetProducts_Models").then((response) => {
            setModelsList(response.data);
         
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("ModelsList", JSON.stringify(data));
        });
      }, []);
    
    
 
    

    
      const fetchData_model = () => {
        customaxios.get("ProductModel/GetProducts_Models").then((response) => {
            setModelsList(response.data);
          
          setLoading(false);
          const data = { data: response.data, expiry: Date.now() + 6 * 60 * 60 * 1000 };
          localStorage.setItem("ModelsList", JSON.stringify(data));
        });
      };
    
      
      const handle_select_model = (Model:Model)=>{
        setselectedCategory_models(Model.product_ModelId)
        props.setselectedCollection_Name(Model.model_Name)
        props.setselectedCollection_Type("GetProductsByProductArch_ByModel_paged")
        props.setselectedCollection_Id(Model.product_ModelId)
        console.log('selected ', Model)
      }
      const set_favourite_model = (brand: Model) => {
        if(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") {
     // Handle the double click action here
     console.log('Double click on ', brand.product_ModelId);
      
     customaxios
       .post("ProductModel/Model_Favourite?id="+ brand.product_ModelId 
       )
       .then((response) => {
         console.log(response);
         fetchData_model();
       })
       .catch((error) => {
         console.error("Error during double click action:", error);
       });
        }
        
       
      };

     
    return(
    <>
      <div className="CategoriesList">
     
        
        {loading ? (
          <Loading_Default />
        ) : (
        <>
  {/*
            Brands
            */}
<table  className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
   
   <tr>
            <th className="px-6 py-3">Logo</th>
            <th className="px-6 py-3">Name</th>
            <th className="px-6 py-3">No of Items</th>
            <th className="px-6 py-3">No of Archs</th>
            <th className="px-6 py-3">Fav</th>
   </tr>
          </thead>
        
        
        <tbody>
        <tr>
              <td>Brands</td>
            </tr>
          {filteredBrands.map((brand) => (
            <tr      key={brand.brandId} className="bg-white border-b dark:bg-gray-800 dark:border-red-500 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td>  {brand.image_url && (
                 
                    <img src={brand.image_url} style={{ maxWidth: 50 }}  alt="" />
                )} </td>

                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select(brand);props.closeModal();   }}>
                {brand.brand_Name}
                </td>
                <td>
                {brand.productcount}
                </td>
                <td>
                {brand.archCount}
                </td>
                <td>
                {brand.favourite === true ? (<IconButton
            onClick={() => set_favourite_brand(brand)}
            color="secondary" // Change color as needed
          >
            <FavoriteIcon />
          </IconButton>):(<IconButton onClick={() => set_favourite_brand(brand)}>
            <FavoriteBorderIcon />
          </IconButton>)}
                </td>
            </tr>
               
          
            ))}

            <tr>
              <td>Categories</td>
            </tr>
            {filteredCategories.map((Category) => (
            <tr           key={Category.product_groupId} className="bg-white border-b dark:bg-gray-800 dark:border-blue-500 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td>  {Category.image_url && (
                 
                    <img src={Category.image_url} style={{ maxWidth: 50 }}  alt="" />
                )} </td>

                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select_category(Category); props.closeModal();  }}>
                {Category.group_Name}
                </td>
                <td>
                {Category.itemcount}
                </td>
                <td>
                {Category.archCount}
                </td>
                <td>
                {Category.favourite === true ? (<IconButton
            onClick={() => set_favourite_Category(Category)}
            color="secondary" // Change color as needed
          >
            <FavoriteIcon />
          </IconButton>):(<IconButton onClick={() => set_favourite_Category(Category)}>
            <FavoriteBorderIcon />
          </IconButton>)}
                </td>
            </tr>
               
          
            ))}



<tr>
              <td>Models</td>
            </tr>
          {filteredCategories_models.map((Model) => (
            <tr              key={Model.product_ModelId} className="bg-white border-b dark:bg-gray-800 dark:border-green-500 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td>  {Model.image_url && (
                 
                    <img src={Model.image_url} style={{ maxWidth: 50 }}  alt="" />
                )} </td>

                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white" onClick={() => {handle_select_model(Model); props.closeModal();}}>
                {Model.model_Name}
                </td>
                <td>
                {Model.itemcount}
                </td>
                <td>
                {Model.archCount}
                </td>
                <td>
                {Model.favourite === true ? (<IconButton
            onClick={() => set_favourite_model(Model)}
            color="secondary" // Change color as needed
          >
            <FavoriteIcon />
          </IconButton>):(<IconButton onClick={() => set_favourite_model(Model)}>
            <FavoriteBorderIcon />
          </IconButton>)}
                </td>
            </tr>
               
          
            ))}

        </tbody>
        </table>
        

            
       
        </>
        )}
      </div>

   

        </>
        )
}