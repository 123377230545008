import React, { useEffect, useState,useMemo,useDebugValue,useCallback   } from "react";
import customaxios from '../../helpers/axios-helper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Checkbox, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PaymentForm from "./PaymentForm";
import { Cheque } from "./Cheques/ChequeForm";
import ChequeForm from './Cheques/ChequeForm';
import ChequeList from './Cheques/ChequeList';
import '../Style_Customer/Style.css';
import { IconButton } from '@material-ui/core';
import { LockOpen } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
interface AddPaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  invoices: any[];
}

const AddPaymentModal: React.FC<AddPaymentModalProps> = ({ isOpen, onClose, invoices }) => {
  const [paymentMode, setPaymentMode] = useState<string>("Cheque");
  const [amount, setAmount] = useState<number>(0);
  const [chequeNumber, setChequeNumber] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [paymentStatus, setPaymentStatus] = useState<string>("Pending");
  const [chequeDate, setChequeDate] = useState<Date | null>(new Date());
  const [paymentAllocations, setPaymentAllocations] = useState<PaymentAllocation[]>([]);
  const [cheques, setCheques] = useState<Cheque[]>([]);

  const handleAddCheque = (cheque: Cheque) => {
    setCheques((prevCheques) => [...prevCheques, cheque]);
  };
  
  const handleRemoveCheque = (index: number) => {
    setCheques((prevCheques) => prevCheques.filter((_, i) => i !== index));
  };
  const handlePaymentModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMode(event.target.value);
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(event.target.value));
  };

  const handleChequeNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChequeNumber(event.target.value);
  };

  const handleBankNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
console.log("paymentAllocations",paymentAllocations);
    try {
      // create an array of paymentInvoices objects with selected invoice details
      const paymentInvoices = invoices.map((invoice) => ({
        invoiceId: invoice.invoiceId,
        invoiceValue: invoice.invoiceValue,
        invoiceValueWithTax: invoice.invoiceValueWithTax,
        paidAmount: amount,
        paymentDue: invoice.invoiceValueWithTax - amount,
        dueDate: invoice.dueDate,
        taxBill: invoice.taxBill,
        paymentType: paymentMode,
        creditDays: invoice.creditDays,
        createdDateTime: new Date()
      }));
      const response = await customaxios.post(`Payment/CreatePayment`, {
        customer: invoices[0].customer,
        paymentMode: paymentMode,
        amount: amount,
        chequeNumber: chequeNumber,
        bankName: bankName,
        paymentDate: new Date(),
        paymentStatus: "Pending",
        cheque_date: chequeDate,
        invoices: [],
        paymentInvoices: paymentAllocations,
        Cheques:cheques
      });
      console.log("Cheques",cheques);

      console.log(response.data);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  if (!isOpen) {
    return null;
  }

  const handleCloseModal = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };


  return (
    <div className="modal-overlay _add_Payment_selected_invoices" onClick={handleCloseModal}>
      <div className="modal ">
 <div className="header_title" >
 <h3>Add Payment</h3>
 </div>
    
     <div className="Selected_invoices">
     <InvoiceTable 
        invoices={invoices}
        paymentamount={amount}
        paymentAllocations_={paymentAllocations}
        setPaymentAllocations_={setPaymentAllocations}
      />
     </div>
   <div className="ADdPaymentinfo" >
   <form onSubmit={handleSubmit} >
  <div>
<div className="base">
<label htmlFor="payment-mode">Payment Mode</label>
    <select id="payment-mode" value={paymentMode} onChange={handlePaymentModeChange}>
      <option value="Cheque">Cheque</option>
      <option value="Cash">Cash</option>
      <option value="Online Transfer">Online Transfer</option>
    </select>
    <div>
    <label htmlFor="amount">Amount</label>
    <input type="number" id="amount" 
           value={amount}
           onChange={handleAmountChange}
           step="0.01"
           min="0" />

<button className="button_one" type="submit">Submit</button>

  </div>

  </div>

{
  /*
    <label htmlFor="status">Status</label>
    <select id="status" value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
      <option value="received">Received</option>
      <option value="on hold">On Hold</option>
      <option value="pending">Pending</option>
      <option value="realized">Realized</option>
    </select>
  */
}

</div>
<div className="cash">

</div>
 
  <div>
  
  </div>
    </form>
    {paymentMode === 'Cheque' && (
    <ChequeForm onAddCheque={handleAddCheque} />
    )}


   </div>


<ChequeList cheques={cheques}  onRemoveCheque={handleRemoveCheque} />



 
      </div>
    </div>
  );
  
  
}

export default AddPaymentModal;

interface InvoiceTableProps {
  invoices: any[];
  paymentamount: number;
  paymentAllocations_: PaymentAllocation[];
  setPaymentAllocations_: React.Dispatch<React.SetStateAction<PaymentAllocation[]>>;

}



interface InvoiceTableProps {
  invoices: any[];
  paymentamount: number;
  paymentAllocations_: PaymentAllocation[];
  setPaymentAllocations_: React.Dispatch<React.SetStateAction<PaymentAllocation[]>>;
}

interface PaymentAllocation {
  invoiceId: string;
  Amount: number;
  manuallyAdjusted: boolean;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({
  invoices,
  paymentamount,
  paymentAllocations_,
  setPaymentAllocations_,
}) => {
  const [paymentAllocations, setPaymentAllocations] = useState<PaymentAllocation[]>(paymentAllocations_);

  const handleAllocationChange = useCallback((invoiceId: string, amount: number) => {
    const otherAllocations = paymentAllocations.filter(allocation => allocation.invoiceId !== invoiceId);
    const updatedAllocation: PaymentAllocation = {
      invoiceId,
      Amount: amount,
      manuallyAdjusted: true,
    };

    const lockedAllocations = [...otherAllocations, updatedAllocation].filter(allocation => allocation.manuallyAdjusted);
    const unlockedAllocations = [...otherAllocations, updatedAllocation].filter(allocation => !allocation.manuallyAdjusted);

    const totalLockedAmount = lockedAllocations.reduce((sum, allocation) => sum + allocation.Amount, 0);
    const unlockedItemCount = unlockedAllocations.length;
    const remainingAmount = paymentamount - totalLockedAmount;
    const unlockedAmountPerItem = Math.floor(remainingAmount / unlockedItemCount);
  
    const newAllocations = [...lockedAllocations, ...unlockedAllocations.map(allocation => ({
      ...allocation,
      Amount: unlockedAmountPerItem,
    }))];

    setPaymentAllocations(newAllocations);
  }, [paymentAllocations, paymentamount]);

  const unlockAllocation = useCallback((invoiceId: string) => {
    const updatedAllocations = paymentAllocations.map(allocation => {
      if (allocation.invoiceId === invoiceId) {
        return {
          ...allocation,
          manuallyAdjusted: false,
        };
      }
      return allocation;
    });

    setPaymentAllocations(updatedAllocations);
  }, [paymentAllocations]);

  const allocatePaymentAmount = useCallback(() => {
    const amount = Math.floor(paymentamount / invoices.length);
    const newAllocations = invoices.map(invoice => ({
      invoiceId: invoice.invoiceId,
      Amount: amount,
      manuallyAdjusted: false,
    }));
    setPaymentAllocations(newAllocations);
  }, [invoices, paymentamount]);

  useEffect(() => {
    setPaymentAllocations_(paymentAllocations);
  }, [paymentAllocations]);

  useEffect(() => {
    if (paymentamount > 0) {
      allocatePaymentAmount();
    }
  }, [paymentamount, allocatePaymentAmount]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Payment Due</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Payment Allocation
                <IconButton onClick={() => unlockAllocation('unlock-all')}>
                  <LockOpen />
                </IconButton>
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices
            .sort((a, b) => {
              const allocationA = paymentAllocations.find(allocation => allocation.invoiceId === a.invoiceId)?.Amount || 0;
              const allocationB = paymentAllocations.find(allocation => allocation.invoiceId === b.invoiceId)?.Amount || 0;
              return allocationB - allocationA;
            })
            .map(invoice => {
              const allocation = paymentAllocations.find(a => a.invoiceId === invoice.invoiceId);
              const allocatedValue = allocation ? allocation.Amount : 0;
              return (
                <InvoiceTableRow
                  key={invoice.invoiceId}
                  invoice={invoice}
                  allocatedValue={allocatedValue}
                  onAllocationChange={handleAllocationChange}
                  unlockAllocation={unlockAllocation}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


interface InvoiceTableRowProps {
  invoice: any;
  allocatedValue: number;
  onAllocationChange: (invoiceId: string, amount: number) => void;
  unlockAllocation: (invoiceId: string) => void;
}

const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({ invoice, allocatedValue, onAllocationChange, unlockAllocation }) => {
  const [inputValue, setInputValue] = useState(allocatedValue);
  const [isLocked, setIsLocked] = useState(true); // Set initial state to locked

  useEffect(() => {
    setInputValue(allocatedValue);
    setIsLocked(allocatedValue !== 0);
  }, [allocatedValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
      setInputValue(parseFloat(e.target.value));
    }
  };

  const handleInputBlur = () => {
    if (!isLocked) {
      onAllocationChange(invoice.invoiceId, inputValue);
    }
  };

  const toggleLock = () => {
    setIsLocked(prevIsLocked => !prevIsLocked);
  };

  return (
    <TableRow key={invoice.invoiceId}>
      <TableCell>{invoice.invoice_Id_Simplified}</TableCell>
      <TableCell>{invoice.dueDate ? invoice.dueDate.toLocaleDateString() : ''}</TableCell>
      <TableCell>{invoice.paymentDue}</TableCell>
      <TableCell>{invoice.invoiceValue}</TableCell>
      <TableCell>{invoice.paymentType === 'credit' ? `${invoice.creditDays} days` : invoice.paymentType}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="number"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            disabled={isLocked} // Disable the input field when locked
          />
          {isLocked ? (
            <IconButton onClick={toggleLock}>
              <LockIcon />
            </IconButton>
          ) : (
            <IconButton onClick={toggleLock}>
              <LockOpenIcon />
            </IconButton>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};