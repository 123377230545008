import ListDirectors_C_A from "./ListDirectors_C_A";
import CreateDirectors_C_A from "./CreateDirectors_C_A";
export default function  Directors_C_A (){
    
    return(
    <>
   
      <CreateDirectors_C_A/>
        <br />
        <ListDirectors_C_A/>
    </>)
}