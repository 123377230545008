import React, { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import customaxios from "../helpers/axios-helper";
import Search from "../components/Product/Search/Search";

const ProductArch = lazy(() => import("../components/Product/Arch/ProductArch"));

interface RouteParams {
  id: string;
  [key: string]: string;
}

export default function ProductListByBrandArch() {
  const { id } = useParams<RouteParams>();

  const [brand, setBrand] = useState<any>({});
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    customaxios
      .post("Brand/getbrandbyid", {
        Brandid: id,
      })
      .then((response) => {
        const { brand_Name, productcount, archCount } = response.data;
        setBrand({ brand_Name, productcount, archCount });
        document.title = brand_Name;
      })
      .catch((error) => {
        console.log(error);
      });

    customaxios
      .post("product/getproductsbyBrandsproductarch", {
        Brandid: id,
      })
      .then((response) => {
        setProductList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Clean up function to reset the title if needed
    return () => {
      document.title = "Your Default Title"; // Change this line to reset the title to your preferred default value.
    };
  }, [id]);

  return (
    <div className="groupProductview">
        <Search/>
      <div className="CategoryInformation">
        <p className="Name">{brand.brand_Name}</p>
        <p className="itemcount">Total Products: {brand.productcount}</p>
        <p>{"Arch Count :" + brand.archCount}</p>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {productList.map((row, index) => (
            <ProductArch key={row.productArch.productArchId} products={row.products} arch={row.productArch}/>
        ))}

      </Suspense>
    </div>
  );
}
