import React, { useState, useEffect, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import { useAuth,useAuthUser } from '../../contexts/auth-context';
import { toast } from 'react-toastify';

export default function EditProduct_Brand({ product }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const [brandName, setBrandName] = useState(product.brand.brand_Name);
  const auth = useAuthUser();

  const modalRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      console.log("brand call");
      // Make API call to fetch related items here
      customaxios
        .post("/brand/getbrand", {
          keyword: brandName,
        })
        .then((response) => {
          setRelatedItems(response.data);
          console.log("brand");
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isEditing, brandName]);

  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/product/update_brand", {
        brandName: brandName,
        Product: product.productId,
      })
      .then((response) => {
        console.log("brand");
        console.log(response.data);
        console.log("set false");

        setIsEditing(false);
        toast.success('Brand updated');

        product.brand.brand_Name = brandName;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setBrandName(product.brand.brand_Name);
  };

  const handleBrandNameChange = (event) => {
    setBrandName(event.target.value);
  };

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
{isModalOpen && (
        <div className="modal_brand" onClick={handleClose} ref={modalRef}>
          <div className="modal_brand-content">
            {isEditing ? (
              <div className="brand_Name">
                <h2>Update Brand</h2>
                <input
                  type="text"
                  value={brandName}
                  onChange={handleBrandNameChange}
                />
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={handleCancelEditing}>Cancel</button>
              </div>
            ) : (
              <>
                
                {(auth?.role === "CEO" || auth?.role === "Director" || auth?.role === "Developer") ? (
   <>
      <h4>Update Brand</h4>
      <p onClick={() => setIsEditing(true)}>Click here to edit {brandName}</p>
   </>
) : (
   <>No Auth to access this</>
)}

              </>
            )}
            {isEditing && relatedItems.length > 0 && (
              <ul className="productbrandselectlist">
                {relatedItems.map((item) => (
                  <li
                    className="productbrandselect"
                    key={item.brandId}
                    onClick={() => setBrandName(item.brand_Name)}
                  >
                    {item.brand_Name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
      <p onClick={() => setIsModalOpen(true)}>: {product.brand.brand_Name}</p>
   

    </>
  );
}
