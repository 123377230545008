import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { toast } from 'react-toastify';
import { useAuthUser } from '../../contexts/auth-context';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import customaxios from "../../helpers/axios-helper";
import Loading_Defualt from "../../components/Loading_Defualt";
interface ReturnItem {
    id: number;
    Product: any;
    Quantity: number;
    UnitPrice: number;
    Total_Amount: number;
    Reason:string;
    CustomReason:string;
}

export default function Profit_Loss_Transaction_Report({ Billing_Group }) {
    const AuthUser = useAuthUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Sales, setSales] = useState<any[]>([]);
    const [Returns, setReturns] = useState<any[]>([]);

    const [loading, setLoading] = useState<boolean>(true); // Loading state

    const handleViewProfit_Loss_Transaction_Report = () => {
        const allowedRoles = ['Developer', 'CEO', 'Director'];
        if (allowedRoles.includes(AuthUser.role)) {
            setIsModalOpen(true);
        } else {
            toast.error("You don't have permission to view history");
        }
    };

    useEffect(() => {
        fetchExpenses();
    }, [Billing_Group]);

    const fetchExpenses = async () => {
        try {
            const response = await customaxios.get("companysales/Profit_Loss_Company_Details?billingGroup="+Billing_Group);
            setSales(response.data.sales);    
            setReturns(response.data.totalReturns);    
            setLoading(false); // Set loading to false after data is fetched
  
        } catch (error) {
            console.error("Error fetching profit_loss:", error);
            setLoading(false); // Set loading to false if there's an error
   
            // Handle errors
        }
    };
    const tabsData = [
        { label: "Sales", component: <Sales_Transactions Sales={Sales}/> },
        { label: "Returns", component: <Return_Transactions Returns={Returns}/> },
      
        
      ];
      const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

      const handleTabChange = (event, newValue) => {
        setSelectedItem(newValue);
      };
    return (
        <>
            <button onClick={handleViewProfit_Loss_Transaction_Report}>{Billing_Group ? Billing_Group : "Unknown"}</button>

            <Modal
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
            >
                <Box sx={{
                    position: "absolute",
                    overflow: "auto",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80vw", height: "80vh", maxHeight: "900px", bgcolor: "background.paper",
                    boxShadow: 24, p: 4
                }}>

<Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
             
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
{loading && (<Loading_Defualt/>)}

                 {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}

                </Box>
            </Modal>
        </>
    );
}

function Sales_Transactions ({Sales})
{
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    const toggleRowExpansion = (index: number) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };
    return(
        <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Type</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Customer</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Profitability</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Payment Mode</th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            {Sales.map((Sale, index) => (
                <React.Fragment key={Sale.invoiceId}>
                    <tr className="hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer" onClick={() => toggleRowExpansion(index)}>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.createdDateTime.toLocaleString('en-US', { month: 'short', day: 'numeric' })}</td>
                        <td className="px-6 py-4 whitespace-nowrap">Sale</td>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.customer?.customerName}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
{/* Calculate and display total profitability for the sale */}
{Sale.orderItems.reduce((totalProfit, Item) => {
    const itemProfitability = (Item.total_Amount - (Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice : 0) * Item.quantity);
    return totalProfit + itemProfitability;
}, 0).toFixed(2).toLocaleString({ style: 'currency', currency: 'LKR' })}
</td>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.paymentType}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            {expandedRows.includes(index) ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 12l-8-8 1.5-1.5L10 9.99 16.5 2.5 18 4l-8 8z" clipRule="evenodd" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 12l-8-8 1.5-1.5L10 9.99 16.5 2.5 18 4l-8 8z" clipRule="evenodd" transform="rotate(90 10 10)" />
                                </svg>
                            )}
                        </td>
                    </tr>
                    {expandedRows.includes(index) && (
                        <>
                        <tr>
                            <th>
                                Product
                            </th>
                            <th>
                                Total Amount 
                            </th>
                            
                            <th>
                              Last Purchase Price 
                            </th>
                            <th>
                           
                                QTY  
                            </th>
                           

                            <th>
                                Gross 
                            </th>
                        </tr>
                     {
                           Sale.orderItems.map((Item, i) => (
                            <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer">
                                <td className="whitespace-nowrap"><p className="font-semibold text-black-900">
                                    {Item.product.itemdetails}</p>
<p className="font-light text-gray-500 dark:text-gray-200 ">
{Item.product.oaM_ACode}{" - "}
{Item.product.suppliercode && Item.product.suppliercode.trim() !== "" ? Item.product.suppliercode.trim() : "Supplier Code"}

</p></td>

<td>
{Item.total_Amount.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) }
</td>


<td>
{(Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) : 0)}

</td>
<td>

{(Item.quantity)}
</td>
<td className=" whitespace-nowrap">
{((Item.total_Amount - (Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice : 0) * Item.quantity).toFixed(2))}
</td>
</tr>
                        ))
                     }   
                        </>
                     
                    )}
                </React.Fragment>
            ))}
        </tbody>
    </table>
    )
}

function Return_Transactions ({Returns})
{
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    const toggleRowExpansion = (index: number) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };
    return(
        <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 dark:bg-gray-800">
            <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Type</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Customer</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Return Value</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Payment Mode</th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            {Returns.map((Sale, index) => (
                <React.Fragment key={Sale.invoiceId}>
                    <tr className="hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer" onClick={() => toggleRowExpansion(index)}>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.createdDate.toLocaleString('en-US', { month: 'short', day: 'numeric' })}</td>
                        <td className="px-6 py-4 whitespace-nowrap">Return</td>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.customer?.customerName}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
{/* Calculate and display total profitability for the sale */}
{Sale.return_Items.reduce((totalProfit, Item) => {
    const itemProfitability = ((Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice : 0) * Item.quantity);
    return totalProfit + itemProfitability;
}, 0).toFixed(2).toLocaleString({ style: 'currency', currency: 'LKR' })}
</td>
                        <td className="px-6 py-4 whitespace-nowrap">{Sale.paymentType}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            {expandedRows.includes(index) ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 12l-8-8 1.5-1.5L10 9.99 16.5 2.5 18 4l-8 8z" clipRule="evenodd" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 12l-8-8 1.5-1.5L10 9.99 16.5 2.5 18 4l-8 8z" clipRule="evenodd" transform="rotate(90 10 10)" />
                                </svg>
                            )}
                        </td>
                    </tr>
                    {expandedRows.includes(index) && (
                        <>
                        <tr>
                            <th>
                                Product
                            </th>
                            <th>
                                Billed Amount 
                            </th>
                            
                            <th>
                              Last Purchase Price 
                            </th>
                            <th>
                           
                                QTY  
                            </th>
                           

                            <th>
                                RTN Value 
                            </th>
                        </tr>
                     {
                           Sale.return_Items.map((Item, i) => (
                            <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer">
                                <td className=" whitespace-nowrap"><p className="font-semibold text-black-900">
                                    {Item.product.itemdetails}</p>
<p className="font-light text-gray-500 dark:text-gray-200 ">
{Item.product.oaM_ACode}{" - "}
{Item.product.suppliercode && Item.product.suppliercode.trim() !== "" ? Item.product.suppliercode.trim() : "Supplier Code"}

</p></td>

<td>
{Item.total_Amount.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) }
</td>


<td>
{(Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice.toLocaleString('en-US', { style: 'currency', currency: 'LKR' }) : 0)}

</td>
<td>

{(Item.quantity)}
</td>
<td className="  whitespace-nowrap">
{(( (Item.product.productPrice.last_PurchasePrice ? Item.product.productPrice.last_PurchasePrice : 0) * Item.quantity).toFixed(2))}
</td>
</tr>
                        ))
                     }   
                        </>
                     
                    )}
                </React.Fragment>
            ))}
        </tbody>
    </table>
    )
}