
import React, { useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "./Style/Style.css"
import Prepayments from "./Prepayments/Prepayments";
import StaffLoan from "./StaffLoan/StaffLoan";
import Salary_Advance from "./Salary_Advance/Salary_Advance";
const tabsData = [
    { label: "-", component: <></> },
    { label: "Pre Payments", component: <Prepayments/> },
    { label: "Salary Advance", component: <Salary_Advance/> },
    { label: "Staff Loan", component: <StaffLoan/> },
  ];
  
export default function Deposit_Advance_Prepayments(){
    
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);
    const handleTabChange = (event, newValue) => {
        setSelectedItem(newValue);
      };
    return(
        <>
        <div className="">
          <div className="">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={selectedItem}
              onChange={handleTabChange}
              className="tabs_Deposit_Advance_Prepayments"
            >
              {tabsData.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.label}
                  className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
                />
              ))}
            </Tabs>
          </div>
  <br />
          <div className="">
            {tabsData.map((tab, index) => (
              <React.Fragment key={index}>
                {selectedItem === tab.label && tab.component}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    )
}