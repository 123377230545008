import{ useState, useEffect, useRef } from "react";
import customaxios from "../../helpers/axios-helper";
import {useSelector} from "react-redux";
import { RootState } from "../../Globals/GlobalInterface";
import { toast } from 'react-toastify';
import { Tabs, Tab, Box } from '@mui/material';
import ImageRow  from "../Images/ImageRow";
import ProductRow from "./Arch/productrow";
import {  Link } from "react-router-dom";
import {  useAuthUser } from "../../contexts/auth-context";
import { useTheme } from '@mui/material/styles';
import "./Arch_Menu_Style/Arch_Menu_Style.css"




export default function Product_Arch_menu({ products,arch }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const [categoryname, setcategoryname] = useState(products.length > 0 ? products[0].product_Group?.group_Name : '');
  const [activeTab, setActiveTab] = useState(0);
  const _globalstate = useSelector((state:RootState) => state);
  const addtocart =true;
  const filteredProducts = products.sort((a, b) => b.productQuantity.quantity - a.productQuantity.quantity);
  const filteredlockeditems = filteredProducts.sort((a, b) =>a.unmoveditem - b.unmoveditem);
  const [titleText, setTitleText] = useState(arch.productName); // Add state variable to keep track of title text
  const [selected_Product, setselected_Product] = useState<any>({});

  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange_brand_Tab = (event, newValue) => {
    setSelectedTab(newValue);

    // Update the selected product when a tab is selected
    setselected_Product(filteredlockeditems[newValue]);
  };
  const AuthUser = useAuthUser();
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const modalRef = useRef(null);
  const theme = useTheme();
  useEffect(() => {
    if (isEditing) {
     // console.log("Category call");
      // Make API call to fetch related items here
      customaxios
      .post("/Category/getCategories", {
          categoryname: categoryname,
        })
        .then((response) => {
          setRelatedItems(response.data);
         // console.log("setcategory");
   //       console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isEditing, categoryname]);

  const handleSaveChanges = () => {
    // Make API call to save changes here
    customaxios
      .post("/ProductArch/update_arch_Category", {
        Group_Name: categoryname,
        Id: arch.productArchId,
      })
      .then((response) => {
      //  console.log("setcategory");
      //  console.log(response.data);
      //  console.log("set false");
        toast.success('Category updated');
        setIsEditing(false);

       // product.product_Group.group_Name = categoryname;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (filteredProducts && filteredProducts.length > 0) {
      setselected_Product(filteredProducts[0]);
    }
  //  console.log(selected_Product)
  }, [filteredProducts]);
  
  const handleCancelEditing = () => {
    setIsEditing(false);
    setcategoryname(products[0].product_Group.group_Name);
  };

  const handleBrandNameChange = (event) => {
    setcategoryname(event.target.value);
  };

  const handleClose = (event) => {
    if (modalRef.current === event.target) {
      setIsModalOpen(false);
    }
  };
  let stockcount = 0;

  filteredlockeditems.map((product, index) => {
    stockcount = stockcount + Number(product.productQuantity.quantity);
  });
  //console.log(filteredlockeditems)
  //console.log("selected_Product",selected_Product)

  return (
    <>
      {isModalOpen && (
        <div className="tabone">
          <div className="ArchMenu_Background" onClick={handleClose} ref={modalRef}>
            <div className="ArchMenu_content">
             <div className="info_">
              <Link to={`/Arch/${arch.productArchId}`} target="_blank" rel="noopener noreferrer">
              {arch.productName} ({arch.arcCode})
              </Link>
              <div className="images" >
      <ImageRow minfied={false} productarch={arch} />
    </div>
             </div>

              <div className="_details">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
              >
                <Tab label="Products" />
                <Tab label="Category" />
                <Tab label="Component" />
                <Tab label="Model" />
                {/* Add more tabs as needed */}
              </Tabs>
              <Box sx={{ p: 2 }}>
                {activeTab === 0 && (
                  <>
                    {filteredlockeditems.map((product, index) => {
                      if (!_globalstate.zeroqty) {
                        if (product.productQuantity.quantity <= 0) {
                          return null;
                        } else {
                          return (
                            <ProductRow
                          handleReturnItem={()=>{}}
                          addtocart={addtocart}
                              product={product}
                              index={index}
                            />
                          );
                        }
                      } else {
                        return (
                          <ProductRow
                          handleReturnItem={()=>{}}
                            addtocart={addtocart}
                            product={product}
                            index={index}
                          />
                        );
                      }
                    })}
                  </>
                )}
                {activeTab === 1 && (
                  <>
                    {
                      (AuthUser.role === "Director" || AuthUser.role === "Developer")
                      ? (
                        <div className="category_config">
                          {isEditing ? (
                            <div className="brand_Name">
                              <h2>Update Category</h2>
                              <input
                                type="text"
                                value={categoryname}
                                onChange={handleBrandNameChange}
                              />
                              <button onClick={handleSaveChanges}>Save</button>
                              <button onClick={handleCancelEditing}>Cancel</button>
                            </div>
                          ) : (
                            <>
                              <h4>Update Category</h4>
                              <p onClick={() => setIsEditing(true)}>
                                Click here to edit {categoryname}
                              </p>
                            </>
                          )}
                          {isEditing && relatedItems.length > 0 && (
                            <ul className="productbrandselectlist">
                              {relatedItems.map((item) => (
                                <li
                                  className="productbrandselect"
                                  key={item.product_groupId}
                                  onClick={() => setcategoryname(item.group_Name)}
                                >
                                  {item.group_Name}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )
                      : null
                    }</>
                  
                )}
                {activeTab === 2 && (
                  <div className="tabtwo">
                    <p>component_config</p>
                  </div>
                )}
                {activeTab === 3 && (
                  <div className="tabthree">
                    <p>Model_config</p>
                  </div>
                )}
                {/* Add more tab content sections as needed */}
              </Box>
              </div>
            </div>
          </div>
        </div>
      )}
       <div className="product_Arch">
      
        <div className="archtitlewrapper">
            <div className="title_Product">
               <p className="titlename _black" onClick={() => setIsModalOpen(true)}>{titleText}</p>
            </div>
        </div>
        {
  /*
      <div className="selected_product">
        <div className="Brand_selected">
        {selected_Product?.brand?.brand_Name || 'Brand Not Available'}
        </div>
      <div className="price_Brand_selected">
      {selected_Product?.productPrice?.price ? `${selected_Product.productPrice.price.toLocaleString()} LKR` : 'Price Not Available'}

      </div>
</div>


    <div className="brands">
    <Tabs
    value={selectedTab}
    onChange={handleChange_brand_Tab}
    variant="scrollable"
    scrollButtons="auto"
    className='ArcH_Product_Brand_Tabs'
  >
    {filteredlockeditems.map((product, index) => {
      if (!_globalstate.zeroqty || product.productQuantity.quantity > 0) {
        return (
          <Tab
            key={index}
            label={product.brand.brand_Name}
          />
        );
      }
      return null;
    })}
  </Tabs>
    </div>
    */
}

      <div onClick={() => setIsModalOpen(true)} className="images">
        { filteredlockeditems.length > 1 ? (

  <div className="prdcount">
    {filteredlockeditems.length}
  </div> 

) : null}
           {arch.archImages.length > 0 && (
             <img

               src={arch.archImages[0].url}
               alt="Product Image"
               style={{ width: "100%", height: "100%", objectFit: "contain" }}
             />
           )}
         </div>
       </div>
     
    </>
  );
  
}
