import React, { useState, useEffect, useRef } from "react";
import { brand } from '../../models/Products/brand';
import { Product } from '../../models/Products/product';
import { useAuthUser } from '../../contexts/auth-context';
import customaxios from "../../helpers/axios-helper";
import "./Style/Style.css";
import NotesList from "./Components/Product_Components/NotesList";
import {Modal, Box} from "@mui/material";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
/*interface Product {
  suppliercode: any;
  oaM_ACode: any;
}*/
/*
interface HistoryRow {
  product: Product; 
  total_Amount: any;
}
*/
interface ModalSummaryProps {
  historyRow: any;
}

export const LineProductSummary: React.FC<ModalSummaryProps> = ({
  historyRow,


}) => {
const AuthUser = useAuthUser();
const [isModalOpen, setIsModalOpen] = useState(false);


  const [isSaleHistoryVisible, setIsSaleHistoryVisible] = useState(false);
  const [SaleTrend, setSaleTrend] = useState<any>();
  const handleToggleSaleHistory = () => {
    setIsSaleHistoryVisible(!isSaleHistoryVisible);
  };
  
  useEffect(() => {
    if (isSaleHistoryVisible) {
      // Code to run when isPurchaseHistoryVisible is true
      customaxios.get(`Stock/GetSalesTrend?productId=${historyRow.product.productId}`)
      .then((res) => {
        setSaleTrend(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
      console.log("Purchase history is visible!");
    }
  }, [isSaleHistoryVisible]);

  const handleViewHistory = () => {
    const allowedRoles = ['Developer', 'CEO', 'Director','Praveen']; // Specify the roles allowed to view history
    if (allowedRoles.includes(AuthUser.role)) {
      setIsModalOpen(true);
     
    } else {
      toast.error("You don't have permission to view history");
    }
  };

  return (
   <>
       <p onClick={handleViewHistory} className="">
{historyRow.product.productName}
{historyRow.notes?.length > 0  && (
                                    
                                    <>
                                    (<FontAwesomeIcon icon={faStickyNote} className="note-icon" />)
                                    </> 
                                 )}
</p>
    <Modal 
    onClose={() => setIsModalOpen(false)}

open={isModalOpen}>
 <Box sx={{ position:
     "absolute",
      overflow: "auto",
       top: "50%", 
       left: "50%",
        transform: "translate(-50%, -50%)", 
        width: "80vw", height: "80vh", maxHeight: "900px", bgcolor: "background.paper",
         boxShadow: 24, p: 4 }}>
<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  <div className="border rounded-md p-4">
    <h3 className="text-lg font-semibold mb-4">Product Information</h3>
    <table className="table-auto">
      <tbody>
        <tr>
          <th className="pr-2">Product Name</th>
          <td>: {historyRow.product.productName}</td>
        </tr>
        <tr>
          <th className="pr-2">Supplier Code</th>
          <td>: {historyRow.product.suppliercode}</td>
        </tr>
        <tr>
          <th className="pr-2">A Code</th>
          <td>: {historyRow.product.oaM_ACode}</td>
        </tr>
        <tr>
          <th className="pr-2">Brand</th>
          <td>: {historyRow.product.brand.brand_Name}</td>
        </tr>
        {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
          <tr>
            <th className="pr-2">Supplier</th>
            <td>: {historyRow.product.supplier.supplier_Name} ({historyRow.product.supplier.supplier_Short})</td>
          </tr>
        )}
        {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
          <tr>
            <th className="pr-2">Last Purchase Price</th>
            <td>: {historyRow.product.productPrice.last_PurchasePrice}</td>
          </tr>
        )}
        {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
          <tr>
            <th className="pr-2">Profitability</th>
            <td>: {((historyRow.total_Amount - (historyRow.quantity * historyRow.product.productPrice.last_PurchasePrice)) / historyRow.total_Amount * 100).toFixed(2)}%</td>
          </tr>
        )}
        {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
          <tr>
            <th className="pr-2">Profitability LKR</th>
            <td>: {(historyRow.total_Amount - (historyRow.quantity * historyRow.product.productPrice.last_PurchasePrice)).toFixed(2) }</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>

  <div>
    <div className="border rounded-md p-4">
      <h3 className="text-lg font-semibold mb-4">Order Item Notes</h3>
      <NotesList notes={historyRow.notes} refId={historyRow.id}/>
    </div>

    {(AuthUser?.role === "CEO" || AuthUser?.role === "Director" || AuthUser?.role === "Developer") && (
      <div className="border rounded-md p-4 mt-4">
        <h3 className="text-lg font-semibold mb-4">Sales Trend</h3>
        <button className="bg-blue-500 text-white px-4 py-2 mb-2" onClick={handleToggleSaleHistory}>
          View Sales Trend
        </button>
        {isSaleHistoryVisible && (
          <div>
            <table className="table-auto">
              <thead>
                <tr>
                  <th>Sale Date</th>
                  <th>Unit Price LKR</th>
                  <th>Sale Qty</th>
                  <th>Total Amount LKR</th>
                </tr>
              </thead>
              <tbody>
                {SaleTrend && SaleTrend.map((Sale) => (
                  <tr key={Sale.id}>
                    <td>
                      {Sale.orderItem.created_Datetime?.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                      })}
                    </td>
                    <td>{Sale.orderItem?.unitPrice?.toLocaleString()}</td>
                    <td>{Sale.movement?.toLocaleString()}</td>
                    <td>{Sale.orderItem.total_Amount?.toLocaleString()}</td>
                  </tr>
                ))}
                {!SaleTrend && <tr><td >No Data Available</td></tr>}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )}
  </div>
</div>

</Box>
</Modal>
   </>

  );
};
