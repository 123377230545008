import { useState,useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useAuthUser,useAuth } from '../contexts/auth-context'; 
import Pos from '../components/POS/Pos';
import Customer_Dash_V2 from '../Customer/Customer_Dash_V2';
import Admin from "./../Administration/Admin"
import Customer_Routes from '../components/Customer_Routes/Customer_Routes';
import { usePurchase_Prov } from '../components/Product_Purchase/Product_Purchase_Provider';
import { usePos } from '../components/POS/PosProvider';
import { useNavigate  } from "react-router-dom";


import { useCart } from '../components/Cart/CartProvider';
import Cart from '../components/Cart/Cart';

import List_Sales_reps from '../components/Sales_rep/List_Sales_reps';
import Collection from '../components/Product/Collection/Collection';
import "./Style_Home/Style_Home.css";
import Purchase_Dashboard from '../components/Product_Purchase/Purchase_Dashboard/Purchase_Dashboard';
import Three_DCart from '../components/3D_Cart/Three_DCart';
import SalesPage from './SalesPage';
import History from '../components/History/History';
export default function Home() {
 
  const AuthUser = useAuthUser();
  const { PurchaseItems } = usePurchase_Prov();
  const isAuth = useAuth();
  const { PosItems } = usePos();
  const { getTotalItemsInCart } = useCart();
    const navigate = useNavigate();
    const cartItemCount = getTotalItemsInCart();
    
  const tabs = [
   /* { label: "Sale Order", component: <Sales_order_v2 id={null} /> },
    { label: "Sales", component: <List_Sales_reps /> },*/
    { label: "Sales", component: <SalesPage /> },
    { label: "Purchase", component: <Purchase_Dashboard /> },
    { label: "History", component: <History /> },
    /*{ label: "Products", component: <Products /> },*/
    { label: "Products", component: <Collection /> },
    { label: "Customers", component: <Customer_Dash_V2 /> },
    { label: "Routes", component: <Customer_Routes /> },
    { label: "Accounts", component: <Admin /> },
   /*  { label: "Three_DCart", component: <Three_DCart /> },*/
   /* { label:  `Cart ${cartItemCount > 0 ? "(" + cartItemCount + ")" : ""}`, component: <Cart /> },
    { label: `POS ${PosItems.length > 0 ? "(" + PosItems.length + ")" : ""}`, component: <Pos /> },
    { label: `Purchase ${PurchaseItems.length > 0 ? "(" + PurchaseItems.length + ")" : ""}`, component: <Product_Purchase /> },
*/
  ];
  const [tabsContainerVisible, setTabsContainerVisible] = useState(true); // Initialize it as visible

  // Filter tabs based on conditions
  if(!isAuth){  navigate('/')}
  const filteredTabs = tabs.filter(tab => {
    if (tab.label === "Sale Order") {
      return AuthUser.role === "Director" || AuthUser.role === "Developer";
    } else if ( tab.label === "Admin"  || tab.label === "POS" || tab.label === "Accounts" || tab.label === `Purchase ${PurchaseItems.length > 0 ? "(" + PurchaseItems.length + ")" : ""}` ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" ;
    } 
    else if (tab.label === "Customers" || tab.label === "Routes" ||  tab.label === "Sales" || tab.label === "POS" ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" || AuthUser.role === "Billing_Agent";
    } 
    else if (tab.label === "Purchase" ) {
      return AuthUser.role === "Director" || AuthUser.role === "Developer" || AuthUser.role === "Praveen" ;
    } 
    else {
      return true; // Show other tabs by default
    }
  });
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key >= '1' && event.key <= '9') {
        const index = parseInt(event.key) - 1;
        if (index >= 0 && index < filteredTabs.length) {
          setActiveTab(index);
        }
      }
    };  

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredTabs]);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className='Home_Container'>
 
      <div className="home-tabs-container">
      <Tabs 
             scrollButtons="auto" // Automatically show scroll buttons if tabs overflow
             variant="scrollable" // Use scrollable tabs for mobile screens
            value={activeTab} onChange={handleTabChange} className='home-tabs'>
              {filteredTabs.map((tab, index) => (
                <Tab key={index} label={tab.label} className='home-tab-label' />
              ))}
            </Tabs>

      </div>
     
      <div className='home-tab-container'>
      {filteredTabs[activeTab].component}
      </div>
    </div>
  );
}
