import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { toast } from 'react-toastify';
import { useAuthUser } from '../../contexts/auth-context';
import { CheckCircle } from '@mui/icons-material';
import ProductRow from "../Product/Arch/productrow";
import Search from '../Product/Search/Search';
import customaxios from "../../helpers/axios-helper";

interface ReturnItem {
    id: number;
    Product: any;
    Quantity: number;
    UnitPrice: number;
    Total_Amount: number;
    Reason:string;
    CustomReason:string;
}

export default function Manual_Return({ customerid }) {
    const AuthUser = useAuthUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [returnitems, setReturnItems] = useState<ReturnItem[]>([]);
    const [Customer, setItems] = useState<any>([]);
    const predefinedReasons = ["Reason 1", "Reason 2", "Reason 3"]; // Define predefined reasons
    const [customReasons, setCustomReasons] = useState<{ [key: number]: string }>({}); // State to store custom reasons for each item

    const handleViewHistory = () => {
        const allowedRoles = ['Developer', 'CEO', 'Director', 'Praveen']; // Specify the roles allowed to view history
        if (allowedRoles.includes(AuthUser.role)) {
            setIsModalOpen(true);
        } else {
            toast.error("You don't have permission to view history");
        }
    };

//axios call to get items post request
useEffect(() => {
    customaxios.post('Customer/GetCustomerById/',{
        Customerid: customerid,
    })
    .then((response) => {
        console.log(response);
        setItems(response.data);
    })
}, [customerid]);

    useEffect(() => {
        console.log(returnitems);
    }, [returnitems]);

    const addItemToReturnList = (item: any) => {
        const id = returnitems.length > 0 ? returnitems[returnitems.length - 1].id + 1 : 1;
        const newItem: ReturnItem = {
            id,
            Product: item,
            Quantity: 1,
            UnitPrice: item.productPrice.price,
            Total_Amount: item.productPrice.price * 1,
            Reason:"",
            CustomReason:""
        };
        setReturnItems(prevReturnItems => [...prevReturnItems, newItem]);
    };

    const handleQtyChange = (id: number, qty: number) => {
        const updatedItems = returnitems.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    Quantity: qty,
                    Total_Amount: qty * item.UnitPrice
                };
            }
            return item;
        });
        setReturnItems(updatedItems);
    };
    const handleReasonChange = (id: number, reason: string) => {
        const updatedItems = returnitems.map(item => {
            if (item.id === id) {
                // If the selected reason is "Other", set Reason to "Other" and keep the custom reason
                // Otherwise, set Reason to the selected reason and clear the custom reason
                return {
                    ...item,
                    Reason: reason,
                    CustomReason: reason === "Other" ? item.CustomReason : ""
                };
            }
            return item;
        });
        setReturnItems(updatedItems);
    };

    const handleCustomReasonChange = (id: number, customReason: string) => {
        const updatedItems = returnitems.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    CustomReason: customReason
                };
            }
            return item;
        });
        setReturnItems(updatedItems);
    };
    
    const handlePriceChange = (id: number, price: number) => {
        const updatedItems = returnitems.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    Price_Unit: price,
                    Total_Amount: item.Quantity * price
                };
            }
            return item;
        });
        setReturnItems(updatedItems);
    };

    const handleRemoveItem = (id: number) => {
        const updatedItems = returnitems.filter(item => item.id !== id);
        setReturnItems(updatedItems);
    };
    const handlePostReturn = () => {
        const productReturn: any = {
            return_Items: returnitems,
            customer: {
                customerId: Customer.customerId,
              },
            Createdby: {
                id:AuthUser.id,
              },
        };
        console.log(productReturn)

        customaxios.post('/Product_Return/CreateProductReturn', productReturn)
            .then(response => {
                // Handle success, maybe show a success message or perform further actions
                toast.success("Return posted successfully");
                console.log(response)
            })
            .catch(error => {
                // Handle error, maybe show an error message or perform error-specific actions
                console.error("Error posting return:", error);
                toast.error("Error posting return");
            });
    };

    return (
        <>
            <button onClick={handleViewHistory}>Return</button>

            <Modal
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
            >
                <Box sx={{
                    position: "absolute",
                    overflow: "auto",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80vw", height: "80vh", maxHeight: "900px", bgcolor: "background.paper",
                    boxShadow: 24, p: 4
                }}>
                    <div className="customer_Info card">

<h2>{" "+Customer.customerName} {Customer.verified_Customer && <CheckCircle style={{ color: 'green', marginLeft: 8 }} />}</h2>
<p><strong>Sales Rep</strong> {Customer.town?.route? Customer.town.route.salesrep : "Not Allocated"}</p>
<p><strong>Group Name </strong> {Customer.town?.route? Customer.town.route.salesrep : "Not Allocated"}</p>

</div>
                    <Search
                        optionalParam={{
                            IsReturn: true,
                            returnitem: addItemToReturnList
                        }}
                    />

                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="border border-gray-200 ">Product_Return</th>
                                <th className="border border-gray-200 ">Reason</th>
                                <th className="border border-gray-200 ">Quantity</th>
                                <th className="border border-gray-200 ">Unit Price</th>
                                <th className="border border-gray-200 ">Total_Amount</th>
                                <th className="border border-gray-200 ">Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {returnitems.map((item: ReturnItem) => (
                                <tr key={item.id} className="border border-gray-200">
                                    <td className="border border-gray-200 "><a href={`/Product/${item.Product.productId}`}>
          <p className="font-semibold text-black-900">{item.Product.itemdetails}</p>
          <p className="font-light text-gray-500 dark:text-gray-200 ">
            {item.Product.oaM_ACode}{" - "}
            {item.Product.suppliercode && item.Product.suppliercode.trim() !== "" ? item.Product.suppliercode.trim() : "Supplier Code"}

          </p>
        </a></td>
                                    <td className="border border-gray-200">
    <select
        value={item.Reason}
        onChange={(e) => handleReasonChange(item.id, e.target.value)}
        className="w-full border border-gray-200 rounded"
    >
        {predefinedReasons.map(reason => (
            <option key={reason} value={reason}>{reason}</option>
        ))}
        <option value="Other">Other</option>
    </select>
    {/* Input for custom reason */}
    {item.Reason === "Other" && (
    <input
        type="text"
        value={item.CustomReason}
        onChange={(e) => handleCustomReasonChange(item.id, e.target.value)}
        className="w-full border border-gray-200 rounded  mt-2"
        placeholder="Enter custom reason"
    />
)}
</td>

                                    <td className="border border-gray-200 ">
                                        <input
                                            type="number"
                                            value={item.Quantity}
                                            onChange={(e) => handleQtyChange(item.id, parseInt(e.target.value))}
                                            className="w-full border border-gray-200 rounded "
                                        />
                                    </td>
                                    <td className="border border-gray-200">
                                        <input
                                            type="number"
                                            value={item.UnitPrice}
                                            onChange={(e) => handlePriceChange(item.id, parseFloat(e.target.value))}
                                            className="w-full border border-gray-200 rounded"
                                        />
                                    </td>
                                    <td className="border border-gray-200 ">{item.Total_Amount}</td>
                                    <td className="border border-gray-200 ">
                                        <button onClick={() => handleRemoveItem(item.id)} className="bg-red-500 hover:bg-red-600 text-white font-bold px-4 py-2 rounded">Remove</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handlePostReturn}>Post Return</button>
                </Box>
            </Modal>
        </>
    );
}
