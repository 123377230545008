
import React, { lazy, Suspense, useEffect, useState } from "react";
import customaxios from "../../../helpers/axios-helper";
import SwipeableViews from 'react-swipeable-views';
import Productarch from "../Arch/ProductArch";
import Product_arch from "../Arch/Product_Arch";
import Loading_Default from "../../../components/Loading_Defualt";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Print_Export from "../Print_Export";
import ModelsSelector from "./Models/ModelsSelector";

import BrandSelector from "./Brand/BrandSelector";
import Supplier_Selector from "./Supplier/SupplierSelector";

import Category_Selector from "./Category/CategorySelector";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Modal from "react-modal";
import "./Collection_Style/Style.css";
import Favourites from "./Favourites/Favourites";

import { useAuthUser,useAuth } from '../../../contexts/auth-context'; 
import { useNavigate } from 'react-router-dom';


interface Category {
    product_groupId: any;
    group_Name: string;
    itemcount: number;
    archCount: number;
    image_url: string;
  }

export default function Collection(){
 

    const [selectedCollection_Name, setselectedCollection_Name] = useState<string | null>(null);
    const [selectedCollection_Type, setselectedCollection_Type] = useState<string | null>(null);
    const [selectedCollection_Id, setselectedCollection_Id] = useState<string | null>(null);

    const [productList, setProductList] = useState([]);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(250); // Default page size
    const [TotalProducts, setTotalProducts] = useState(0); // Default page size
    const [isLoading, setIsLoading] = useState(true);
  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const isAuth = useAuth();

    const AuthUser = useAuthUser();
    const navigate = useNavigate();
    if (!isAuth) {
      // Redirect to the login page if not verified
      navigate('/');
    }
  
    // Replace 'role1', 'role2', etc., with the actual roles you want to check
    const allowedRoles = ['Director','Developer','Praveen','Sales_Rep','Billing_Agent','Customer'];
  
    // Check if the user has at least one of the allowed roles
    const isVerified = allowedRoles.some(role => AuthUser.role === role);
  
    if (!isVerified) {
      // Redirect to the login page if not verified
      navigate('/');
    }


    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);
  const [loadingDuration, setLoadingDuration] = useState<number | null>(null);
  
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
    const isLastPage = currentPage === totalPages;
    const handlePageSizeChange = (event) => {
      const newPageSize = parseInt(event.target.value, 10);
      setPageSize(newPageSize);
      setCurrentPage(1); // Reset to the first page when page size changes
    };
    useEffect(()=>{
      setCurrentPage(1)
    },[selectedCollection_Id])
    useEffect(()=>{
      if (selectedCollection_Id === null) {
        setIsModalOpen(true);
      }
    },[selectedCollection_Id])

    useEffect(() => {
      setProductList([]); // Clear the product list when the id changes
      // Fetch category information
     
      fetchProductData(selectedCollection_Id, currentPage, pageSize,selectedCollection_Type); 
  
  
      return () => {
        document.title = "Overseas Automotive"; // Change this line to reset the title to your preferred default value.
      };
    },  [selectedCollection_Id, currentPage, pageSize]);
  
    const fetchProductData = (selectedCollection_Id, page, size,Collection_type) => {
    
      setIsLoading(true);
      const startTime = Date.now();
      setLoadingStartTime(startTime);


      customaxios
      .post(`/product/${Collection_type}`, {
        [Collection_type === "GetProductsByProductArch_ByModel_paged" ? 'Product_ModelId' : 'Id']: selectedCollection_Id,
          Page: page,
          Size: size,
        })
        .then((response) => {
          console.log(response.data)
          setProductList(response.data.products);
        // setProductListParent(productList);
          setTotalPages(response.data.totalPages);
          setTotalProducts(response.data.totalCount
            )
            const endTime = Date.now();
            const duration = (endTime - startTime) / 1000; // Convert to seconds
            setLoadingDuration(duration);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };


  
    const tabsData = [
      { 
        label: "Favourites", 
        component: <Favourites 
          closeModal={closeModal}
          isOpen={isModalOpen}
          selectedCollection_Id={selectedCollection_Id}  
          setselectedCollection_Name={setselectedCollection_Name}
          setselectedCollection_Type={setselectedCollection_Type}
          setselectedCollection_Id={setselectedCollection_Id}
        />
      },
      { 
        label: "Category", 
        component: <Category_Selector 
          selectedCollection_Id={selectedCollection_Id}  
          closeModal={closeModal}
          isOpen={isModalOpen}
          setselectedCollection_Name={setselectedCollection_Name}
          setselectedCollection_Type={setselectedCollection_Type}
          setselectedCollection_Id={setselectedCollection_Id}
        />
      },
      { 
        label: "Brand", 
        component: <BrandSelector 
          closeModal={closeModal}
          isOpen={isModalOpen}
          selectedCollection_Id={selectedCollection_Id}  
          setselectedCollection_Name={setselectedCollection_Name}
          setselectedCollection_Type={setselectedCollection_Type}
          setselectedCollection_Id={setselectedCollection_Id}
        />
      },
      { 
        label: "Model", 
        component: <ModelsSelector 
          closeModal={closeModal}
          isOpen={isModalOpen}
          selectedCollection_Id={selectedCollection_Id}  
          setselectedCollection_Name={setselectedCollection_Name}
          setselectedCollection_Type={setselectedCollection_Type}
          setselectedCollection_Id={setselectedCollection_Id}
        />
      }
    ];
    
    // Role-based rendering for the Supplier tab
    if ((AuthUser?.role === 'Director' || AuthUser?.role === 'Developer' )) {
      tabsData.push({
        label: "Supplier", 
        component: <Supplier_Selector 
          closeModal={closeModal}
          isOpen={isModalOpen}
          selectedCollection_Id={selectedCollection_Id}  
          setselectedCollection_Name={setselectedCollection_Name}
          setselectedCollection_Type={setselectedCollection_Type}
          setselectedCollection_Id={setselectedCollection_Id}
        />
      });
    }
    
    const [selectedItem, setSelectedItem] = useState(tabsData[0].label);

    const handleTabChange = (event, newValue) => {
      setSelectedItem(newValue);
    };
    
    return(
        <>
<div className="Collection_Wrapper">
<div className="menu_collection">

<div className="menu">
<button  className="button_one  btn btn-anim"

onClick={openModal}>
 {selectedCollection_Name !== null && selectedCollection_Name !== "" ? (
selectedCollection_Name
) : (
"Collection"
)}

</button>
</div>

{
  ( AuthUser?.role === "Director" 
  || AuthUser?.role === "CEO"
  || AuthUser?.role === "Developer"
  || AuthUser?.role === "Sales_Rep"
  || AuthUser?.role === "Billing_Agent"
  || AuthUser?.role === "Praveen") ? (
    <>
     {productList?.length > 0 && (
        <div className="Export">
          <Print_Export Data={productList}/>
        </div>
      )}
    </> 
  ) : (
    <>
     
    </>
  )
}


{productList.length > 0 && (<div className="result_kpi">

<span style={{ fontSize: "small", color: "gray" }}>
{productList?.length} Results in{" "} ({loadingDuration?.toFixed(2)} seconds)

</span>
<span style={{ fontSize: "small", color: "gray" }}> Total {TotalProducts}</span>
</div>)}

{productList.length > 0 && (
<div className="showing_">

  <select value={pageSize} onChange={handlePageSizeChange}>
    <option value="10">10</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="250">250</option>
    <option value="1500">1500</option>
    <option value="5000">5000</option>
    {/* Add more options as needed */}
  </select>
</div>)}
</div>
<br />
<br />




{selectedCollection_Id === null ? (
        /* Render content when selectedCollection_Id is not empty */
        <div  style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Optional: Makes it center vertically on the whole viewport
        }}>
           <div className="">
            
         <p> select a collection</p>
        </div>

        </div>
      ) : (
        /* Render content when selectedCollection_Id is empty */
        <div>  <div className="pageination_">

        {loadingDuration !== null && (
        <>
        
        
        {totalPages > 1 ? (
  <Stack spacing={2} sx={{ justifyContent: "center", mt: 3 }}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      color="primary"
    />
  </Stack>
) : (
  <></>
)}

      
        </>
        )}
        </div>
        
        <div className="CategoryProductview">
        
        
        
        
        
        <div className="ProductScroll _Revised_Arch">
        
        <Suspense fallback={<Loading_Default />}>
          {productList?.length > 0 ? (
            productList.map((row) => (
              <>
              
                  < Product_arch
                    key={row.productArch.productArchId}
                    products={row.products}
                    arch={row.productArch}
                  />
                
              </>
            ))
          ) : (
            <Loading_Default /> // Display loading component while data is being fetched
          )}
        </Suspense>
        <div className="pageination">
       
        {loadingDuration !== null && (
        <>
        
       
      
        </>
        )}
       
        </div>
        {productList.length > 1  && (
      <> 
       {totalPages > 1 ? (
  <Stack spacing={2} sx={{ justifyContent: "center", mt: 3 }}>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      color="primary"
    />
  </Stack>
) : (
  <></>
)}

      <div>
          <label>Page Size:</label>
          <select value={pageSize} onChange={handlePageSizeChange}>
          <option value="10">10</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="250">250</option>
    <option value="1500">1500</option>
    <option value="5000">5000</option>
            {/* Add more options as needed */}
          </select>
        </div>
       {isLastPage && (
        <div className="end" style={{ textAlign: "center" }}>
          <h4>~~~~~ END OF PRODUCTS ~~~~~</h4>
          <h4>༼ つ ◕_◕ ༽つ</h4>
        </div>
        )}
        </>
        )}
        </div>
        </div></div>
      )}



</div>

    
    <Modal className="Collection_Model" isOpen={isModalOpen} onRequestClose={closeModal}>
    {
      /*  <button className="button_one danger" onClick={closeModal}>Close</button> */
    }

      <Tabs
     
     scrollButtons="auto" // Automatically show scroll buttons if tabs overflow
     variant="scrollable" // Use scrollable tabs for mobile screens

            value={selectedItem}
            onChange={handleTabChange}
          >
            {tabsData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                value={tab.label}
                className={`tab ${selectedItem === tab.label ? '_selected' : ''}`}
              />
            ))}
          </Tabs>
     
          <div className="Collection_Model_view">
          {tabsData.map((tab, index) => (
            <React.Fragment key={index}>
              {selectedItem === tab.label && tab.component}
            </React.Fragment>
          ))}
        </div>
        </Modal>

        </>
    )
}