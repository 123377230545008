// Define the action types as constants

// Define the productnamereducer to handle the SET_PRODUCT_NAME action
const ProductLables = (state = false, action) => {
    switch (action.type) {
      case "SET_PRODUCT_LABLES":
        return !state; // Toggle the boolean value
      default:
        return state;
    }
  };

  export default ProductLables;